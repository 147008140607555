import Vue from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import 'default-passive-events'
// element ui
import i18n from './lang/index';
import ElementLocale from 'element-ui/lib/locale';
import {
  Tag,
  Row,
  Col,
  Form,
  FormItem,
  Input,
  Button,
  Link,
  Tree,
  Dropdown,
  DropdownMenu,
  DropdownItem,
  Avatar,
  Dialog,
  Select,
  Option,
  Tooltip,
  ColorPicker,
  Slider,
  Alert,
  Upload,
  Radio,
  RadioGroup,
  MessageBox,
  Carousel,
  CarouselItem,
  Image,
  Pagination,
  Popover,
  Container,
  Aside,
  Main,
  Table,
  TableColumn,
  DatePicker,
  TimePicker,
  RadioButton,
  timeSelect,
  Divider,
  Progress,
  Checkbox,
} from 'element-ui';
// 本地
import './styles/element-variables.scss';
import * as api from './api';
import * as utils from './helpers/utils';
import validate from './helpers/validate';
import { ajax, noCall, noLoading } from './helpers/ajax';
import * as dicts from './config/dicts';
import * as path from './config/path';
import BaseDialog from './components/BaseDialog';
import GuideDialog from './components/GuideDialog';
import echarts from './helpers/echartsUI';
import * as oem from './oem'

ElementLocale.i18n((key, value) => i18n.t(key, value));
// element ui组件注册
Vue.use(Tag);
Vue.use(Row);
Vue.use(Col);
Vue.use(Form);
Vue.use(FormItem);
Vue.use(Input);
Vue.use(Button);
Vue.use(Link);
Vue.use(Tree);
Vue.use(Dropdown);
Vue.use(DropdownMenu);
Vue.use(DropdownItem);
Vue.use(Avatar);
Vue.use(Dialog);
Vue.use(Select);
Vue.use(Option);
Vue.use(Tooltip);
Vue.use(ColorPicker);
Vue.use(Slider);
Vue.use(Alert);
Vue.use(Upload);
Vue.use(Radio);
Vue.use(RadioGroup);
Vue.use(Carousel);
Vue.use(CarouselItem);
Vue.use(Image);
Vue.use(Pagination);
Vue.use(Popover);
Vue.use(Container);
Vue.use(Aside);
Vue.use(Main);
Vue.use(Table);
Vue.use(TableColumn);
Vue.use(DatePicker);
Vue.use(TimePicker);
Vue.use(RadioButton);
Vue.use(timeSelect);
Vue.use(Divider);
Vue.use(Progress);
Vue.use(Checkbox);

Vue.prototype.$msgbox = MessageBox;
Vue.prototype.$prompt = MessageBox.prompt;

// 注册全局组件
Vue.component('base-dialog', BaseDialog);
Vue.component('guide-dialog', GuideDialog);

// 注册全局方法
Vue.prototype.$api = api;
Vue.prototype.$utils = utils;
Vue.prototype.$ajax = ajax;
Vue.prototype.$noCall = noCall;
Vue.prototype.$noLoading = noLoading;
Vue.prototype.$validate = validate;
Vue.prototype.$dicts = dicts;
Vue.prototype.$path = path;
Vue.prototype.$echarts = echarts;
Vue.prototype.$oem = oem;

Vue.config.productionTip = false;

document.title = oem.siteName()

export default new Vue({
  router,
  i18n,
  store,
  render: h => h(App)
}).$mount('#app');
