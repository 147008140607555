/* eslint-disable no-dupe-keys */
import LeftMenu from "./leftMenu";
import TopHeader from "./topHeader";
import RemoteOperate from "./remoteOperate";
import WhiteBoard from "./../../components/whiteBoard";
import PreviewImage from "./../../components/previewImage";
import html2canvas from "html2canvas";
import Clipboard from 'clipboard';
import { format, diffHours, getSessionStorage } from '../../helpers/utils'
import QRCode from 'qrcodejs2'
import { EleResize } from './../../helpers/esresize'
import Watermark from './../../helpers/warterMark'
import WatermarkBig from './../../helpers/warterMarkBig'
import AgoraRTC from "agora-rtc-sdk-ng"
import { agoraCameraRtcEngine, agoraScreenRtcEngine } from '@/views/index/agoraRtcEngine'
import agora from "@/config/agora"

export default {
  components: {
    LeftMenu,
    TopHeader,
    RemoteOperate,
    WhiteBoard,
    PreviewImage,
    html2canvas,
    Clipboard,
    diffHours,
    format,
    QRCode,
    EleResize,
    Watermark,
    WatermarkBig
  },
  data() {
    return {
      userWss: null,
      hstRtcEngine: new window.HstRtcEngine(), // 引擎实例
      agoraCameraRtcEngine: null, // 声网摄像头引擎
      agoraScreenRtcEngine: null, // 声网共享屏幕引擎
      // hstRtcEngine: '',
      currentCamId: "", // 当前选择的摄像头Id
      currentMicId: "", // 当前选择的麦克风Id
      onlineUserList: [], // pass当前在线人员
      ownOnlineUserList: [], // 我们当前在线人员
      groupId: "", // 当前房间Id
      groupUserList: [], // 房间人员列表
      isPubVideo: false, // 当前是否正在广播本地视频
      isPubAudio: false, // 当前是否广播本地音频
      isPubShare: false, // 当前是否正在屏幕共享
      mainVideoUserId: "", // 主视频框人员id
      speakerUserId: "", // 当前主讲人
      mainVideoIsAR: false, // 主视频框是AR账户
      showWhiteBoard: false, // 是否显示白板
      showShareVideo: false, // 是否播放屏幕共享
      initShowImgUrl: "", // 静态标记时，白板放的默认图片
      messageList: [], // 房间聊天列表
      inputMsg: "", // 当前输入的文字
      isFullScreen: false, // 主视频框人员是否处于全屏状态
      defaultColors: ["#FFFF00", "#FF0000", "#00ff00"], // 动态标记颜色
      currentPointColor: "#FFFF00", // 当前动态标记颜色
      clientWidth: document.documentElement.clientWidth, // 屏幕宽度
      clientHeight: document.documentElement.clientHeight, // 屏幕高度
      // 主讲授权
      speakerAuthorDialog: false, // 主讲授权
      speakerAuthorUserId: "", // 被授权人id
      showEquipmentInfo: false, // 是否显设备信息
      equipInfo: null, // 设备信息
      equipInfoPicList: [], // 设备图片
      equipData: null, // 缓存的邀请信息
      inviteUserInfo: null, // 邀请者用户信息
      showPreviewImage: false, // 实现显示图片查看器
      previewImageUrl: "", // 预览图片地址
      userHeartAutoClose: false, // 是否是手动用户心跳
      roomHeartAutoClose: false, // 是否是手动断开会议室心跳
      isTransparent: false, // 是否是透明白板
      isShowSpace: true, // 控制空间标记上传和下载控件
      openTransparentType: "", // 打开透明白板的入口（rightOption：右边操作按钮，leftSpeakerOption：主讲全屏左边操作按钮）
      showMenuEquiment: false,
      showMenuEquimentSrc: "",
      isLockText: "YES", // 是否锁定
      inviteComfirm: false, // 是否显示
      isBotton: true,
      isSetNickNameShow: false,
      // 预约详情
      setDialogVisible: false,
      showFooter: false,
      guidePicData: '',
      userMettingName: '',
      meetingID: '',
      invitationLink: '', // 当前房间链接
      userInfoName: {},
      isShowRoom: false,
      // 文件上传
      showMettingUploadFile: false,
      fileList: [], // 上传文件列表
      mettingUploadToName: '', // 目标用户名
      mettingUploadToAccountId: '', // 目标用户名ID
      mettingHeaders: {
        subject_token: this.$utils.getSessionStorage('token'),
      },
      fileSize: '',
      isMettingUploadFile: true, // 判断是否选择文件
      isMettingRead: false, // 判断推送文件是否预览
      // 报修详情
      isShowRepairDetails: false,
      repairDetailsWache: '',
      showEquipmentDetails: false,
      customBizData: '', // 是否有报修详情头部
      // 在线人员
      centerDialogVisible: false,
      showUserLineQY: false, // 企业联系人
      showUserLineWB: false, // 外部联系人
      showUserLinePT: false, // 平台联系人
      onLineAll: '',
      userqyList: [],
      userwbList: [],
      userptList: [],
      showReadFile: false,
      uploadFileData: '',
      uploadFileShow: '',
      showFileName: '',
      // 账号统计数据导出
      joinMeetingTimes: 0, // 本月远程支持时长(分钟)
      totaljoinMeetingTimes: 0, // 本年远程支持时长(分钟)
      isTimesYear: 1,
      joinMeetingLineTimes: 0, // 本月在线时长(分钟)
      totalJoinMeetingLineTimes: 0, // 本年在线时长(分钟)
      isLineTimesYear: 1,
      // accountRepairOrderNums: 0, // 接受到报修工单(个数)
      accountRemoteSupportNums: 0, // 本月远程支持次数(次)
      totaljoinMeetingNum: 0, // 本年远程支持次数(次)
      isJoinMeetingNum: 1,
      accountSharedFiles: 0, // 本月已共享文件(个数)
      totalfilePushNum: 0, // 本年已共享文件(个数)
      isfilePushYear: 1,
      // accountProcessRepairOrderNums: 0, // 已处理报修工单(个数)
      joinMeetingTimesList: [], // 年度远程支持时长图表
      joinMeetingNumList: [], // 年度远程支持次数图表
      // 视频布局
      videoListTop: [],
      videoListBottom: [],
      currentUserData: '',
      mettingUserData: '', // 向会议里联系人发送弹窗
      isnickinfoshow: false,
      getnickinId: '',
      treeRefs: '',
      setNicknameVisible: false,
      nickNameDefault: "",
      currentRoomLock: '',
      autoTime: null,
      // currentAudioStatus: true,
      myAudioStatus: true,
      currentAudioVolume: 0,
      // currentVideoStatus: true,
      loadInnerHtml: false,
      isFullScreenShow: true,
      progressPercent: 0,
      progressFlag: false,
      pageHeight: 0,
      isWatermark: false,
      rtcEngine: process.env.VUE_APP_RTC_ENGINE,
      agoraAppId: null, // 声网appId
      agoraMainVideoTracks: null,
      agoraAccount: null,
      agoraLocalScreenVideoTracks: null,
      agoraLocalScreenAudioTracks: null,
      agoraGetVideoStatsTimer: null,
      videoStats: null,
      mainVideoStats: null,
      deploymentMethod: process.env.VUE_APP_DEPLOYMENT_METHOD,
      usersResolution: [],
      userWatermarkTag: []
    };
  },
  computed: {
    // 用户信息
    user() {
      return this.$store.state.user;
    },
    // 用户token
    token() {
      return this.user.token;
    },
    // 用户accountId
    userId() {
      return this.user.accountId;
    },
    // 用户id
    userAccountId() {
      return this.user.accountUid;
    },
    // 用户昵称
    nickname() {
      return this.user.nickname;
    },
    // 用户头像
    headImg() {
      return this.user.headImg;
    },
    // 用户密码
    password() {
      return this.user.password;
    },
    // 菜单是否折叠
    isFold() {
      return this.$store.state.isFold;
    },
  },
  watch: {
    onlineUserList: {
      handler(newVal, oldVal) {
        console.log('newVal', newVal)
        this.onLineAll = newVal
        this.getOnlineCenterUser()
      },
      deep: true,
    },
    showEquipmentInfo: {
      handler(newVal, oldVal) {
        if (newVal === true) {
          this.$nextTick(() => {
            let secondsNum = 30
            const videoCountApply = this.getEleById("equipIDSeconds");
            videoCountApply.innerHTML = secondsNum
            this.inviteEquipTimer = setInterval(() => {
              if (secondsNum <= 1) {
                this.clearInviteTimer();
                this.showEquipmentInfo = false
                return;
              }
              secondsNum--;
              videoCountApply.innerHTML = secondsNum
            }, 1000);
          })
        } else {
          this.clearInviteTimer();
        }
      },
    },
    getnickinId: {
      handler(newVal, oldVal) {
        if (newVal) {
          this.isnickinfoshow = true
        }
        if (oldVal) {
          if (document.getElementById('nick' + oldVal)) {
            document.getElementById('nick' + oldVal).style.display = "none"
          }
        }
        console.log(newVal)
        console.log(oldVal)
      },
      deep: true,
    },
    groupUserList: {
      handler() {
        const user = this.groupUserList.filter(item => item.userId === this.mainVideoUserId)
        if (user.length > 0) {
          const data = user[0].videoStats
          if (data) {
            this.mainVideoStats = `${data.width}×${data.height} ${Math.ceil(data.bitrate / 1000)}Kbps ${data.frameRate}fps ${data.delay}ms`
          } else {
            this.mainVideoStats = null
          }
        }
      },
      deep: true
    }
  },
  beforeDestroy() {
    if (this.inviteEquipTimer) {
      clearInterval(this.inviteEquipTimer);
      this.inviteEquipTimer = null;
    }
    if (this.autoTime) {
      clearInterval(this.autoTime);
      this.autoTime = null;
    }
    if (this.inviteApplyTimer) {
      clearInterval(this.inviteApplyTimer);
      this.inviteApplyTimer = null;
    }
    // 清除会议室心跳
    if (this.webHeartStepTimer) {
      clearInterval(this.webHeartStepTimer);
      this.webHeartStepTimer = null;
    }
    if (this.speakerInviteTimer) {
      clearInterval(this.speakerInviteTimer);
      this.speakerInviteTimer = null;
    }

    // 清除获取状态
    if (this.getStatsStepTimer) {
      window.cancelAnimationFrame(this.getStatsStepTimer);
      this.getStatsStepTimer = null;
    }
    // 手动断开socket
    if (this.userWss) {
      this.userWss.close();
      this.userWss = null;
    }
    if (this.roomWss) {
      this.roomWss.close();
      this.roomWss = null;
    }
    // 清除重连定时器
    if (this.userWssRelinkTimer) {
      clearTimeout(this.userWssRelinkTimer);
      this.userWssRelinkTimer = null;
    }
    if (this.roomWssRelinkTimer) {
      clearTimeout(this.roomWssRelinkTimer);
      this.roomWssRelinkTimer = null;
    }
    // 清除断网监听
    window.removeEventListener("offline", this.offline);
  },
  mounted() {
    // Watermark.set(this.user.accountId + ' ' + this.user.companyName, this.$utils.format(new Date(), "yyyyMMdd"), this.$refs.directrecordwp)
    // 开始连接用户心跳
    this.userWebHeart();
    // 初始化引擎
    // this.initHstRtcEngine();
    if (this.rtcEngine === 'AGORA') {
      this.initAgoraRtcEngine()
      this.$nextTick(() => {
        this.getOwmOnlineUsers()
      })
    } else if (this.rtcEngine === 'HST') {
      this.initHstRtcEngine();
    }
    // 监听断网
    window.addEventListener("offline", this.offline);
    // 获取语音提示元素
    this.$nextTick(() => {
      this.callNotice = this.getEleById("callNotice");
    });
    // 上报心跳
    this.webHeartStepTimer = setInterval(() => {
      // 上报用户心跳
      if (this.userIsConnect) {
        this.userWss.send(
          JSON.stringify({
            type: "USER_ACCOUNT_HEART_BEAT",
            body: {},
          })
        );
      }
      // 上报会议室心跳
      if (this.roomIsConnect && this.groupId && this.groupUserList.length) {
        this.roomWss.send(
          JSON.stringify({
            type: "ROOM_USER_ACCOUNT_HEART_BEAT",
            body: {
              roomUniqueNo: this.groupId,
            },
          })
        );
      }
    }, 5000);
    document.addEventListener("click", (e) => {
      console.log(1111, this.isnickinfoshow)
      if (this.isnickinfoshow === true) {
        this.closeNickNore(e)
      }
    });
    // 每5分钟获取一次数据
    this.getUserAccount()
    if (this.autoTime) {
      clearInterval(this.autoTime)
    } else {
      this.timer = setInterval(() => {
        setTimeout(this.getUserAccount(), 0)
        console.log("刷新" + new Date());
      }, 1000 * 60 * 5)
    }
  },
  // 模拟自动点击事件
  directives: {
    trigger: {
      inserted(el, binging) {
        el.click()
      }
    }
  },
  methods: {
    // getOtherVideoStats(item) {
    //   if (item.videoStats) {
    //     return `分辨率:${item.videoStats.width}×${item.videoStats.height} 码率:${Math.ceil(item.videoStats.bitrate / 1000)} 帧率:${item.videoStats.frameRate} 延迟:${item.videoStats.delay}ms`
    //   }
    //   return null
    // },
    // 开启水印
    openWatermark() {
      // const text1 = this.user.accountId + ' ' + this.user.companyName + ' ' + this.$utils.format(new Date(), "yyyyMMdd")
      this.isWatermark = !this.isWatermark
      const watermarkTag = this.isWatermark ? 'YES' : 'NO'
      if (this.userWatermarkTag.filter(item => item.accountId === this.userId).length > 0) {
        this.userWatermarkTag.map(item => {
          if (item.accountId === this.userId) {
            item.watermarkTag = watermarkTag
          }
          return item
        })
      } else {
        this.userWatermarkTag.push({ accountId: this.userId, watermarkTag })
      }
      // 关闭水印
      if (!this.isWatermark) {
        if (this.mainVideoUserId === this.userId) {
          WatermarkBig.remove()
        } else {
          Watermark.remove(this.getEleById('t' + this.userId))
        }
        // Watermark.remove()
        // WatermarkBig.remove()
        this.sendWatermarkTagMsg('NO', this.userId)
      } else {
        // 当自己是主窗口
        if (this.mainVideoUserId === this.userId) {
          // 他人画面上加水印
          // const curreArr = this.groupUserList.filter(item => {
          //   return item.userId !== this.mainVideoUserId
          // })
          // curreArr.forEach((item) => {
          //   this.$nextTick(() => {
          //     this.addWatermark(item.userId)
          //   })
          // })
          this.addBigWatermark()
        } else {
          // WatermarkBig.set(this.getEleById('directrecordwp'), text1);
          this.addWatermark(this.userId)
        }
        this.sendWatermarkTagMsg('YES', this.userId)
        // 当自己不在主窗口时候
      }
    },
    addWatermark(userId) {
      const text1 = this.user.accountId + ' ' + this.user.companyName + ' ' + this.$utils.format(new Date(), "yyyyMMdd")
      // const text2 = this.$utils.format(new Date(), "yyyyMMdd")
      console.log('addWatermark', userId)
      Watermark.set(this.getEleById('t' + userId), text1);
    },
    addBigWatermark() {
      const text1 = this.user.accountId + ' ' + this.user.companyName + ' ' + this.$utils.format(new Date(), "yyyyMMdd")
      // const text2 = this.$utils.format(new Date(), "yyyyMMdd")
      WatermarkBig.set(this.getEleById('directrecordwp'), text1);
    },
    // 兼容性比例缩放
    getOnresize() {
      this.$nextTick(() => {
        const messageResize = document.getElementsByClassName('el-message-box__wrapper')[0]
        const docHeight = document.body.clientHeight
        const docWidth = document.body.clientWidth
        const standarHeight = docHeight / 1080
        if (docWidth < 1680) {
          if (messageResize) {
            messageResize.childNodes[0].style.zoom = standarHeight;
          }
        }
      })
    },
    // 图表自动缩放
    drawLine (dom, option) {
      // 基于准备好的dom，初始化echarts实例
      const myChart = this.$echarts.init(document.getElementById(dom))
      const resizeDiv = document.getElementById(dom)
      // 绘制图表
      myChart.clear()
      myChart.setOption(option)
      const listener = function () {
        myChart.resize()
      }
      EleResize.on(resizeDiv, listener)
    },
    // 年度远程支持时长图表
    initEchartsTimes(resData, totalData) {
      const myEcharts = 'LeftBottomBox1'
      const option = {
        title: [{
          text: this.$t("yearRemoteSupportTimes"),
          textStyle: {
            fontSize: 18,
            color: '#232531',
            fontWeight: 'normal'
          },
          itemGap: 15,
          subtext: totalData,
          subtextStyle: {
            color: '#000000',
            fontSize: 36,
            fontWeight: 'bolder'
          },
          padding: [20, 0, 10, 20]
        },
        {
          text: this.$t("tableMinute"),
          left: '80%',
          textStyle: {
            fontSize: 18,
            color: '#6E818B',
            fontWeight: 'normal'
          },
          padding: [20, 0, 10, 20]
        }],
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'shadow'
          }
        },
        grid: [{
          top: '100',
          bottom: '50',
          left: '20',
          right: '25'
        }],
        xAxis: {
          axisLabel: {
            rotate: 30,
          },
          axisTick: { // 坐标轴 刻度
            show: true,
          },
          type: 'category',
          data: [this.$t("tableJanuary"), this.$t("tableFebruary"), this.$t("tableMarch"), this.$t("tableApril"), this.$t("tableMay"), this.$t("tableJune"), this.$t("tableJuly"), this.$t("tableAugust"), this.$t("tableSeptember"), this.$t("tableOctober"), this.$t("tableNovember"), this.$t("tableDecember")],
        },
        yAxis: {
          axisLabel: {
            show: false,
          },
          axisTick: {
            show: false,
          },
          axisLine: {
            show: false,
          },
          splitLine: {
            show: false,
          }
        },
        legend: {},
        series: [
          {
            type: 'bar',
            itemStyle: {
              color: '#92CEFF'
            },
            barMaxwidth: '10%',
            data: resData
          }
        ]
      }
      this.drawLine(myEcharts, option)
    },
    // 年度远程支持次数图表
    initEchartsNums(resData, totalData) {
      const myEcharts = 'LeftBottomBox2'
      const option = {
        title: [{
          text: this.$t("yearRemoteSupportNums"),
          textStyle: {
            fontSize: 18,
            color: '#232531',
            fontWeight: 'normal'
          },
          itemGap: 15,
          subtext: totalData,
          subtextStyle: {
            color: '#000000',
            fontSize: 36,
            fontWeight: 'bolder'
          },
          padding: [20, 0, 10, 20]
        },
        {
          text: this.$t("tableNumber"),
          left: '80%',
          textStyle: {
            fontSize: 18,
            color: '#6E818B',
            fontWeight: 'normal'
          },
          padding: [20, 0, 10, 20]
        }],
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'shadow'
          }
        },
        grid: [{
          top: '100',
          bottom: '50',
          left: '20',
          right: '25'
        }],
        xAxis: {
          axisLabel: {
            rotate: 30,
          },
          axisTick: { // 坐标轴 刻度
            show: true,
          },
          type: 'category',
          data: [this.$t("tableJanuary"), this.$t("tableFebruary"), this.$t("tableMarch"), this.$t("tableApril"), this.$t("tableMay"), this.$t("tableJune"), this.$t("tableJuly"), this.$t("tableAugust"), this.$t("tableSeptember"), this.$t("tableOctober"), this.$t("tableNovember"), this.$t("tableDecember")],
        },
        yAxis: {
          axisLabel: {
            show: false,
          },
          axisTick: {
            show: false,
          },
          axisLine: {
            show: false,
          },
          splitLine: {
            show: false,
          }
        },
        legend: {},
        series: [
          {
            type: 'bar',
            itemStyle: {
              color: '#92CEFF'
            },
            data: resData
          }
        ]
      }
      this.drawLine(myEcharts, option)
    },
    // 年度处理工单图表
    // initEchartsWork() {
    //   const myEcharts = 'LeftBottomBox3'
    //   const option = {
    //     title: {
    //       text: this.$t("yearRepairOrderNum"),
    //       textStyle: {
    //         color: '#3E89C1',
    //         fontSize: 14
    //       },
    //       subtext: '副标题',
    //       subtextStyle: {},
    //       padding: [20, 0, 10, 20]
    //     },
    //     tooltip: {
    //       trigger: 'axis',
    //       axisPointer: {
    //         type: 'shadow'
    //       }
    //     },
    //     grid: [{
    //       bottom: '50',
    //     }],
    //     xAxis: {
    //       name: '(个)',
    //       nameLocation: 'center', // 轴名称相对位置
    //       nameTextStyle: { // 坐标轴名称样式
    //         padding: [13, -70, 0, 100]
    //       },
    //       axisLabel: {
    //         rotate: 30, // 旋转角度
    //         // showMaxLabel: false,
    //         // interval: 0
    //       },
    //       axisTick: { // 坐标轴 刻度
    //         show: false,
    //       },
    //       type: 'category',
    //       data: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sept', 'Oct', 'Nov', 'Dec']
    //     },
    //     yAxis: {
    //       show: false,
    //       type: 'value'
    //     },
    //     legend: {},
    //     series: [
    //       {
    //         // name: '星期',
    //         type: 'bar',
    //         itemStyle: {
    //           color: '#141B4D'
    //         },
    //         data: [23, 33, 45, 56, 78]
    //       }
    //     ]
    //   }
    //   this.drawLine(myEcharts, option)
    // },
    // 本月
    getCompanyStatistics() {
      const nowDate = new Date();
      // var fullYear = nowDate.getFullYear();
      // var month = nowDate.getMonth() + 1; // getMonth 方法返回 0-11，代表1-12月
      // var endOfMonth = new Date(fullYear, month, 0).getDate(); // 获取本月最后一天
      nowDate.setDate(1);
      nowDate.setHours(0);
      nowDate.setSeconds(0);
      nowDate.setMinutes(0);

      this.$ajax({
        url: this.$api.findCompanyStatistics,
        data: {
          companyUid: this.user.company.uid,
          startTime: nowDate.getTime(), // 本月开始时间戳
          endTime: new Date().getTime() // 当前时间戳
        },
      }).then((data) => {
        // console.log(data)
      })
    },
    // 账号统计数据导出
    getUserAccount() {
      this.$noLoading({
        url: this.$api.findUserAccountStatistics,
      }).then((data) => {
        this.joinMeetingTimesList = this.$utils.monthListData(data, 'joinMeetingTimes', 1)
        this.joinMeetingNumList = this.$utils.monthListData(data, 'joinMeetingNum')
        // 本年总数
        this.totaljoinMeetingNum = data.reduce((pre, cur) => pre + cur.joinMeetingNum, 0) // 本年远程支持次数(次)
        this.totaljoinMeetingTimes = data.reduce((pre, cur) => pre + this.$utils.changMinute(cur.joinMeetingTimes), 0) // 本年远程支持时长
        this.totalJoinMeetingLineTimes = data.reduce((pre, cur) => pre + cur.loginTimes, 0) // 本年在线时长(分钟)
        this.totalfilePushNum = data.reduce((pre, cur) => pre + cur.filePushNum, 0) // 本年已共享文件(个数)
        // 图表
        this.initEchartsTimes(this.joinMeetingTimesList, this.totaljoinMeetingTimes) // 年度远程支持时长图表
        this.initEchartsNums(this.joinMeetingNumList, this.totaljoinMeetingNum) // 年度远程支持次数图表

        const monthT = (new Date().getMonth()) + 1
        const listData = data.find((item) => {
          return item.month === monthT
        })
        this.joinMeetingTimes = listData.joinMeetingTimes // 远程支持时长(分钟)
        this.joinMeetingLineTimes = listData.loginTimes // 在线时长(分钟)
        this.accountSharedFiles = listData.filePushNum // 已共享文件(个数)
        this.accountRemoteSupportNums = listData.joinMeetingNum // 远程支持次数(次)
      })
    },
    pemoteSupportMonth() {
      this.isTimesYear = 1
    },
    // 本年远程支持时长(分钟)
    pemoteSupportYear() {
      this.isTimesYear = 2
    },
    // 本年在线时长(分钟)
    joinMeetingLineYear() {
      this.isLineTimesYear = 2
    },
    joinMeetingLineMonth() {
      this.isLineTimesYear = 1
    },
    // 本年远程支持次数(次)
    joinMeetingNumYear() {
      this.isJoinMeetingNum = 2
    },
    joinMeetingNumMonth() {
      this.isJoinMeetingNum = 1
    },
    // 本年已共享文件(个数)
    filePushYear() {
      this.isfilePushYear = 2
    },
    filePushMonth() {
      this.isfilePushYear = 1
    },
    getinvite(item) {
      console.log(item)
      this.invite(item)
    },
    getOnlineCenterUser() {
      console.log('this.onLineAll', this.onLineAll)
      if (this.onLineAll.length >= 1) {
        this.centerDialogVisible = true
      } else {
        this.centerDialogVisible = false
      }
      // 在线人员分组
      this.$noLoading({
        url: this.$api.userAccountContacts,
      }).then((data) => {
        if (data.c && data.c.length) {
          data.c.forEach((item) => {
            item.parentType = "c";
            if (item.children && item.children.length) {
              item.children.forEach((list) => {
                list.parentType = "c";
                list.parentGroupId = item.id;
                list.value = "";
              });
            }
          });
        }
        const userqy = this.$utils.flattenObj(data.b)
        const userwb = this.$utils.flattenObj(data.c)
        const userpt = this.$utils.flattenObj(data.a)
        const ListA = []
        const ListB = []
        const ListC = []
        for (let i = 0; i < this.onLineAll.length; i++) {
          userqy.forEach(item => {
            if (item.accountId === this.onLineAll[i] && item.onlineStatus === "YES" && ListA.filter(i => i.accountId === item.accountId).length === 0) {
              ListA.push(item)
            }
          })
          userwb.forEach(item => {
            if (item.accountId === this.onLineAll[i] && item.onlineStatus === "YES" && ListB.filter(i => i.accountId === item.accountId).length === 0) {
              ListB.push(item)
            }
          })
          userpt.forEach(item => {
            if (item.accountId === this.onLineAll[i] && item.onlineStatus === "YES" && ListC.filter(i => i.accountId === item.accountId).length === 0) {
              ListC.push(item)
            }
          })
        }
        this.userqyList = this.$utils.resultArray(ListA, 'name')
        this.userwbList = this.$utils.resultArray(ListB, 'name')
        this.userptList = this.$utils.resultArray(ListC, 'name')
        this.$forceUpdate()
        console.log(this.userqyList)
        console.log("我执行了")
      })
    },
    getOwmOnlineUsers() {
      // 在线人员分组
      this.$noLoading({
        url: this.$api.userAccountContacts,
      }).then((data) => {
        if (data.c && data.c.length) {
          data.c.forEach((item) => {
            item.parentType = "c";
            if (item.children && item.children.length) {
              item.children.forEach((list) => {
                list.parentType = "c";
                list.parentGroupId = item.id;
                list.value = "";
              });
            }
          });
        }
        const userqy = this.$utils.flattenObj(data.b)
        const userwb = this.$utils.flattenObj(data.c)
        const userpt = this.$utils.flattenObj(data.a)
        const users = [...userqy, ...userwb, ...userpt]
        users.forEach(item => {
          if (item.onlineStatus === "YES") {
            if (item.accountId !== null && this.ownOnlineUserList.indexOf(item.accountId) === -1) {
              this.ownOnlineUserList.push(item.accountId);
            }
          }
        })
        this.onlineUserList = [...this.ownOnlineUserList]
      })
    },
    // 外部链接加入会议
    addLinkRoom() {
      setTimeout(() => {
        const referUrl = this.$utils.getLocalStorage('referUrl')
        if (referUrl) {
          const value = "https://web.ar-office.com" + referUrl; // 沙盒
          this.$ajax({
            url: this.$api.getRoomUniqueNoByInvitation,
            data: {
              invitation: value,
            },
          }).then((data) => {
            const res = {
              body: {
                roomUniqueNo: data.uniqueNo
              }
            }
            this.addinviteRoomResponse(res)
          })
        }
      }, 1000);
    },
    // 更新联系人
    updateContacts() {
      this.$refs.leftMenu.userAccountContacts();
    },
    // 心跳相关消息监听-------------------------------------------
    heartbeatOnMessage(data) {
      // 用户心跳连接成功 ############
      if (data.type === "USER_ACCOUNT_CONNECTED") {
        console.log("用户心跳连接成功");
        return;
      }
      // 会议室心跳连接成功 ############
      if (data.type === "ROOM_USER_ACCOUNT_CONNECTED") {
        console.log("会议室心跳连接成功");
        return;
      }
      // 我们自己的用户上下线监听
      if (data.type === "USER_ACCOUNT_ONLINE_STATUS") {
        const { accountId, onlineStatus } = data.body;
        // 有人上线了
        if (onlineStatus === "YES") {
          if (this.ownOnlineUserList.indexOf(accountId) === -1) {
            this.ownOnlineUserList.push(accountId);
          }
        } else {
          // 有人下线了
          const index = this.ownOnlineUserList.indexOf(accountId);
          if (index >= 0) {
            this.ownOnlineUserList.splice(index, 1);
            // this.centerUserList.splice(index, 1)
          }
        }
        if (this.rtcEngine === 'AGORA') {
          this.onlineUserList = [...this.ownOnlineUserList]
        }
        console.log("############自己的在线人员");
        console.log(this.groupUserList);
        return;
      }
      // 设备信息信令通知
      if (data.type === "ROOM_NOTICE") {
        const { equipmentRepairCode } = data.body;
        const token = this.$utils.getSessionStorage('token')
        const lang = this.$utils.getLocalStorage('lang') || 'zh'
        let language = "English";
        if (lang === "zh") {
          language = "Chinese";
        } else if (lang === 'ja') {
          language = "Japanese";
        }
        if (equipmentRepairCode) {
          this.showMenuEquimentSrc = `${this.$path.manageHost}inviteEquipment?token%3D${token}%7Clanguage%3D${language}%7Ccode%3D${equipmentRepairCode}`;
          this.showMenuEquiment = true;
        }

        this.repairDetailsWache = data.body.data.equipmentRepairCode
        if (this.customBizData) {
          this.isShowRepairDetails = true
        } else {
          this.isShowRepairDetails = false
        }
        const accountConfigDataList = data.body.data.accountConfigDataList
        accountConfigDataList.forEach(item => {
          const tag = {
            accountId: item.accountId,
            watermarkTag: item.watermarkTag
          }
          this.userWatermarkTag.push(tag)
          if (item.watermarkTag === 'YES') {
            this.addWatermark(item.accountId)
          }
        })
      }
      // 水印信息
      if (data.type === "PUB_MEETING_ACCOUNT_CONFIG") {
        if (this.userWatermarkTag.filter(item => item.accountId === data.body.accountId).length > 0) {
          this.userWatermarkTag.map(item => {
            if (item.accountId === data.body.accountId) {
              item.watermarkTag = data.body.watermarkTag
            }
            return item
          })
        } else {
          this.userWatermarkTag.push({ accountId: data.body.accountId, watermarkTag: data.body.watermarkTag })
        }
        if (data.body.watermarkTag === 'YES') {
          if (data.body.accountId === this.mainVideoUserId) {
            this.addBigWatermark()
          } else {
            this.addWatermark(data.body.accountId)
          }
        } else {
          if (data.body.accountId === this.mainVideoUserId) {
            WatermarkBig.remove()
          } else {
            Watermark.remove(this.getEleById('t' + data.body.accountId))
          }
        }
      }
    },
    // 报修详情
    openRepairDetails() {
      const token = this.$utils.getSessionStorage('token')
      const lang = this.$utils.getLocalStorage('lang') || 'zh'
      let language = "English";
      if (lang === "zh") {
        language = "Chinese";
      } else if (lang === 'ja') {
        language = "Japanese";
      }
      if (this.repairDetailsWache) {
        this.showMenuEquimentSrc = `${this.$path.manageHost}inviteEquipment?token%3D${token}%7Clanguage%3D${language}%7Ccode%3D${this.repairDetailsWache}`;
        console.log(this.showMenuEquimentSrc)
        // this.showEquipmentDetails = true;
        this.showMenuEquiment = true;
      }
    },
    // 视频邀请相关消息监听---------------------------------------
    inviteOnMessage(data) {
      console.log('收到邀请', data)
      // 收到好友邀请加入房间 ############
      if (data.type === "INVITE_INTO_ROOM") {
        // 回复收到邀请
        this.inviteRoomResponse(data.body, "MESSAGE_RECEIVED");
        // 自动直接拒绝情形
        // 1.我邀请别人加入会议，正在等待别人加入
        // 2.别人邀请我加入会议，我正在考虑是否加入
        // 3.我向主讲人申请主讲，正在等待主讲人回复
        // 4.别人向我申请主讲，我正在考虑是否加入
        if (this.inviteTimer || this.speakerInviteTimer) {
          this.inviteRoomResponse(data.body, "REFUSE");
          return;
        }
        // PC邀请远程控制，直接返回不支持
        if (data.body.type === "REMOTE_CONTROL") {
          this.inviteRoomResponse(data.body, "NOT_SUPPORTED");
          return;
        }
        // 在会议中
        if (this.groupId) {
          if (this.currentRoomLock === "YES") {
            this.inviteRoomResponse(data.body, "LOCKED");
            return;
          } else {
            this.inviteRoomResponse(data.body, "ALREADY_IN_ROOM");
            return;
          }
          // this.inviteRoomResponse(data.body, "ALREADY_IN_ROOM");
          // return;
        }

        // 查询设备信息
        if (data.body.customBizData) {
          this.customBizData = data.body.customBizData // 是否有报修详情头部
          let baseUrl = "";
          let url = "";
          const params = {};
          if (data.body.customBizData.indexOf("custom") === 0) {
            baseUrl = "http://" + location.hostname + this.$path.oemBaseURL;
            url = this.$api.equipmentGetEquipmentByWebCode; // http://192.168.1.101/oem/equipment/getEquipmentByWebCode
            params.code = data.body.customBizData;
          } else if (data.body.customBizData.indexOf("publicCustom") === 0) {
            baseUrl = "";
            url = this.$api.customQrCodeScan; // /tmva/custom/qrCode/scan
            params.qrCode = data.body.customBizData;
            params.type = "WEB_DEVICE_INFO";
          } else {
            baseUrl = "";
            url = this.$api.qrCodeScan; // /tmva/qrCode/scan
            params.qrCode = data.body.customBizData;
            params.type = "WEB_DEVICE_INFO";
          }
          this.$ajax({
            baseUrl: baseUrl,
            url,
            data: params,
          }).then((result) => {
            console.log(result)
            this.getUserInfo(data.body.accountId, (inviteUserInfo) => {
              this.inviteUserInfo = inviteUserInfo;
              this.equipData = data;
              this.equipInfo = result;
              this.equipInfoPicList = this.$utils.formatArrPic(this.equipInfo.equipment.imageSet, 3)
              this.showEquipmentInfo = true;
              console.log(this.equipInfoPicList)
            });
          });
        } else {
          // 删除预约自动弹窗
          const elmessagebox = document.getElementById("meetingConfirmID")
          if (elmessagebox) {
            this.clearInviteTimer();
            this.$msgbox.close();
            // debugger
          }
          this.getOnresize()
          // 提示邀请弹框
          let secondsApply = 30
          this.$utils
            .confirmApply({
              title: this.$t("remoteGuideAccept"),
              content: `<div class="applyBox">
              <div class="applyBoxLine"><img src="${data.body.userAccountHeadImg ? data.body.userAccountHeadImg : require('./img/touxiang.png')}" /></div>
                <div class="applyBoxRight">
                  <p>${data.body.userAccountCompanyName}</p>
                  <p>${data.body.userAccountName}-${data.body.accountId}</p>
                  <p>${this.$t("hasInvite")}(<span id="videoCountApply">${secondsApply}</span>s)</p>
                </div>
              </div>`,
              confirmText: this.$t("accept"),
              cancelText: this.$t("reject"),
            })
            .then(() => {
              this.clearInviteTimer();
              this.inviteRoomResponse(data.body, "AGREE");
              this.groupId = data.body.roomUniqueNo;
              this.joinGroup();
            })
            .catch(() => {
              this.clearInviteTimer();
              this.inviteRoomResponse(data.body, "REFUSE");
            });
          this.$nextTick(() => {
            console.log(this.inviteApplyTimer, secondsApply)
            const videoCountApply = this.getEleById("videoCountApply");
            videoCountApply.innerHTML = 30;
            this.inviteApplyTimer = setInterval(() => {
              if (secondsApply <= 1) {
                this.clearInviteTimer();
                return;
              }
              secondsApply--;
              videoCountApply.innerHTML = secondsApply;
            }, 1000);
          })
        }
        this.$nextTick(() => {
          this.callNotice.play();
        })
        // 计时，30秒无回复自动关闭
        let seconds = 30;
        this.inviteTimer = setInterval(() => {
          if (seconds <= 1) {
            this.clearInviteTimer();
            this.inviteRoomResponse(data.body, "TIMEOUT");
            return;
          }
          seconds--;
        }, 1000);
        return;
      }
      // 我邀请对方加入房间，对方给我的回复结果 ############
      if (data.type === "PUB_INVITE_TO_ROOM_RESULT") {
        switch (data.body.result) {
          // 消息已送达
          case "MESSAGE_RECEIVED": {
            this.getEleById("receivedTips").innerHTML = `(${this.$t("hasReceived")})`;
            break;
          }
          // 超时
          case "TIMEOUT": {
            this.$utils.showMsg(this.$t("timeoutSpeakerPermission"), "warning");
            break;
          }
          // 锁定
          case "LOCKED": {
            this.clearInviteTimer();
            this.$utils.showMsg(this.$t("LOCKED"), "warning");
            break;
          }
          // 正在会议
          case "ALREADY_IN_ROOM": {
            this.clearInviteTimer();
            // 如果我也在会议中的话，提示：对方正忙，暂时无法接受您的邀请
            if (this.groupUserList.length) {
              this.$utils.showMsg(this.$t("noAcceptInvite"));
              return;
            }
            // 如果我不在会议中，可以申请加入
            this.getOnresize()
            this.$utils
              .confirmApply({
                title: this.$t("remoteGuideAccept"),
                content: `<div class="applyBox">
                <div class="applyBoxLine"><img src="${this.currentUserData.headImg ? this.currentUserData.headImg : require('./img/touxiang.png')}" /></div>
                  <div class="applyBoxRight">
                    <p>${this.currentUserData.company.name}</p>
                    <p>${this.currentUserData.name}-${this.currentUserData.accountId}</p>
                    <p>${this.$t("userinmeeting")}(<span id="addApplyID"></span>s)</p>
                  </div>
                </div>`,
                confirmText: this.$t("apply"),
                cancelText: this.$t("cancel"),
              })
              .then(() => {
                this.clearInviteTimer();
                console.log('apply-data', data)
                this.sendUserMsg({
                  userId: data.userAccountUid,
                  bodyJson: {
                    type: "JOIN_MEETING",
                    signallingId: data.body.inviteNo,
                  },
                });
                this.getOnresize()
                this.$utils
                  .alertCss(
                    "hangUpCss",
                    `<div class="alertCssBox">
                    <div class="alertCssLine"><img src="${this.currentUserData.headImg ? this.currentUserData.headImg : require('./img/touxiang.png')}" /></div>
                      <div class="applyBoxRight">
                      <p>${this.currentUserData.company.name}</p>
                      <p>${this.currentUserData.name}-${this.currentUserData.accountId}</p>
                      <p>${this.$t("userinmeetingTips")}（<span id="addRoomID"></span>s）</p>
                      <p id="receivedTips">${this.$t("hasReceived")}</p>
                      </div>
                    </div>`,
                    this.$t("hangUp"),
                    this.$t("remoteGuideApply"),
                  )
                  .then(() => {
                    this.clearInviteTimer();
                    this.groupId = "";
                    this.sendUserMsg({
                      userId: data.userAccountUid,
                      bodyJson: {
                        type: "CANCEL_JOIN_MEETING",
                      },
                    });
                  });
                this.$nextTick(() => {
                  // 计时，30秒无回复自动关闭
                  let seconds = 30;
                  const addRoomID = this.getEleById("addRoomID");
                  addRoomID.innerHTML = 30;
                  this.inviteTimer = setInterval(() => {
                    if (seconds <= 1) {
                      this.clearInviteTimer();
                      this.groupId = "";
                      return;
                    }
                    seconds--;
                    console.log(this.inviteTimerStr)
                    addRoomID.innerHTML = seconds;
                  }, 1000);
                })
              })
              .catch(() => {
                this.clearInviteTimer();
                this.sendUserMsg({
                  userId: data.userAccountUid,
                  bodyJson: {
                    type: "CANCEL_JOIN_MEETING",
                  },
                });
                this.groupId = "";
              });
            this.$nextTick(() => {
              // 计时，30秒无回复自动关闭
              let secondsAdd = 30;
              const addApplyID = this.getEleById("addApplyID");
              addApplyID.innerHTML = 30;
              this.inviteTimer = setInterval(() => {
                if (secondsAdd <= 1) {
                  this.clearInviteTimer();
                  this.groupId = "";
                  return;
                }
                secondsAdd--;
                addApplyID.innerHTML = secondsAdd;
              }, 1000);
            })
            break;
          }
          // 拒绝
          case "REFUSE": {
            this.clearInviteTimer();
            if (!this.groupUserList.length) this.groupId = "";
            this.$utils.showMsg(
              `${this.$t("rejectInvite")}`,
              "warning"
            );
            break;
          }
          // 同意
          case "AGREE": {
            this.clearInviteTimer();
            this.joinGroup();
            break;
          }
          default: // do something
        }
        return;
      }
      // 手动取消邀请 ############
      if (data.type === "CANCEL_INVITE_INTO_ROOM") {
        this.clearInviteTimer();
        this.getOnresize()
        this.$utils.showMsg(this.$t("hasCanced"), "warning");
      }
    },
    // 收到视频邀请返回响应结果
    inviteRoomResponse(body, result) {
      // 向会议里联系人发送弹窗
      this.mettingUserData = body
      this.userWss.send(
        JSON.stringify({
          type: "RECEIVE_INVITE_TO_ROOM_RESULT",
          body: {
            inviteNo: body.inviteNo,
            roomUniqueNo: body.roomUniqueNo,
            result,
          },
        })
      );
    },
    // 主讲相关消息监听------------------------------------------
    async speakerOnMessage(data) {
      // 收到好友主讲申请 ############
      if (data.type === "ROOM_USER_ACCOUNT_HOST_APPLY") {
        // 回复收到邀请
        this.applySpeakerResponse("MESSAGE_RECEIVED", data.body.applyNo);
        // 自动直接拒绝情形
        // 1.我邀请别人加入会议，正在等待别人加入
        // 2.别人邀请我加入会议，我正在考虑是否加入
        // 3.我向主讲人申请主讲，正在等待主讲人回复
        // 4.别人向我申请主讲，我正在考虑是否加入
        // 5.正在进行白板交流
        // 6.正在进行桌面共享
        // 7.正在进行空间动态标记
        if (
          this.inviteTimer ||
          this.speakerInviteTimer ||
          this.showWhiteBoard ||
          this.isPubShare
        ) {
          this.applySpeakerResponse("REFUSE", data.body.applyNo);
          return;
        }

        this.$ajax({
          url: this.$api.userAccountFindInfo,
          data: {
            accountId: data.accountId,
          },
        }).then((res) => {
          this.getOnresize()
          // 申请主讲权限
          this.$utils
            .confirmApply({
              // title: data.accountId,
              // content: this.$t("getSpeakerPermission"),
              // confirmText: this.$t("accept"),
              // cancelText: this.$t("reject"),
              title: this.$t("applySpeakerAccept"),
              content: `<div class="applyBox">
              <div class="applyBoxLine"><img src="${res.headImg ? res.headImg : require('./img/touxiang.png')}" /></div>
                <div class="applyBoxRight">
                  <p>${res.company.name}</p>
                  <p>${res.name}-${res.nickname ? res.nickname : res.accountId}</p>
                  <p>${this.$t("getSpeakerPermission")}（<span id="getSpeakerApply"></span>s）</p>
                </div>
              </div>`,
              confirmText: this.$t("agree"),
              cancelText: this.$t("msgRefuse"),
            })
            .then(() => {
              this.clearInviteTimer(data.body.applyNo);
              this.applySpeakerResponse("AGREE", data.body.applyNo);
              this.clearInviteTimer();
            })
            .catch(() => {
              this.clearInviteTimer();
              this.applySpeakerResponse("REFUSE", data.body.applyNo);
            });
          this.$nextTick(() => {
            // 计时，30秒无回复自动关闭
            let seconds = 30;
            const getSpeakerApply = this.getEleById("getSpeakerApply");
            getSpeakerApply.innerHTML = 30;
            this.speakerInviteTimer = setInterval(() => {
              if (seconds <= 1) {
                this.clearInviteTimer();
                this.applySpeakerResponse("TIMEOUT", data.body.applyNo);
                return;
              }
              console.log(this.speakerInviteTimer)
              seconds--;
              getSpeakerApply.innerHTML = seconds;
            }, 1000);
          })
        }).catch(() => {
          this.clearInviteTimer();
        })

        return;
      }
      // 我向主讲人申请主讲，主讲人给我的回复结果 ############
      if (data.type === "PUB_APPLY_HOST_RESULT") {
        switch (data.body.result) {
          // 消息已送达
          case "MESSAGE_RECEIVED": {
            this.getEleById("speakerReceivedTips").innerHTML = `(${this.$t(
              "hasReceived"
            )})`;
            break;
          }
          // 超时
          case "TIMEOUT": {
            this.$utils.showMsg(this.$t("timeoutSpeakerPermission"), "warning");
            break;
          }
          // 拒绝
          case "REFUSE": {
            this.clearInviteTimer();
            this.$utils.showMsg(
              this.$t("disagreeSpeakerPermission"),
              "warning"
            );
            break;
          }
          // 同意
          case "AGREE": {
            this.clearInviteTimer();
            break;
          }
          default: // do something
        }
        return;
      }
      // 给会议室所有用户下发主讲已切换消息 ############
      if (data.type === "ROOM_USER_ACCOUNT_HOST_CHANGED") {
        this.speakerUserId = data.body.accountId;
        this.getUserType();
        // 显示当前主讲人
        this.getUserInfo(data.body.accountId, (userInfo) => {
          // 显示有人加入房间消息
          this.showChatMsg({
            userId: data.body.accountId,
            name: userInfo.nickname,
            headImg: userInfo.headImg,
            msg: `${userInfo.nickname}(${data.body.accountId}) ${this.$t("toSpeaker")}`,
          });
        });
        return;
      }
      // 给会议室所有用户下发主讲已放弃主讲消息 ############
      if (data.type === "ROOM_USER_ACCOUNT_HOST_GIVE_UP") {
        console.log(data)
        // 显示主讲已退出
        if (this.speakerUserId === this.userId) {
          this.getUserInfo(this.userId, (userInfo) => {
            this.showChatMsg({
              userId: this.userId,
              name: userInfo.nickname,
              headImg: userInfo.headImg,
              msg: `${userInfo.nickname}(${this.userId}) ${this.$t("giveUpedSpeaker")}`,
            });
          });
          return;
        }
        if (this.speakerUserId !== this.userId) {
          this.getUserInfo(this.speakerUserId, (userInfo) => {
            this.showChatMsg({
              userId: this.speakerUserId,
              name: userInfo.nickname,
              headImg: userInfo.headImg,
              msg: `${userInfo.nickname}(${this.speakerUserId}) ${this.$t("giveUpedSpeaker")}`,
            });
          });
          return;
        }
        this.currentRoomLock = "NO"
        this.speakerUserId = "";
        this.mainVideoIsAR = false;
      }
      // 给会议室所有用户下发主讲已离开消息，适用于主讲断线或离开房间 ############
      if (data.type === "ROOM_USER_ACCOUNT_HOST_LEAVE") {
        // 显示当前无主控
        this.speakerUserId = "";
        this.mainVideoIsAR = false;
        this.currentRoomLock = "NO"
        // 显示主讲已退出
        this.getUserInfo(this.userId, (userInfo) => {
          this.showChatMsg({
            userId: this.userId,
            name: userInfo.nickname,
            headImg: userInfo.headImg,
            msg: `${userInfo.nickname}(${this.userId}) ${this.$t("currentNoSpeaker")}1`,
          });
        });
        return;
      }
      // 对方主动取消了邀请
      if (data.type === "ROOM_HOST_APPLY_CANCELED") {
        this.clearInviteTimer();
      }
      // 强制移出
      if (data.type === "ROOM_REMOVE_OTHER") {
        const index = this.groupUserList.findIndex(
          (item) => item.userId === this.userId
        );
        // 移出
        if (index >= 0) {
          this.groupUserList.splice(index, 1);
          this.$utils.showMsg(this.$t("forceRemoveWarn"));
        }

        if (this.rtcEngine === 'HST') {
          // 停止广播本地媒体流
          this.stopPublishAllMedia();
          // 停止接收远端视频
          this.stopRecvAllMedia();
          // 退出房间
          this.hstRtcEngine
            .leaveGroup()
            .then(() => {
              // 断掉会议室心跳
              if (this.roomWss) {
                this.roomHeartAutoClose = true;
                this.roomWss.close();
              }
              if (this.roomWssRelinkTimer) {
                clearTimeout(this.roomWssRelinkTimer);
                this.roomWssRelinkTimer = null;
              }
              this.groupId = "";
              this.groupUserList = [];
              this.isPubShare = false;
              this.isPubVideo = false;
              this.isPubAudio = false;
              this.mainVideoUserId = "";
              this.speakerUserId = "";
              this.mainVideoIsAR = false;
              this.messageList = [];
            })
            .catch(() => {
            });
        } else if (this.rtcEngine === 'AGORA') {
          await this.agoraUserLeaveGroup(this.userId)
        }
        // this.onUserLeaveGroup()
      }
      // 房间锁定
      if (data.type === "ROOM_LOCK") {
        if (data.body.isLock === "YES") {
          this.isLockText = "NO"
          this.currentRoomLock = "YES"
        } else if (data.body.isLock === "NO") {
          this.isLockText = "YES"
          this.currentRoomLock = "NO"
        }
      }
    },
    // 收到主讲邀请返回响应结果
    applySpeakerResponse(result, applyNo) {
      this.roomWss.send(
        JSON.stringify({
          type: "RECEIVE_APPLY_HOST_RESULT",
          body: {
            roomUniqueNo: this.groupId,
            result,
            applyNo,
            notes: "",
          },
        })
      );
    },
    // 自定信令消息监听------------------------------------------
    customOnMessage(res) {
      if (res.type !== "PUB_CUSTOM_MESSAGE") return;
      const { body } = res;
      let userAccountUid = res.userAccountUid
      const { bodyJson } = body;
      const { type, accountId, result, data } = bodyJson;
      if (!userAccountUid) {
        userAccountUid = bodyJson.userAccountUid
      }
      const { roomId, url, msg, order, tagAccountId } = data || {};

      // 申请加入对方会议中 ############
      if (type === "JOIN_MEETING") {
        // result为AGREE时，说明对方同意我申请加入会议
        if (result === "AGREE") {
          this.clearInviteTimer();
          this.groupId = roomId;
          this.joinGroup();
          return;
        }
        // result为REFUSE时，说明对方拒绝我申请加入会议
        if (result === "REFUSE") {
          this.clearInviteTimer();
          if (!this.groupUserList.length) this.groupId = "";
          this.$utils.showMsg(this.$t("no_meeting"), "warning");
          return;
        }
        // 超时
        if (result === "TIMEOUT") {
          this.$utils.showMsg(this.$t("timeoutSpeakerPermission"), "warning");
          return;
        }

        if (result === "LOCKED") {
          this.clearInviteTimer();
          this.$utils.showMsg(this.$t("LOCKED"), "warning");
          return;
        }

        // 如果bodyJson.data存在，说明是对方向我申请加入会议
        if (!result) {
          this.getOnresize()
          // 查询当前用户详情
          this.$utils
            .confirmApply({
              // title: accountId,
              // content: this.$t("want_to_join_the_meeting"),
              title: this.$t("remoteGuideAccept"),
              content: `<div class="applyBox">
              <div class="applyBoxLine"><img src="${this.mettingUserData.userAccountHeadImg ? this.mettingUserData.userAccountHeadImg : require('./img/touxiang.png')}" /></div>
                <div class="applyBoxRight">
                  <p>${this.mettingUserData.userAccountCompanyName}</p>
                  <p>${this.mettingUserData.userAccountName}-${this.mettingUserData.accountId}</p>
                  <p>${this.$t("hasInvite")}（<span id="videoCountApply"></span>s）</p>
                </div>
              </div>`,
              confirmText: this.$t("agree"),
              cancelText: this.$t("disagree"),
            })
            .then(() => {
              this.clearInviteTimer();
              this.sendUserMsg({
                userId: userAccountUid,
                bodyJson: {
                  type: "JOIN_MEETING",
                  result: "AGREE",
                  data: {
                    roomId: this.groupId,
                  },
                },
              });
              this.communicationRecordUpdateInviteStatus(
                bodyJson.signallingId,
                "AGREE"
              );
            })
            .catch(() => {
              this.clearInviteTimer();
              this.sendUserMsg({
                userId: userAccountUid,
                bodyJson: {
                  type: "JOIN_MEETING",
                  result: "REFUSE",
                },
              });
              this.communicationRecordUpdateInviteStatus(
                bodyJson.signallingId,
                "REFUSE"
              );
            });
          // 超时
          let seconds = 30;
          this.$nextTick(() => {
            const mettingCountDown = this.getEleById("videoCountApply");
            mettingCountDown.innerHTML = seconds;
            this.inviteTimer = setInterval(() => {
              if (seconds <= 1) {
                this.clearInviteTimer();
                this.sendUserMsg({
                  userId: userAccountUid,
                  bodyJson: {
                    type: "JOIN_MEETING",
                    result: "TIMEOUT",
                  },
                });
                this.communicationRecordUpdateInviteStatus(
                  bodyJson.signallingId,
                  "TIMEOUT"
                );
                return;
              }
              seconds--;
              mettingCountDown.innerHTML = seconds;
            }, 1000);
          })
        }
        return;
      }
      // 取消申请加入会议 ############
      if (type === "CANCEL_JOIN_MEETING") {
        console.log(res)
        this.clearInviteTimer();
        this.$utils.showMsg(this.$t("hasCanced"), "warning");
        return;
      }
      // 群消息接收 ############
      if (type === "GROUP_MESSAGE") {
        // AR端发送拍照图片
        if (url) {
          this.getUserInfo(accountId, (userInfo) => {
            this.showChatMsg({
              userId: accountId,
              name: userInfo.nickname,
              url: url,
              headImg: userInfo.headImg,
            });
          });
          return;
        }
        // 发送群文本消息
        this.getUserInfo(accountId, (userInfo) => {
          this.showChatMsg({
            userId: accountId,
            name: userInfo.nickname,
            msg: msg,
            headImg: userInfo.headImg,
          });
        });
        return;
      }
      // 控制ar相关信息监听 ############
      if (type === "CONTROLLER_ORDER") {
        switch (order) {
          // 显示多点测温
          case "Get_Point_temperature": {
            const pointEle = document.querySelector(".isFullScreen video");
            const pointEleWidth = pointEle.offsetWidth;
            const pointEleHeight = pointEle.offsetHeight;
            const div = document.createElement("div");
            const value = parseFloat(data.temperature / 1000).toFixed(2) + "℃";
            div.innerHTML = value;
            div.style.position = "fixed";
            div.style.zIndex = "1001";
            div.style.color = this.currentPointColor;
            div.style.fontWeight = "bold";
            div.style.left =
              (data.x * pointEleWidth) / 640 +
              (this.clientWidth - pointEleWidth) / 2 +
              20 +
              "px";
            div.style.top = (data.y * pointEleHeight) / 480 - 5 + "px";
            document.body.appendChild(div);
            const wenDuTimer = setTimeout(() => {
              document.body.removeChild(div);
              clearTimeout(wenDuTimer);
            }, 500);
            const valueMsg =
              this.$utils.format(
                new Date(),
                `${this.$t("time")}：yyyy-MM-dd hh:mm:ss`
              ) +
              ` ${this.$t("temperature")}：` +
              value;
            this.sendChatMsg(valueMsg);
            this.showChatMsg({
              userId: this.userId,
              msg: valueMsg,
            });
            break;
          }
          default: // do something
        }
        return;
      }
      // 主讲控制  ############
      if (type === "HOST_ORDER") {
        switch (order) {
          // 打开动态标记
          case "watch": {
            this.otherMemberShowMainVideo(tagAccountId);
            break;
          }
          // 关闭动态标记
          case "out_watch": {
            if (tagAccountId === this.mainVideoUserId) {
              this.getEleById("mainVideo").parentElement.className = "";
            } else {
              if (this.rtcEngine === 'HST') {
                this.getEleById(tagAccountId).parentElement.className = "";
              } else if (this.rtcEngine === 'AGORA') {
                this.getEleById('video-' + tagAccountId).parentElement.className = "";
              }
            }
            this.activePointVideoUserId = "";
            break;
          }
          // 显示动态标记效果
          case "mouse_point": {
            this.$nextTick(() => {
              console.log('data', data)
              this.currentPointColor = "#" + data.color;
              this.otherMemberShowMainVideo(data.tagAccountId);
              const pointEle = document.querySelector(".isFullScreen video");
              // 计算坐标点位start
              const pointEleWidth = pointEle.offsetWidth;
              const pointEleHeight = pointEle.offsetHeight;
              console.log('pointEleWidth', 'pointEleHeight', pointEleWidth, pointEleHeight)
              let pageX, pageY;
              let local_add_width = 0;
              let local_add_height = 0;
              let remote_add_width = 0;
              let remote_add_height = 0;
              let local_width = pointEleWidth;
              let local_height = pointEleHeight;
              let remote_width = data.videoWidth;
              let remote_height = data.videoHeight;
              if (pointEleWidth / pointEleHeight > 1280 / 720) {
                local_width = pointEleHeight * 1280 / 720;
                local_add_width = (pointEleWidth - local_width) / 2;
              } else if (pointEleWidth / pointEleHeight < 1280 / 720) {
                local_height = pointEleWidth * 720 / 1280;
                local_add_height = (pointEleHeight - local_height) / 2;
              }
              if (data.videoWidth / data.videoHeight > 1280 / 720) {
                remote_width = data.videoHeight * 1280 / 720;
                remote_add_width = (data.videoWidth - remote_width) / 2;
              } else if (data.videoWidth / data.videoHeight < 1280 / 720) {
                remote_height = data.videoWidth * 720 / 1280; // 360
                remote_add_height = (data.videoHeight - remote_height) / 2; // 20
              }
              console.log('remote_width, remote_height', remote_width, remote_height)
              console.log('local_width, local_height', local_width, local_height)
              console.log('local_add_width, local_add_height', local_add_width, local_add_height)
              pageX = local_add_width + ((data.x - remote_add_width) * local_width) / remote_width
              pageY = local_add_height + ((data.y - remote_add_height) * local_height) / remote_height
              if (pageX < 0) pageX = 0
              if (pageY < 0) pageY = 0
              // 计算坐标点位置end
              let zoom = this.getEleById('app').style.zoom
              console.log('zoom', zoom)
              if (!zoom) {
                zoom = 1
              }
              this.showPointAnimate(pageX * zoom, pageY * zoom);
            });
            break;
          }
          default: // do something
        }
        return;
      }
      // 监听别人设置昵称 ############
      if (type === "UPDATE_NICKNAME") {
        this.getUserInfo(accountId, (data) => {
          console.log('getUserInfo', data)
          this.groupUserList.forEach((list) => {
            if (list.userId === accountId) {
              list.showName = data.nickname;
              this.messageList.forEach((m) => {
                if (m.userId === list.userId) {
                  m.showName = list.showName;
                }
              });
            }
          });
        });
        return;
      }
      console.log("其他");
    },
    // 更新邀请状态
    communicationRecordUpdateInviteStatus(inviteNo, inviteToRoomResult) {
      this.$ajax({
        url: this.$api.communicationRecordUpdateInviteStatus,
        data: {
          inviteNo,
          inviteToRoomResult,
        },
      }).then(() => {});
    },
    // 消息分发中心---------------------------------------------
    onmessage(e) {
      if (typeof e.data !== "string") return;
      const data = JSON.parse(e.data);
      console.log("消息分发中心####################################");
      console.log('data', data)
      // 心跳相关消息监听
      this.heartbeatOnMessage(data);
      // 视频邀请相关消息监听
      this.inviteOnMessage(data);
      // 主讲相关消息监听
      this.speakerOnMessage(data);
      // 自定信令消息监听
      this.customOnMessage(data);
      // 文件上传监听
      this.mettingUploadOnMessage(data);
      // AR远程操作相关
      this.$refs.remoteOperate && this.$refs.remoteOperate.onRecvUserMsg(data);
      // 新消息管理
      this.$refs.topHeader && this.$refs.topHeader.onRecvUserMsg(data);
      // 添加联系人结果处理
      this.$refs.leftMenu && this.$refs.leftMenu.onRecvUserMsg(data);
      // 云录制监听
      this.onRecvRecord(data)
    },
    // 云录制监听
    onRecvRecord(data) {
      // 云录制监听 录制信息消息
      if (data.type === 'ROOM_CLOUD_RECORD_INFO') {
        this.showChatMsg({
          userId: data.body.accountId,
          name: data.body.accountId,
          msg: `${data.body.accountId} ${this.$t("sendARRecord")}`,
        });
      }
      // 下发房间录制结束信息消息
      if (data.type === 'ROOM_CLOUD_RECORD_FINISHED_INFO') {
        console.log(data)
        this.showChatMsg({
          userId: data.body.accountId,
          name: data.body.accountId,
          msg: `${data.body.accountId} ${this.$t("closeARRecord")}`,
        });
      }
    },
    // 获取用户详情
    getUserInfo(accountId, callback) {
      this.$ajax({
        url: this.$api.userAccountFindInfo,
        data: {
          accountId,
        },
        showLoading: true,
      }).then((data) => {
        console.log(data)
        data.nickname = data.nickName || data.accountId;
        callback && callback(data);
      });
    },
    // 拍照
    photograph() {
      this.$refs.remoteOperate.sendUserMsg("Photograph");
    },
    // 监听断网
    offline() {
      this.addEventListenerOffline(this.$t("networkDisconnection"));
    },
    // 获取用户的分辨率
    hstGetUserResolution(mediaId, user) {
      console.log('获取分辨率:userId, this.mediaType.VIDEO, mediaId', user.userId, this.mediaType.VIDEO, mediaId)
      this.hstRtcEngine.getStreamStats(
        {
          userId: user.userId,
          mediaType: this.mediaType.VIDEO,
          mediaId,
        },
        (stats) => {
          console.log('hstGetUserResolution', stats)
        },
        100,
        user.userId
      );
    },
    // 显示视频统计数据
    getStreamStats(mediaId, user) {
      console.log(user)
      this.hstRtcEngine.getStreamStats(
        {
          userId: user.userId,
          mediaType: this.mediaType.AUDIO,
          mediaId,
        },
        (stats) => {
          if (user.userId === this.userId) {
            if (!this.isPubAudio) {
              this.$nextTick(() => {
                user.volume = 0;
                this.$utils.setSessionStorage(
                  `${user.userId}:volume:`,
                  user.volume
                );
              });
              return;
            }
          } else if (!user.pubAudio) {
            this.$nextTick(() => {
              user.volume = 0;
              this.$utils.setSessionStorage(
                `${user.userId}:volume:`,
                user.volume
              );
            });
            return;
          }
          if (+stats.volume && +stats.volume >= 0) {
            if (user.userId === this.userId) {
              this.$nextTick(() => {
                user.volume = +stats.volume;
                this.$utils.setSessionStorage(
                  `${user.userId}:volume:`,
                  user.volume
                );
              });
              this.$set(this.$data, 'currentAudioVolume', this.$utils.getSessionStorage(`${user.userId}:volume:`))
            } else {
              this.$nextTick(() => {
                user.volume = +stats.volume;
                this.$utils.setSessionStorage(
                  `${user.userId}:volume:`,
                  user.volume
                );
              });
            }
          } else {
            this.$nextTick(() => {
              user.volume = 0;
              this.$utils.setSessionStorage(
                `${user.userId}:volume:`,
                user.volume
              );
            });
          }
        },
        100,
        user.userId
      );
    },
    // 根据id获取元素
    getEleById(id) {
      return document.getElementById(id);
    },
    // ----------------------------------------------发送信令和接收信令--------------------------------
    // 用户消息发送
    sendUserMsg({ userId, bodyJson = {} }) {
      const { id, accountId } = this.user;
      bodyJson.userAccountId = id;
      bodyJson.accountId = accountId;

      const params = {
        type: "RECEIVE_CUSTOM_MESSAGE",
        body: {
          userAccountUidSet: [userId], // 用户id，不是accountId
          bodyJson,
        },
      };
      console.log(params);
      // 向会议里联系人发送弹窗
      this.mettingUserData = this.user
      this.userWss.send(JSON.stringify(params));
    },
    // 群消息发送
    sendGroupMsg(bodyJson = {}) {
      const { id, accountId } = this.user;
      bodyJson.userAccountId = id;
      bodyJson.accountId = accountId;
      const params = {
        type: "RECEIVE_CUSTOM_MESSAGE",
        body: {
          roomUniqueNo: this.groupId,
          bodyJson,
        },
      };
      console.log("发送组消息");
      console.log(params);
      this.userWss.send(JSON.stringify(params));
    },
    sendWatermarkTagMsg(watermarkTag, accountId) {
      const params = {
        type: "RECEIVE_MEETING_ACCOUNT_CONFIG",
        body: {
          roomUniqueNo: this.groupId,
          watermarkTag,
          accountId,
        },
      };
      console.log(params);
      this.userWss.send(JSON.stringify(params));
    },
    // ----------------------------------------------邀请与被邀请操作--------------------------------------
    // 清除邀请等待计时和弹框
    clearInviteTimer() {
      if (this.inviteEquipTimer) {
        clearInterval(this.inviteEquipTimer);
        this.inviteEquipTimer = null;
      }
      if (this.inviteApplyTimer) {
        clearInterval(this.inviteApplyTimer);
        this.inviteApplyTimer = null;
      }

      if (this.inviteTimer) {
        clearInterval(this.inviteTimer);
        this.inviteTimer = null;
      }
      if (this.inviteFileTime) {
        clearInterval(this.inviteFileTime);
        this.inviteFileTime = null;
      }

      if (this.speakerInviteTimer) {
        clearInterval(this.speakerInviteTimer);
        this.speakerInviteTimer = null;
      }
      this.showEquipmentInfo = false;
      try {
        this.callNotice.pause();
        this.$msgbox.close();
      } catch (e) {}
    },
    // 邀请好友
    invite(item) {
      console.log('invite', item)
      if (this.currentRoomLock === 'YES') {
        this.$utils.showMsg(this.$t("LOCKED"), "warning");
        return;
      }
      const { accountId, uid } = item;
      // 查询用户详情 accountId
      this.$ajax({
        url: this.$api.userAccountFindInfo,
        data: {
          accountId,
        },
      }).then((data) => {
        console.log('data', data)
        this.currentUserData = data
        // 如果邀请的人员已在当前会议中
        if (this.groupUserList.some((list) => list.userId === accountId)) {
          this.$utils.alert(this.$t("hasInMeeting"));
          return;
        }
        // 如果邀请的人员不在线
        if (
          this.onlineUserList.indexOf(accountId) === -1 ||
          this.ownOnlineUserList.indexOf(accountId) === -1
        ) {
          this.$utils.alert(this.$t("userNotOnline"));
          return;
        }

        console.log('this.groupId', this.groupId)
        // return
        // 当前房间不存在
        if (!this.groupId) {
          console.log('currentUserData', this.currentUserData)
          this.roomGetRoomUniqueNo(() => {
            this.roomUserAccountInviteToRoom(uid);
          });
          return;
        }
        this.roomUserAccountInviteToRoom(uid);
      })
    },
    /**
     * 获取会议室房间号接口
     * @param {function} callback 回掉函数
     */
    roomGetRoomUniqueNo(callback) {
      this.$ajax({
        url: this.$api.roomGetRoomUniqueNo,
      }).then((data) => {
        this.groupId = data;
        callback && callback();
      });
    },
    /**
     * 邀请加入房间接口
     * @param {string} userId 目标用户账号ID
     * @param {string} accountId 目标用户账号登录名
     */
    roomUserAccountInviteToRoom(uid) {
      console.log('this.currentUserData', this.currentUserData)
      this.$ajax({
        url: this.$api.roomUserAccountInviteToRoom,
        data: {
          type: "AUDIO_AND_VIDEO",
          roomUniqueNo: this.groupId,
          targetUserAccountUid: uid,
        },
      }).then((data) => {
        this.getOnresize()
        let seconds = 30;
        this.$utils
          .alertCss(
            "hangUpCss",
            `<div class="alertCssBox">
              <div class="alertCssLine"><img src="${this.currentUserData.headImg ? this.currentUserData.headImg : require('./img/touxiang.png')}" /></div>
              <div class="applyBoxRight">
              <p>${this.currentUserData.company.name}</p>
              <p>${this.currentUserData.name}-${this.currentUserData.accountId}</p>
              <p>${this.$t("waitingOtherJoin")}（<span id="videoCountDown">${seconds}</span>s）</p>
              <p id="receivedTips"></p>
              </div>
            </div>`,
            this.$t("hangUp"),
            this.$t("remoteGuideApply"),
          )
          .then(() => {
            this.roomUserAccountCancelInviteToRoom(data);
          })
          .catch(() => {});
        this.$nextTick(() => {
          this.callNotice.play();
          const videoCountDown = this.getEleById("videoCountDown");
          this.inviteTimer = setInterval(() => {
            if (seconds <= 1) {
              this.clearInviteTimer();
              if (!this.groupUserList.length) this.groupId = "";
              return;
            }
            seconds--;
            videoCountDown.innerHTML = seconds;
          }, 1000);
        });
      });
    },
    /**
     * 手动取消邀请
     * @param {string} inviteNo 邀请编号
     */
    roomUserAccountCancelInviteToRoom(inviteNo) {
      this.$ajax({
        url: this.$api.roomUserAccountCancelInviteToRoom,
        data: {
          roomUniqueNo: this.groupId,
          inviteNo,
        },
      })
        .then(() => {
          this.clearInviteTimer();
          if (!this.groupUserList.length) this.groupId = "";
        })
        .catch(() => {});
    },
    // -------------------------------------------------设备信息-----------------------------------------
    // 拒绝
    closeEquipmentInfo() {
      this.clearInviteTimer();
      this.inviteRoomResponse(this.equipData.body, "REFUSE");
    },
    // 接受
    confirmEquipmentInfo() {
      const { body } = this.equipData;
      this.clearInviteTimer();
      this.inviteRoomResponse(body, "AGREE");
      this.groupId = body.roomUniqueNo;
      this.joinGroup();
    },
    // 加入
    addinviteRoomResponse(res) {
      const { body } = res;
      this.clearInviteTimer();
      this.inviteRoomResponse(body, "AGREE");
      this.groupId = body.roomUniqueNo;
      this.joinGroup();
    },
    // ----------------------------------------------主视频框全屏操作----------------------------------
    // 其他人员显示动态标记
    otherMemberShowMainVideo(videoUserId) {
      if (videoUserId === this.mainVideoUserId) {
        this.getEleById("mainVideo").parentElement.className = "isFullScreen";
      } else {
        if (this.rtcEngine === 'HST') {
          this.getEleById(videoUserId).parentElement.className = "isFullScreen";
        } else if (this.rtcEngine === 'AGORA') {
          this.getEleById('video-' + videoUserId).parentElement.className = "isFullScreen";
        }
      }
      this.activePointVideoUserId = videoUserId;
    },
    // 双击主视频框
    dbClickMainVideo() {
      // 主讲人双击
      if (this.speakerUserId === this.userId) {
        this.fullScreenMainVideo();
        return;
      }
      // 观众双击
      if (this.isFullScreen) {
        this.isFullScreen = false;
        return;
      }
      this.isFullScreen = true;
    },
    // 主讲人打开动态标记
    fullScreenMainVideo() {
      console.log(this.mainVideoUserId)
      // 如果正在进行共享桌面
      if (this.isPubShare) {
        this.$utils.showMsg(this.$t("closeShareDesk"), "warning");
        return;
      }
      if (this.isFullScreen) return;
      this.isFullScreen = true;
      this.currentPointColor = "#FFFF00";
      this.getEleById("pointColorList").style.display = "block";
      this.getEleById("pointboardList").style.display = "block";
      // // 打开动态标记
      this.sendGroupMsg({
        type: "HOST_ORDER",
        data: {
          order: "watch",
          tagAccountId: this.mainVideoUserId,
        },
      });
    },
    // 退出动态标记
    pointExit() {
      this.$utils
        .confirm({
          content: this.$t("confirmExit"),
        })
        .then(() => {
          const user = this.groupUserList.find(
            (item) => item.userId === this.mainVideoUserId
          );
          // 关闭动态标记
          this.sendGroupMsg({
            type: "HOST_ORDER",
            data: {
              order: "out_watch",
              tagUserAccountId: user.id,
              tagAccountId: this.mainVideoUserId,
            },
          });
          this.getEleById("pointColorList").style.display = "none";
          this.getEleById("pointboardList").style.display = "none";
          this.isFullScreen = false;
          this.isFullScreenShow = true
        })
        .catch(() => {});
    },
    // 显示动态标记效果
    showPointAnimate(x, y) {
      console.log('x,y', x, y)
      const div = document.createElement("div");
      div.className = "isPoint";
      const pointX = x - 13 + "px"
      const pointY = y - 13 + "px"
      console.log('pointX, pointY', pointX, pointY)
      div.style.left = pointX;
      div.style.top = pointY;
      div.style.borderColor = this.currentPointColor;
      document.body.appendChild(div);
      const pointTimer = setTimeout(() => {
        document.body.removeChild(div);
        clearTimeout(pointTimer);
      }, 500);
    },
    // 发送动态坐标点
    getMousePoint(e) {
      console.log('e', e)
      if (!this.isFullScreen || this.speakerUserId !== this.userId) return;
      const { offsetWidth, offsetHeight } = e.target;
      const user = this.groupUserList.find(
        (item) => item.userId === this.mainVideoUserId
      );
      let zoom = this.getEleById('app').style.zoom
      if (!zoom) {
        zoom = 1
      }
      const params = {
        type: "HOST_ORDER",
        data: {
          order: "mouse_point",
          x: e.offsetX / zoom,
          y: e.offsetY / zoom,
          videoWidth: offsetWidth,
          videoHeight: offsetHeight,
          color: this.currentPointColor.replace("#", ""),
          tagUserAccountId: user.id,
          tagAccountId: this.mainVideoUserId,
        },
      };
      // 动态标记点
      this.sendGroupMsg(params);
      // 多点测温获取坐标温度
      if (
        this.$refs.remoteOperate &&
        ((this.$refs.remoteOperate.AR_Camera === 0 &&
          this.$refs.remoteOperate.mainCameraId === 5) ||
          (this.$refs.remoteOperate.AR_Camera === 1 &&
            this.$refs.remoteOperate.deputyCameraId === 5)) &&
        this.$refs.remoteOperate.AR_Thermometry === 1
      ) {
        // 获取温度
        const offsetWidthC = (offsetHeight * 640) / 480;
        let offsetXC = (offsetWidth - offsetWidthC) / 2;
        offsetXC = e.offsetX - offsetXC;
        this.sendUserMsg({
          userId: user.userAccountUid,
          bodyJson: {
            type: "CONTROLLER_ORDER",
            data: {
              order: "Get_Point_temperature",
              x: Math.floor((offsetXC * 640) / offsetWidthC),
              y: Math.floor((e.offsetY * 480) / offsetHeight),
            },
          },
        });
      }
    },
    // 选择动态标记点颜色
    setCurrentPointColor(item) {
      this.currentPointColor = item;
    },
    // -----------------------------------------------发送文本消息-------------------------------------
    // 确认输入群消息
    commitInputMsg() {
      if (!this.inputMsg) {
        this.$utils.showMsg(this.$t("pleaseEnterContent"), "warning");
        return;
      }
      this.sendChatMsg(this.inputMsg);
      this.inputMsg = "";
    },
    // 发送群消息
    sendChatMsg(msg) {
      this.sendGroupMsg({
        type: "GROUP_MESSAGE",
        data: {
          headimg: this.headImg,
          msg,
        },
      });
    },
    // 展示群消息
    showChatMsg({ userId, name, headImg, msg, url }) {
      const msgItem = {
        userId,
        showName: name,
        headImg: headImg,
      };
      if (msg) msgItem.msg = msg; // 用户消息
      if (url) msgItem.url = url; // 照图片，优先展示
      this.messageList.push(msgItem);
      console.log(this.messageList)
      // 滚动到底部
      this.$nextTick(() => {
        const messageContent = this.getEleById("messageContent");
        messageContent.scrollTop = messageContent.scrollHeight;
      });
    },
    // 打开图片预览查看器
    previewImg(url) {
      this.previewImageUrl = url;
      this.showPreviewImage = true;
    },
    // 关闭图片预览查看器
    closePreviewImage() {
      this.showPreviewImage = false;
    },
    // ----------------------------------------主讲操作(白板、动态标记等)--------------------------------
    startScreenShare() {
      this.hstRtcEngine.startPublishMedia(
        this.mediaType.SCREEN_SHARE,
        null,
        null
      );
    },
    // 打开白板交流
    openWhiteBoard() {
      // 如果正在进行共享桌面
      if (this.isPubShare) {
        this.$utils.showMsg(this.$t("closeShareDesk"), "warning");
        return;
      }
      this.initShowImgUrl = "";
      if (this.showWhiteBoard) return;
      this.isTransparent = false;
      this.isShowSpace = true
      this.showWhiteBoard = true;
      this.$nextTick(() => {
        if (this.rtcEngine === 'HST') {
          this.hstRtcEngine.startPublishMedia(
            this.mediaType.SCREEN_SHARE,
            null,
            null
          );
        } else if (this.rtcEngine === 'AGORA') {
          this.agoraScreenJoinGroup()
        }
      });
    },
    // 聊天框图片导入白板
    importWhiteBoard(item) {
      // 如果正在进行共享桌面
      if (this.isPubShare) {
        this.$utils.showMsg(this.$t("closeShareDesk"), "warning");
        return;
      }
      this.initShowImgUrl = item.url;
      if (this.showWhiteBoard) return;
      this.isTransparent = false;
      this.showWhiteBoard = true;
      this.$nextTick(() => {
        if (this.rtcEngine === 'HST') {
          this.hstRtcEngine.startPublishMedia(
            this.mediaType.SCREEN_SHARE,
            null,
            null
          );
        } else if (this.rtcEngine === 'AGORA') {
          this.agoraScreenJoinGroup()
        }
      });
    },
    // 下载图片
    downloadImage(item) {
      console.log(item)
      setTimeout(() => {
        // https://tmvafiles.tmva-cn.com 沙盒
        // https://tmvafiles.team-view.cn 测试
        // https://tmvafiles.ar-office.com 正式
        const imgOs = item.url.replace(process.env.VUE_APP_FILEDOWN_HOST, 'fliedown')
        // console.log(imgOs)
        const a = document.createElement('a');
        const filename = item.showName + new Date().getTime()
        const xhr = new XMLHttpRequest();
        xhr.open('get', imgOs, true);
        xhr.responseType = 'blob';
        xhr.send();
        xhr.onload = function () {
          if (xhr.readyState === 4 && xhr.status === 200) {
            const blob = new Blob([xhr.response], { type: "image/png" });
            a.href = URL.createObjectURL(blob);
            a.download = filename;
            a.click();
          }
        };
      }, 500)
    },
    // 打开空间标记
    openTransparentWhiteBoard() {
      // 如果正在进行共享桌面
      if (this.isPubShare) {
        this.$utils.showMsg(this.$t("closeShareDesk"), "warning");
        return;
      }
      this.isFullScreenShow = false
      this.initShowImgUrl = "";
      if (this.showWhiteBoard) return;
      this.openTransparentType = "rightOption";
      this.isTransparent = true;
      this.showWhiteBoard = true;
      this.isFullScreen = true;
      this.isShowSpace = false
      this.$nextTick(() => {
        if (this.rtcEngine === 'HST') {
          this.hstRtcEngine.startPublishMedia(
            this.mediaType.SCREEN_SHARE,
            null,
            null
          )
        } else if (this.rtcEngine === 'AGORA') {
          this.agoraScreenJoinGroup()
        }
      })
    },
    // 主讲打开透明白板
    speakerOpenTransparentWhiteBoard() {
      console.log('speakerOpenTransparentWhiteBoard')
      this.initShowImgUrl = "";
      this.isFullScreenShow = false
      this.openTransparentType = "leftSpeakerOption";
      this.getEleById("pointColorList").style.display = "none";
      this.getEleById("pointboardList").style.display = "none";
      this.isTransparent = true;
      this.showWhiteBoard = true;
      // this.$nextTick(() => {
      //   this.hstRtcEngine.startPublishMedia(
      //     this.mediaType.SCREEN_SHARE,
      //     null,
      //     null
      //   );
      // });
      this.$nextTick(() => {
        if (this.rtcEngine === 'HST') {
          this.hstRtcEngine.startPublishMedia(
            this.mediaType.SCREEN_SHARE,
            null,
            null
          )
        } else if (this.rtcEngine === 'AGORA') {
          this.agoraScreenJoinGroup()
        }
      })
    },
    // 空间动态标记转成空间静态标记
    changeToStaticMark() {
      this.getEleById("pointColorList").style.display = "none";
      this.getEleById("pointboardList").style.display = "none";
      // 打开静态标记
      this.openStaticMark();
    },
    // 打开空间静态标记
    openStaticMark() {
      // 如果正在进行共享桌面
      if (this.isPubShare) {
        this.$utils.showMsg(this.$t("closeShareDesk"), "warning");
        return;
      }
      if (this.showWhiteBoard) return;
      const canvas = document.createElement("canvas");
      let video = null
      if (this.rtcEngine === 'HST') {
        video = document.getElementById("mainVideo")
      } else if (this.rtcEngine === 'AGORA') {
        video = document.getElementById("mainVideo").children[0].children[0]
      }
      console.log('video', video)
      canvas.width = video.videoWidth;
      canvas.height = video.videoHeight;
      canvas
        .getContext("2d")
        .drawImage(video, 0, 0, canvas.width, canvas.height);
      this.initShowImgUrl = canvas.toDataURL();
      this.isTransparent = false;
      this.isShowSpace = true
      this.showWhiteBoard = true;
      this.$nextTick(() => {
        if (this.rtcEngine === 'HST') {
          this.hstRtcEngine.startPublishMedia(
            this.mediaType.SCREEN_SHARE,
            null,
            null
          )
        } else if (this.rtcEngine === 'AGORA') {
          this.agoraScreenJoinGroup()
        }
      });
    },
    // 退出白板交流和空间静态标记
    exitWhiteBoard() {
      this.$utils
        .confirm({
          content: this.$t("confirmExit"),
        })
        .then(() => {
          if (this.rtcEngine === 'HST') {
            this.hstRtcEngine.stopPublishMedia(this.mediaType.SCREEN_SHARE);
          } else if (this.rtcEngine === 'AGORA') {
            this.agoraLocalScreenVideoTracks.stop()
            // this.agoraLocalScreenAudioTracks.stop()
            // agoraScreenRtcEngine.unpublish([this.agoraLocalScreenAudioTracks, this.agoraLocalScreenVideoTracks])
            agoraScreenRtcEngine.unpublish([this.agoraLocalScreenVideoTracks])
          }
          this.showWhiteBoard = false;
          if (this.openTransparentType === "leftSpeakerOption") {
            // 从主讲主视频全屏左边菜单打开透明白板
            this.getEleById("pointColorList").style.display = "block";
            this.getEleById("pointboardList").style.display = "block";
          } else {
            // 从右边菜单栏打开透明白板
            this.isFullScreen = false;
          }
          this.isFullScreenShow = true
          this.openTransparentType = "";
          // const MediaType = this.hstRtcEngine.MediaType;
          // this.hstRtcEngine.unsetMediaRender(
          //   this.userId,
          //   MediaType.AUDIO,
          //   document.getElementById('rightAudio')
          // );
        })
        .catch(() => {});
    },
    // 打开共享桌面
    openShareDesk() {
      if (this.isPubShare) {
        this.$utils
          .confirm({
            content: this.$t("confirmCloseShareDesk"),
          })
          .then(() => {
            this.isPubShare = false;
            if (this.rtcEngine === 'HST') {
              this.hstRtcEngine.stopPublishMedia(this.mediaType.SCREEN_SHARE);
            } else if (this.rtcEngine === 'AGORA') {
              this.agoraLocalScreenVideoTracks.stop()
              // this.agoraLocalScreenAudioTracks.stop()
              // agoraScreenRtcEngine.unpublish([this.agoraLocalScreenVideoTracks, this.agoraLocalScreenAudioTracks])
              agoraScreenRtcEngine.unpublish([this.agoraLocalScreenVideoTracks])
            }
          });
      } else {
        this.isPubShare = true;
        if (this.rtcEngine === 'HST') {
          this.hstRtcEngine.startPublishMedia(
            this.mediaType.SCREEN_SHARE,
            null,
            null
          );
        } else if (this.rtcEngine === 'AGORA') {
          this.agoraScreenJoinGroup()
        }
        this.$utils.showMsg(this.$t("shareDeskOpened"));
      }
    },
    // 打开主讲授权
    openSpeakerAuthor(item) {
      console.log(item)
      if (this.isPubShare) {
        this.isPubShare = false;
        this.hstRtcEngine.stopPublishMedia(this.mediaType.SCREEN_SHARE);
      }
      this.getUserInfo(item.userId, (data) => {
        this.roomUserAccountGrantHost(data);
      });
      // for (let i = 0; i < this.groupUserList.length; i++) {
      //   if (this.groupUserList[i].userId !== this.userId) {
      //     this.speakerAuthorUserId = this.groupUserList[i].userId;
      //     break;
      //   }
      // }
      // this.speakerAuthorDialog = true;
    },
    // 确认主讲授权
    confirmSpeakerAuthor() {
      this.speakerAuthorDialog = false;
      // 关闭桌面共享
      if (this.isPubShare) {
        this.isPubShare = false;
        this.hstRtcEngine.stopPublishMedia(this.mediaType.SCREEN_SHARE);
      }
      this.getUserInfo(this.speakerAuthorUserId, (data) => {
        this.roomUserAccountGrantHost(data);
      });
    },
    // 主讲授权接口
    roomUserAccountGrantHost(data) {
      console.log(data)
      this.$ajax({
        url: this.$api.roomUserAccountGrantHost,
        data: {
          roomUniqueNo: this.groupId,
          targetUserAccountUid: data.uid,
        },
      }).then(() => {});
    },
    // ----------------------------------------判断当前主视频框是否是AR账户-------------------------------
    // 判断当前主视频框人员是否是AR人员
    getUserType() {
      if (this.mainVideoUserId === this.userId) return;
      this.getUserInfo(this.mainVideoUserId, (data) => {
        console.log('data', data)
        if (data.localUserAccount.lastLoginDeviceType === "ANDROID" || data.localUserAccount.lastLoginDeviceType === "ANDROID_AR") {
          this.mainVideoIsAR = true;
        }
      });
    },
    // -------------------------------------------选择主视频人员----------------------------------------
    async changeMainVideo(item) {
      console.log(item)
      this.mainVideoIsAR = false;
      this.isPubVideo = true
      this.copyMainVideoUserId = this.mainVideoUserId; // 备份上个主视频框人员id，为了渲染该用户视频
      this.mainVideoUserId = item.userId;
      this.getUserType();
      // // 水印文字
      // const text1 = this.user.accountId + ' ' + this.user.companyName + ' ' + this.$utils.format(new Date(), "yyyyMMdd")
      // 切换主视频框
      if (this.rtcEngine === 'HST') {
        this.$nextTick(() => {
          // 1.当前选择的人员是自己
          if (item.userId === this.userId) {
            // 渲染本人画面
            this.hstRtcEngine.setMediaRender(
              this.userId,
              this.mediaType.VIDEO,
              this.currentCamId,
              this.getEleById("mainVideo")
            );
            // 渲染他人画面
            const currentUser = this.groupUserList.find(
              (list) => list.userId === this.copyMainVideoUserId
            );
            // 声音流存在
            if (currentUser.pubAudio && currentUser.activePubAudio) {
              this.hstRtcEngine.setMediaRender(
                currentUser.userId,
                this.mediaType.AUDIO,
                currentUser.audioMediaId,
                this.getEleById(this.copyMainVideoUserId)
              );
            } else {
              this.hstRtcEngine.unsetMediaRender(
                currentUser.userId,
                this.mediaType.AUDIO,
                this.getEleById(this.copyMainVideoUserId)
              );
            }
            // 视频流存在
            if (currentUser.pubVideo && currentUser.activePubVideo) {
              this.hstRtcEngine.setMediaRender(
                currentUser.userId,
                this.mediaType.VIDEO,
                currentUser.videoMediaId,
                this.getEleById(this.copyMainVideoUserId)
              );
            } else {
              this.hstRtcEngine.unsetMediaRender(
                currentUser.userId,
                this.mediaType.VIDEO,
                this.getEleById(this.copyMainVideoUserId)
              );
            }
            // // 水印
            // if (this.isWatermark) {
            //   Watermark.remove()
            //   WatermarkBig.remove()
            //   Watermark.set(this.getEleById('t' + currentUser.userId), text1);
            // }
            return;
          }
          // 2.当前选择的人员不是自己并且主视频是自己
          if (this.copyMainVideoUserId === this.userId) {
            // 渲染本人画面
            this.hstRtcEngine.setMediaRender(
              this.userId,
              this.mediaType.VIDEO,
              this.currentCamId,
              this.getEleById(this.userId)
            );
            // 渲染他人画面
            // 声音流存在
            if (item.pubAudio && item.activePubAudio) {
              this.hstRtcEngine.setMediaRender(
                item.userId,
                this.mediaType.AUDIO,
                item.audioMediaId,
                this.getEleById("mainVideo")
              );
            } else {
              this.hstRtcEngine.unsetMediaRender(
                item.userId,
                this.mediaType.AUDIO,
                this.getEleById("mainVideo")
              );
            }
            // 视频流存在
            if (item.pubVideo && item.activePubVideo) {
              this.hstRtcEngine.setMediaRender(
                item.userId,
                this.mediaType.VIDEO,
                item.videoMediaId,
                this.getEleById("mainVideo")
              );
            } else {
              this.hstRtcEngine.unsetMediaRender(
                item.userId,
                this.mediaType.VIDEO,
                this.getEleById("mainVideo")
              );
            }
            // 水印
            // if (this.isWatermark) {
            //   Watermark.remove()
            //   WatermarkBig.remove()
            //   WatermarkBig.set(this.getEleById('directrecordwp'), text1);
            // }
            return;
          }
          // 3.当前选择的人员不是自己并且主视频也不是自己
          // 下面都是渲染他人画面
          if (item.pubAudio && item.activePubAudio) {
            this.hstRtcEngine.setMediaRender(
              item.userId,
              this.mediaType.AUDIO,
              item.audioMediaId,
              this.getEleById("mainVideo")
            );
          } else {
            this.hstRtcEngine.unsetMediaRender(
              item.userId,
              this.mediaType.AUDIO,
              this.getEleById("mainVideo")
            );
          }
          if (item.pubVideo && item.activePubVideo) {
            this.hstRtcEngine.setMediaRender(
              item.userId,
              this.mediaType.VIDEO,
              item.videoMediaId,
              this.getEleById("mainVideo")
            );
          } else {
            this.hstRtcEngine.unsetMediaRender(
              item.userId,
              this.mediaType.VIDEO,
              this.getEleById("mainVideo")
            );
          }
          const currentUser = this.groupUserList.find(
            (list) => list.userId === this.copyMainVideoUserId
          );
          if (currentUser.pubAudio && currentUser.activePubAudio) {
            this.hstRtcEngine.setMediaRender(
              currentUser.userId,
              this.mediaType.AUDIO,
              currentUser.audioMediaId,
              this.getEleById(this.copyMainVideoUserId)
            );
          } else {
            this.hstRtcEngine.unsetMediaRender(
              currentUser.userId,
              this.mediaType.AUDIO,
              this.getEleById(this.copyMainVideoUserId)
            );
          }
          if (currentUser.pubVideo && currentUser.activePubVideo) {
            this.hstRtcEngine.setMediaRender(
              currentUser.userId,
              this.mediaType.VIDEO,
              currentUser.videoMediaId,
              this.getEleById(this.copyMainVideoUserId)
            );
          } else {
            this.hstRtcEngine.unsetMediaRender(
              currentUser.userId,
              this.mediaType.VIDEO,
              this.getEleById(this.copyMainVideoUserId)
            );
          }
        })
      } else if (this.rtcEngine === 'AGORA') {
        this.$nextTick(async () => {
          this.groupUserList.map(async item => {
            if (item.userId === this.copyMainVideoUserId) {
              await agoraCameraRtcEngine.setRemoteVideoStreamType(item.agoraCameraInfo.uid, 1)
              item.agoraCameraInfo.videoTrack.play(this.getEleById('video-' + item.userId), { mirror: agora.mirror, fit: agora.fit })
            }
            if (item.userId === this.mainVideoUserId) {
              await agoraCameraRtcEngine.setRemoteVideoStreamType(item.agoraCameraInfo.uid, 0)
              item.agoraCameraInfo.videoTrack.play(this.getEleById('mainVideo'), { mirror: agora.mirror, fit: agora.fit })
            }
            return item
          })
        })
      }
      this.$nextTick(() => {
        const watermarkTag1 = this.userWatermarkTag.filter(i => i.accountId === this.copyMainVideoUserId)
        const watermarkTag2 = this.userWatermarkTag.filter(i => i.accountId === this.mainVideoUserId)
        WatermarkBig.remove()
        if (watermarkTag1.length > 0) {
          Watermark.remove(this.getEleById('t' + watermarkTag1[0].accountId))
          if (watermarkTag1[0].watermarkTag === 'YES') {
            this.addWatermark(watermarkTag1[0].accountId)
          }
        }
        if (watermarkTag2.length > 0) {
          if (watermarkTag2[0].watermarkTag === 'YES') {
            this.addBigWatermark()
          }
        }
      })
    },
    // ----------------------------------------------申请主讲-------------------------------------------
    // 清除主讲权限 自己放弃主控
    clearModerator() {
      if (!this.groupId || this.speakerUserId !== this.userId) return;
      this.$ajax({
        url: this.$api.roomUserAccountGiveUpHost,
        data: {
          roomUniqueNo: this.groupId,
        },
      }).then(() => {
        this.mainVideoIsAR = false;
        this.speakerUserId = "";
        this.currentRoomLock = "NO"
        // const msg = `${userInfo.nickname}(${userId}) ${this.$t("currentNoSpeaker")}`;
        // this.sendChatMsg(msg);
        setTimeout(() => {
          this.showChatMsg({
            name: this.user.name,
            userId: this.userId,
            msg: `${this.user.nickname}(${this.userId}) ${this.$t("currentNoSpeaker")}`,
          });
        }, 500)
      });
    },
    // 手动取消主讲申请
    roomUserAccountCancelHostApply(applyNo) {
      this.$ajax({
        url: this.$api.roomUserAccountCancelHostApply,
        data: {
          roomUniqueNo: this.groupId,
          applyNo,
        },
      })
        .then(() => {
          this.clearInviteTimer();
        })
        .catch(() => {});
    },
    // 申请成为主讲
    applyToSpeaker() {
      // 查询用户详情 accountId
      this.$ajax({
        url: this.$api.userAccountFindInfo,
        data: {
          accountId: this.speakerUserId,
        },
      }).then((res) => {
        this.mettingUserData = res
      })

      // 如果我是主讲
      if (this.speakerUserId && this.speakerUserId === this.userId) {
        this.getOnresize()
        this.$utils
          .confirm({
            content: this.$t("giveUpSpeakerTips"),
          })
          .then(() => {
            this.clearModerator();
          })
          .catch(() => {});
        return;
      }
      // 我不是主讲，我去申请主讲
      this.$ajax({
        url: this.$api.roomUserAccountApplyHost,
        data: {
          roomUniqueNo: this.groupId,
        },
      }).then((data) => {
        const { applyResult, applyNo } = data;
        // 主讲申请已发送
        if (applyResult === "NO") {
          // this.getOnresize()
          this.$utils
          // .alert(
          //   `${this.$t(
          //     "waitingOtherHandle"
          //   )}（<span id="speakerCountDown">${seconds}</span>s）<div id="speakerReceivedTips"></div>`,
          //   this.$t("cancel")
            .alertCss(
              "hangUpCss",
              `<div class="alertCssBox">
                <div class="alertCssLine"><img src="${this.mettingUserData.headImg ? this.mettingUserData.headImg : require('./img/touxiang.png')}" /></div>
                <div class="applyBoxRight">
                <p>${this.mettingUserData.company.name}</p>
                <p>${this.mettingUserData.name}-${this.mettingUserData.accountId}</p>
                <p>${this.$t("waitingOtherJoin")}（<span id="speakerCountDown"></span>s）</p>
                <p id="speakerReceivedTips"></p>
                </div>
              </div>`,
              this.$t("cancel"),
              this.$t("applySpeakerApply")
            )
            .then(() => {
              this.roomUserAccountCancelHostApply(applyNo);
            })
            .catch(() => {});
          // 申请主讲弹出邀请等待弹框
          let seconds = 30;
          this.$nextTick(() => {
            this.callNotice.play();
            const speakerCountDown = this.getEleById("speakerCountDown");
            speakerCountDown.innerHTML = seconds;
            this.speakerInviteTimer = setInterval(() => {
              if (seconds <= 1) {
                this.clearInviteTimer();
                if (!this.groupUserList.length) this.groupId = "";
                return;
              }
              seconds--;
              speakerCountDown.innerHTML = seconds;
            }, 1000);
          });
        }
      });
    },
    // ----------------------------------------------获取当前设备-----------------------------------------
    async confirmSet(currentCamId, currentMicId) {
      this.currentCamId = currentCamId;
      this.currentMicId = currentMicId;
      if (this.rtcEngine === 'HST') {
        this.$nextTick(() => {
          const camvideo = document.getElementById('camvideo')
          if (camvideo) {
            this.hstRtcEngine.setMediaRender(
              this.userId,
              this.mediaType.VIDEO,
              this.currentCamId,
              document.getElementById('camvideo'),
            );
          }
          if (this.mainVideoUserId) {
            if (this.userId === this.mainVideoUserId) {
              this.hstRtcEngine.setMediaRender(
                this.userId,
                this.mediaType.VIDEO,
                this.currentCamId,
                document.getElementById('mainVideo')
              );
              this.hstRtcEngine.setMediaRender(
                this.userId,
                this.mediaType.AUDIO,
                this.currentMicId,
                this.getEleById("mainVideo")
              );
            } else {
              this.hstRtcEngine.setMediaRender(
                this.userId,
                this.mediaType.VIDEO,
                this.currentCamId,
                document.getElementById(this.userId)
              )
              this.hstRtcEngine.setMediaRender(
                this.userId,
                this.mediaType.VIDEO,
                this.currentMicId,
                document.getElementById(this.userId)
              )
            }
          }
        })
      } else if (this.rtcEngine === 'AGORA') {
        this.$nextTick(async () => {
          if (this.mainVideoUserId) {
            const currentUser = this.groupUserList.filter(item => item.userId === this.userId)
            console.log('confirmSet', currentUser, this.currentCamId)
            if (currentUser && currentUser.length > 0) {
              await currentUser[0].agoraCameraInfo.videoTrack.setDevice(this.currentCamId)
              await currentUser[0].agoraCameraInfo.audioTrack.setDevice(this.currentMicId)
            }
          }
        })
      }
    },
    // ----------------------------------------------用户在线处理-----------------------------------------
    async initAgoraRtcEngine() {
      if (this.deploymentMethod === 'PRIVATE') {
        const privateConfig = agora.privateCloudConfig
        await agoraCameraRtcEngine.setLocalAccessPointsV2(privateConfig)
        await agoraScreenRtcEngine.setLocalAccessPointsV2(privateConfig)
      }
      await agoraCameraRtcEngine.enableDualStream()
      await agoraCameraRtcEngine.setLowStreamParameter(agora.lowStreamConfig)
      agoraCameraRtcEngine.on("user-joined", this.agoraHandleUserJoined)
      agoraCameraRtcEngine.on("user-left", this.agoraHandleUserLeft)
      agoraCameraRtcEngine.on("user-published", this.agoraHandleUserPublished)
      agoraCameraRtcEngine.on("user-unpublished", this.agoraHandleUserUnpublished)
      agoraCameraRtcEngine.enableAudioVolumeIndicator()
      agoraCameraRtcEngine.on("volume-indicator", this.agoraHandleVolumeIndicator)
      // agoraCameraRtcEngine.on("network-quality", this.agoraHandleNetworkQuality)
      agoraScreenRtcEngine.on("user-joined", this.agoraScreenHandleUserJoined)
      agoraScreenRtcEngine.on("user-published", this.agoraScreenHandleUserPublished)
      agoraScreenRtcEngine.on("user-unpublished", this.agoraScreenHandleUserUnpublished)
      this.$ajax({
        url: this.$api.findShengWangInfo,
        data: {
        },
      }).then(res => {
        this.agoraAppId = res.appId
        console.log('findShengWangInfo', res)
      })
    },
    // 初始化RTC引擎
    initHstRtcEngine() {
      this.hstRtcEngine
        .init()
        .then(() => {
          // 获取媒体类型
          this.mediaType = this.hstRtcEngine.MediaType;
          // 开始连接用户心跳
          // this.userWebHeart();
          // 登录引擎
          this.loginHstRtcEngine();
          // 用户上线下线监听
          this.onOnlineUserState();
          // 获取房间里的人员
          this.onGroupUserList();
          // 有人加入房间
          this.onUserJoinGroup();
          // 有人离开房间
          this.onUserLeaveGroup();
          // 分组内有人发布媒体流
          this.onPublishMedia();
          // 分组内有人取消发布媒体流
          this.onUnPublishMedia();
          // 接收到远端媒体流
          this.onRemoteMediaAdd();

          this.onForceRemove()
        })
        .catch(() => {});
    },

    // 网络断开处理
    addEventListenerOffline(msg) {
      if (this.groupId) {
        this.$utils.setLocalStorage(
          "cacheGroupId",
          this.userId + "|" + this.groupId
        );
      }
      try {
        // 手动断开用户心跳
        if (this.userWss) {
          this.userHeartAutoClose = true;
          this.userWss.close();
          this.userWss = null;
        }
        // 手动断开会议室心跳
        if (this.roomWss) {
          this.roomHeartAutoClose = true;
          this.roomWss.close();
          this.roomWss = null;
        }
      } catch (e) {}
      // this.getOnresize()
      this.$utils.alert(msg, this.$t("confirm")).then(() => {
        this.destroy();
      });
    },
    // 用户会议室心跳
    userWebHeart() {
      this.userIsConnect = false;
      this.userWss = new WebSocket(
        `${this.$api.websocketUserAccount}?subject_token=${this.user.token}&software-version=${getSessionStorage("SoftwareVersion")}&device-type=${getSessionStorage("DeviceType")}&meeting-sdk=${process.env.VUE_APP_MEETING_SDK}`
      );
      // 连接成功
      this.userWss.onopen = () => {
        console.log("用户心跳连接成功");
        this.userIsConnect = true;
        // 监听消息
        this.userWss.onmessage = this.onmessage;
      };
      // 连接关闭
      this.userWss.onclose = () => {
        this.userIsConnect = false;
        if (this.userHeartAutoClose) return;
        this.userHeartAutoClose = false;
        this.userWssRelinkTimer = setTimeout(() => {
          clearTimeout(this.userWssRelinkTimer);
          this.userWssRelinkTimer = null;
          this.userWebHeart();
        }, 3000);
      };
    },
    // 房间会议室心跳
    roomWebHeart() {
      this.roomIsConnect = false;
      this.roomWss = new WebSocket(
        `${this.$api.websocketRoom}?subject_token=${this.user.token}&room_unique_no=${this.groupId}&software-version=${getSessionStorage("SoftwareVersion")}&device-type=${getSessionStorage("DeviceType")}&meeting-sdk=${process.env.VUE_APP_MEETING_SDK}`
      );
      // 连接成功
      this.roomWss.onopen = () => {
        this.roomIsConnect = true;
        // 监听消息
        this.roomWss.onmessage = this.onmessage;
      };
      // 断开连接
      this.roomWss.onclose = () => {
        this.roomIsConnect = false;
        if (this.roomHeartAutoClose) return;
        this.roomHeartAutoClose = false;
        this.roomWssRelinkTimer = setTimeout(() => {
          clearTimeout(this.roomWssRelinkTimer);
          this.roomWssRelinkTimer = null;
          this.roomWebHeart();
        }, 3000);
      };
    },
    // 处理断网退出房间的情况，判断房间是否存在 ROOM_000003
    roomCheckStatus(groupId, callback) {
      this.$ajax({
        url: this.$api.roomCheckStatus,
        data: {
          roomUniqueNo: groupId,
        },
      }).then((data) => {
        callback && callback(data);
      });
    },
    // 登录RTC引擎
    loginHstRtcEngine() {
      const user = this.$utils.getSessionStorage("user");
      const params = {
        appId: user.passAppId,
        token: user.passToken,
        // appId: '79cbaede62bd4742af3f0749a269fa35',
        // token: '001379Ni7m0Qw8BPwzgJT5QpwTb41xuLGmgs9y6SOK9QXVowVSQTeighGv0smnpl7zoeT4Kdtzcm2EJgFWME8nmcqMouJecscol6A4aHmLr7gtdDx0/sMC08V6TbsOywfGw',
        userId: this.userId,
        forceLogin: true,
        // accessUrl: 'http://117.185.56.42:21080/ngproxy/http/access/server/address',
      };
      this.hstRtcEngine
        .login(params)
        .then(() => {
          this.getOnlineUsers();
          // 异常退出检查加入房间
          const cacheGroupId = this.$utils.getLocalStorage("cacheGroupId");
          if (!cacheGroupId) return;
          const result = cacheGroupId.split("|");
          this.roomCheckStatus(result[1], (data) => {
            if (data.roomStatusCode === "ROOM_000003") {
              if (data[0] === this.userId) {
                this.groupId = data[1];
                this.joinGroup();
                this.$utils.deleteLocalStorage("cacheGroupId");
              }
            }
          });
        })
        .catch(() => {
          this.addEventListenerOffline(this.$t("abnormalLogin"));
        });
    },
    // 全量获取在线用户
    getOnlineUsers() {
      this.hstRtcEngine
        .getOnlineUsers()
        .then((data) => {
          console.log('getOnlineUsers', data)
          const onlineUserList = new Set();
          (data.userInfo || []).forEach((item) => {
            onlineUserList.add(item.userId);
          });
          this.onlineUserList = [...onlineUserList];
          console.log('onlineUserList', this.onlineUserList)
        })
        .catch((e) => {});
    },
    // 用户上线下线监听
    onOnlineUserState() {
      this.hstRtcEngine.subEvent("onOnlineUserState", (data) => {
        console.log('onOnlineUserState', data)
        const { userId } = data;
        if (userId === this.userId) return;
        this.getOnlineUsers();
      });
    },
    // ----------------------------------------------房间人员管理-----------------------------------------
    closeNickName() {
      this.setNicknameVisible = false
    },
    confirmNickName() {
      if (!this.nickNameDefault) {
        this.$utils.showMsg(this.$t("nicknameNoNull"), "warning");
        return
      }
      this.$ajax({
        url: this.$api.userAccountSetNickname,
        data: {
          nickname: this.nickNameDefault,
        },
      }).then(() => {
        this.groupUserList.forEach((list) => {
          if (list.userId === this.userId) {
            list.showName = this.nickNameDefault;
            this.messageList.forEach((m) => {
              if (m.userId === list.userId) {
                m.showName = list.showName;
              }
            });
          }
        });
        this.$refs.topHeader.userAccountFindInfo()
        this.sendGroupMsg({
          type: "UPDATE_NICKNAME",
        });
        this.$utils.showMsg(this.$t("editNicknameSuccess"));
        this.setNicknameVisible = false
      });
    },
    // 设置昵称
    setNickname() {
      this.setNicknameVisible = true
      this.nickNameDefault = this.groupUserList.find((list) => list.userId === this.userId).showName
    },
    // 主动加入房间
    async joinGroup() {
      console.log('--joinGroup--')
      this.$ajax({
        url: this.$api.checkRoomJoinAble,
        data: {
          uniqueNo: this.groupId,
        },
      }).then(res => {
        if (this.rtcEngine === 'HST') {
          this.hstRtcEngine
            .joinGroup(this.groupId)
            .then(() => {
              // 开始广播本地音频
              if (this.currentMicId) {
                this.isPubAudio = true;
                this.hstRtcEngine.startPublishMedia(
                  this.mediaType.AUDIO,
                  this.currentMicId,
                  null
                );
              }
              // 开始广播本地视频
              if (this.currentCamId) {
                this.isPubVideo = true;
                this.hstRtcEngine.startPublishMedia(
                  this.mediaType.VIDEO,
                  this.currentCamId,
                  {
                    width: 1280,
                    height: 720,
                    frameRate: 25,
                    bitRate: 0,
                  }
                );
              }
              const targetItem = {
                userId: this.userId,
                accountUid: this.user.accountUid,
                showName: "",
                frameRate: 0,
                audioMediaId: this.currentMicId,
                videoMediaId: this.currentCamId,
                activePubAudio: true,
                activePubVideo: true,
                myAudio: true,
                myVideo: true,
                pubAudio: true,
                pubVideo: true,
              };
              // 更新房间人员
              this.getUserInfo(this.userId, (data) => {
                targetItem.showName = data.nickname;
              });
              this.groupUserList.push(targetItem);
              this.getStreamStats(this.currentMicId, targetItem);
              // console.log('------hstGetUserResolution------')
              // this.hstGetUserResolution(this.currentCamId, targetItem)
              // 将主视频框人员设置为自己
              this.mainVideoUserId = this.userId;
              // 渲染本地视频和音频
              this.$nextTick(() => {
                // 渲染本地视频
                this.hstRtcEngine.setMediaRender(
                  this.userId,
                  this.mediaType.VIDEO,
                  this.currentCamId,
                  this.getEleById("mainVideo")
                );
              });
              // 连接会议室心跳
              this.roomWebHeart();
              // 检查是否有主讲
              this.roomCheckStatus(this.groupId, (data) => {
                if (data.roomStatusCode === "ROOM_000003" && data.hostAccountId) {
                  this.speakerUserId = data.hostAccountId;
                  this.getUserType();
                }
              });
              console.log(this.groupUserList)
              // 计入会议后清除deleteLocalStorage 只能进去一次
              this.$utils.deleteLocalStorage('referUrl')
            })
            .catch((e) => {
            });
        } else if (this.rtcEngine === 'AGORA') {
          console.log('agoraJoinGroup', new Date())
          this.agoraJoinGroup()
        }
      }).catch(err => {
      })
    },
    async agoraJoinGroup() {
      const agoraAccount = await this.$ajax({
        url: this.$api.findShengWangAccount,
        data: {
          accountUid: this.user.accountUid,
        },
      })
      this.agoraAccount = agoraAccount
      const agoraCameraAccount = this.agoraAccount.shengWangAccounts.filter(item => item.accountType === 'CAMERA_ACCOUNT')[0]
      const agoraScreenAccount = this.agoraAccount.shengWangAccounts.filter(item => item.accountType === 'SCREEN_SHARE_ACCOUNT')[0]
      const agoraCameraToken = await this.$ajax({
        url: this.$api.getShengWangToken,
        data: {
          channelName: this.groupId,
          uid: agoraCameraAccount.id,
        },
      })
      console.log('agoraCameraToken', agoraCameraToken)
      const agoraScreenToken = await this.$ajax({
        url: this.$api.getShengWangToken,
        data: {
          channelName: this.groupId,
          uid: agoraScreenAccount.id,
        },
      })
      console.log('agoraScreenToken', agoraScreenToken)
      // const devices = await AgoraRTC.getDevices()
      // console.log('devices', devices)
      console.log('cameraJoin', this.agoraAppId, this.groupId, agoraCameraToken.token, agoraCameraAccount.id)
      console.log('相机ID', agoraScreenAccount.id, '加入频道', this.groupId, '成功')
      console.log('screenJoin', this.agoraAppId, this.groupId, agoraScreenToken.token, agoraScreenAccount.id)
      console.log('屏幕ID', agoraScreenAccount.id, '加入频道', this.groupId, '成功')
      if (this.deploymentMethod === 'PRIVATE') {
        await agoraCameraRtcEngine.join(this.agoraAppId, this.groupId, null, agoraCameraAccount.id || null)
        await agoraScreenRtcEngine.join(this.agoraAppId, this.groupId, null, agoraScreenAccount.id || null)
      } else if (this.deploymentMethod === 'PUBLIC') {
        await agoraCameraRtcEngine.join(this.agoraAppId, this.groupId, agoraCameraToken.token || null, agoraCameraAccount.id || null)
        await agoraScreenRtcEngine.join(this.agoraAppId, this.groupId, agoraScreenToken.token || null, agoraScreenAccount.id || null)
      }
      let tracks = [{}, {}]
      if (this.currentMicId) {
        tracks = await Promise.all([
          AgoraRTC.createMicrophoneAudioTrack({
            microphoneId: this.currentMicId,
          }),
          AgoraRTC.createCameraVideoTrack({
            cameraId: this.currentCamId,
            encoderConfig: agora.encoderConfig,
            optimizationMode: agora.optimizationMode
          })
        ])
      }
      console.log('tracks', tracks)
      console.log('currentMicId', this.currentMicId)
      const targetItem = {
        userId: this.userId,
        showName: "",
        frameRate: 0,
        audioMediaId: this.currentMicId,
        videoMediaId: this.currentCamId,
        activePubAudio: true,
        activePubVideo: true,
        myAudio: true,
        myVideo: true,
        pubAudio: !!this.currentCamId,
        pubVideo: !!this.currentMicId,
        agoraCameraInfo: {
          uid: agoraCameraAccount.id,
          videoTrack: tracks[1],
          audioTrack: tracks[0],
        }
      };
      console.log('targetItem', targetItem)
      // 更新房间人员
      this.getUserInfo(this.userId, (data) => {
        console.log('getUserInfo', data)
        targetItem.showName = data.nickname;
        targetItem.userAccountId = data.uid;
      });
      this.groupUserList.push(targetItem);
      // this.getStreamStats(this.currentMicId, targetItem);
      // 将主视频框人员设置为自己
      this.mainVideoUserId = this.userId;
      // 渲染本地视频和音频
      this.$nextTick(async () => {
        // 渲染本地视频
        if (this.currentMicId) {
          await agoraCameraRtcEngine.setRemoteVideoStreamType(targetItem.agoraCameraInfo.uid, 0)
          await targetItem.agoraCameraInfo.videoTrack.setEncoderConfiguration(agora.encoderConfig)
          targetItem.agoraCameraInfo.videoTrack.play(this.getEleById('mainVideo'), { mirror: agora.mirror, fit: agora.fit })
          // targetItem.agoraCameraInfo.audioTrack.play() // 不播放本地音频
          agoraCameraRtcEngine.publish(tracks) // 将本地音视频push到远端
          this.isPubVideo = true
          this.isPubAudio = true
        } else {
          this.isPubVideo = false
          this.isPubAudio = false
        }
        this.agoraGetVideoStats()
      });
      // 连接会议室心跳
      this.roomWebHeart();
      // 检查是否有主讲
      this.roomCheckStatus(this.groupId, (data) => {
        if (data.roomStatusCode === "ROOM_000003" && data.hostAccountId) {
          this.speakerUserId = data.hostAccountId;
          this.getUserType();
        }
      });
      console.log('AGORA-JOINED', this.groupUserList)
      // 计入会议后清除deleteLocalStorage 只能进去一次
      this.$utils.deleteLocalStorage('referUrl')
    },
    async agoraGetVideoStats() {
      setTimeout(() => {
        this.agoraGetVideoStatsTimer = setInterval(async () => {
          const localStats = await agoraCameraRtcEngine.getLocalVideoStats()
          // console.log('localStats', localStats)
          const stats = {
            width: localStats.sendResolutionWidth,
            height: localStats.sendResolutionHeight,
            bitrate: localStats.sendBitrate,
            frameRate: localStats.sendFrameRate,
            delay: 0,
          }
          this.groupUserList.map(item => {
            if (item.userId === this.userId) {
              item.videoStats = stats
            }
            return item
          })
          const remoteStats = await agoraCameraRtcEngine.getRemoteVideoStats()
          // console.log('remoteStats', remoteStats)
          Object.keys(remoteStats).forEach(item => {
            const data = {
              width: remoteStats[item].receiveResolutionWidth,
              height: remoteStats[item].receiveResolutionHeight,
              bitrate: remoteStats[item].receiveBitrate,
              frameRate: remoteStats[item].receiveFrameRate,
              delay: remoteStats[item].transportDelay,
            }
            this.groupUserList.map(item2 => {
              if (parseInt(item2.agoraCameraInfo.uid) === parseInt(item)) {
                item2.videoStats = data
              }
              return item2
            })
          })
          // console.log('agoraGetVideoStats', this.groupUserList)
        }, 1000)
      }, 2000)
    },
    async agoraScreenJoinGroup() {
      // const audioTracks = await AgoraRTC.createMicrophoneAudioTrack()
      const screenTracks = await AgoraRTC.createScreenVideoTrack({
        encoderConfig: agora.encoderScreenConfig,
        // encoderConfig: agora.customScreenEncoderConfig,
        optimizationMode: agora.screenOptimizationMode
      }, "disable")
      let screenTrack
      console.log('screenTracks', screenTracks)
      if (screenTracks instanceof Array) {
        screenTrack = [...screenTracks]
        this.agoraLocalScreenVideoTracks = screenTracks[0]
        // this.agoraLocalScreenAudioTracks = screenTracks[1]
        // screenTracks[0].play('mainVideo')
        // screenTracks[1].play()
      } else {
        screenTrack = [screenTracks]
        this.agoraLocalScreenVideoTracks = screenTracks
        // this.agoraLocalScreenAudioTracks = audioTracks
        // screenTracks.play('mainVideo')
        // audioTracks.play()
      }
      this.agoraLocalScreenVideoTracks.play('mainVideo')
      // this.agoraLocalScreenAudioTracks.play()
      agoraScreenRtcEngine.publish(screenTrack)
    },
    async getAgoraAccountType(shengWangUid) {
      const agoraAccount = await this.$ajax({
        url: this.$api.findShengWangAccount,
        data: {
          shengWangUid: shengWangUid,
        },
      })
      return agoraAccount.shengWangAccounts.filter(item => item.id === shengWangUid)[0].accountType
    },
    async agoraHandleUserJoined(user) {
      console.log('agoraHandleUserJoined-time', new Date())
      if (await this.getAgoraAccountType(user.uid) === 'CAMERA_ACCOUNT') {
        const userInfo = await this.$ajax({
          url: this.$api.userfindInfo,
          data: {
            shengWangeId: user.uid,
          },
        })
        console.log('agoraHandleUserJoined-userInfo', userInfo)
        console.log('agoraHandleUserJoined-user', user)
        const userId = userInfo.accountId
        if (this.groupUserList.filter(item => item.userId === userId).length === 0) {
          const targetItem = {
            userId: userId,
            showName: "",
            frameRate: 0,
            pubShare: false,
            pubAudio: false,
            pubVideo: false,
            activePubAudio: true,
            activePubVideo: true,
            shareMediaId: "",
            audioMediaId: "",
            videoMediaId: "",
            volume: 0,
            myAudio: true,
            myVideo: true,
            currentAudioStatus: true,
            currentVideoStatus: true,
            agoraCameraInfo: user
          };
          this.getUserInfo(userId, (userInfo) => {
            console.log('userInfo', userInfo)
            targetItem.showName = userInfo.nickname;
            targetItem.userAccountId = userInfo.uid;
            // 显示有人加入房间消息
            this.showChatMsg({
              userId: userId,
              name: userInfo.nickname,
              headImg: userInfo.headImg,
              msg: `${userInfo.nickname}(${userId}) ${this.$t("joinRoom")}`,
            });
            // debugger
          });
          this.groupUserList.push(targetItem);
          console.log('agoraHandleUserJoined-this.groupUserList', this.groupUserList)
        }
      }
    },
    async agoraScreenHandleUserJoined(user) {
      if (await this.getAgoraAccountType(user.uid) === 'SCREEN_SHARE_ACCOUNT') {
        const userInfo = await this.$ajax({
          url: this.$api.userfindInfo,
          data: {
            shengWangeId: user.uid,
          },
        })
        const userId = userInfo.accountId
        this.groupUserList.map(item => {
          if (item.userId === userId) {
            item.agoraScreenInfo = user
          }
          return item
        })
        console.log('agoraScreenHandleUserJoined', this.groupUserList)
      }
    },
    async agoraHandleUserPublished(user, mediaType) {
      setTimeout(async () => {
        console.log('agoraHandleUserPublished-time', new Date())
        if (await this.getAgoraAccountType(user.uid) === 'CAMERA_ACCOUNT') {
          console.log('agoraHandleUserPublished-uid', user.uid)
          console.log('agoraHandleUserPublished-this.groupUserList', this.groupUserList)
          // const groupUserList = [...this.groupUserList]
          // console.log('agoraHandleUserPublished-groupUserList', groupUserList)
          const currentUser = this.groupUserList.filter(item => item.agoraCameraInfo.uid === user.uid)[0]
          console.log('agoraHandleUserPublished-media', mediaType)
          await agoraCameraRtcEngine.subscribe(user, mediaType)
          console.log('agoraHandleUserPublished-currentUser', currentUser)
          if (mediaType === 'video') {
            currentUser.pubVideo = true
            // if (currentUser?.activePubVideo) {
            //
            //   currentUser.myVideo = true
            // } else {
            //   currentUser.pubVideo = false
            //   currentUser.myVideo = false
            // }
          } else if (mediaType === 'audio') {
            currentUser.pubAudio = true
            // currentUser.myAudio = true
          }
          this.$nextTick(async () => {
            if (mediaType === 'video' && currentUser?.activePubVideo) {
              if (currentUser.userId === this.mainVideoUserId) {
                await agoraCameraRtcEngine.setRemoteVideoStreamType(currentUser.agoraCameraInfo.uid, 0)
                currentUser.agoraCameraInfo.videoTrack.play(this.getEleById('mainVideo'), {
                  mirror: agora.mirror,
                  fit: agora.fit
                })
              } else {
                await agoraCameraRtcEngine.setRemoteVideoStreamType(currentUser.agoraCameraInfo.uid, 1)
                currentUser.agoraCameraInfo.videoTrack.play(this.getEleById('video-' + currentUser.userId), {
                  mirror: agora.mirror,
                  fit: agora.fit
                })
              }
            } else if (mediaType === 'audio' && currentUser?.activePubVideo) {
              currentUser.agoraCameraInfo.audioTrack.play()
            }
          })
        }
      }, 500)
    },
    async agoraScreenHandleUserPublished(user, mediaType) {
      setTimeout(async () => {
        if (await this.getAgoraAccountType(user.uid) === 'SCREEN_SHARE_ACCOUNT') {
          console.log('agoraScreenHandleUserPublished-groupUserList', this.groupUserList)
          const currentUser = this.groupUserList.filter(item => item.agoraScreenInfo?.uid === user.uid)[0]
          if (currentUser.userId !== this.userId) {
            console.log('mediaType', mediaType)
            await agoraScreenRtcEngine.subscribe(user, mediaType)
            console.log('agoraHandleUserPublished-currentUser', currentUser)
            if (mediaType === 'video' && currentUser.activePubVideo) {
              currentUser.pubVideo = true
              // currentUser.myVideo = true
            } else if (mediaType === 'audio' && currentUser.activePubVideo) {
              currentUser.pubAudio = true
              // currentUser.myAudio = true
            }
            this.showShareVideo = true
            this.isPubShare = true
            this.$nextTick(() => {
              if (mediaType === 'video' && currentUser.activePubVideo) {
                // currentUser.agoraCameraInfo.videoTrack.stop()
                currentUser.agoraScreenInfo.videoTrack.play(this.getEleById('shareVideo'), { fit: agora.fit })
              } else if (mediaType === 'audio' && currentUser.activePubVideo) {
                // currentUser.agoraCameraInfo.audioTrack.stop()
                currentUser.agoraScreenInfo.audioTrack.play()
              }
            })
          }
        }
      }, 100)
    },
    async agoraHandleUserUnpublished(user, mediaType) {
      if (await this.getAgoraAccountType(user.uid) === 'CAMERA_ACCOUNT') {
        await agoraCameraRtcEngine.unsubscribe(user, mediaType)
        const currentUser = this.groupUserList.filter(item => item.agoraCameraInfo.uid === user.uid)[0]
        console.log('currentUser', currentUser)
        if (mediaType === 'video') {
          currentUser.pubVideo = false
          // currentUser.myVideo = false
        } else if (mediaType === 'audio') {
          currentUser.pubAudio = false
          // currentUser.myAudio = false
        }
      }
    },

    async agoraHandleUserLeft(user, reason) {
      const groupUserList = this.$utils.copyData(this.groupUserList)
      const currentUser = groupUserList.filter(item => item.agoraCameraInfo.uid === user.uid)[0]
      this.groupUserList = this.groupUserList.filter(item => item.agoraCameraInfo.uid !== user.uid)
      if (currentUser) {
        if (currentUser.userId === this.userId) {
          clearInterval(this.agoraGetVideoStatsTimer)
          this.agoraGetVideoStatsTimer = null
        }
        // 如果离开的人员是主视频框人员
        if (currentUser.userId === this.mainVideoUserId) {
          this.mainVideoIsAR = false;
          this.mainVideoUserId = this.userId;
          // this.hstRtcEngine.setMediaRender(
          //   this.userId,
          //   this.mediaType.VIDEO,
          //   this.currentCamId,
          //   this.getEleById("mainVideo")
          // );
          const mainUser = this.groupUserList.filter(item => item.userId === this.userId)[0]
          await agoraCameraRtcEngine.setRemoteVideoStreamType(mainUser.agoraCameraInfo.uid, 0)
          mainUser.agoraCameraInfo.videoTrack.play(this.getEleById('mainVideo'), { mirror: agora.mirror, fit: agora.fit })
        }
        // 显示有人离开房间消息
        this.getUserInfo(currentUser.userId, (userInfo) => {
          // 显示有人加入房间消息
          this.showChatMsg({
            userId: currentUser.userId,
            name: userInfo.nickname,
            headImg: userInfo.headImg,
            msg: `${userInfo.nickname}(${currentUser.userId}) ${this.$t("leaveRoom")}`,
          })
        })
      }
    },

    async agoraScreenHandleUserUnpublished(user, mediaType) {
      if (await this.getAgoraAccountType(user.uid) === 'SCREEN_SHARE_ACCOUNT') {
        await agoraScreenRtcEngine.unsubscribe(user, mediaType)
        this.showShareVideo = false
        this.isPubShare = false
        // const currentUser = this.groupUserList.filter(item => item.agoraScreenInfo?.uid === user.uid)[0]
        // this.$nextTick(() => {
        //   if (mediaType === 'video' && currentUser.activePubVideo) {
        //     currentUser.agoraScreenInfo.videoTrack.stop()
        //     currentUser.agoraCameraInfo.videoTrack.play(this.getEleById('video-' + currentUser.userId), {})
        //   } else if (mediaType === 'audio' && currentUser.activePubVideo) {
        //     currentUser.agoraScreenInfo.audioTrack.stop()
        //     currentUser.agoraCameraInfo.audioTrack.play()
        //   }
        // })
      }
    },

    agoraHandleVolumeIndicator(result) {
      result.forEach((volume, index) => {
        // console.log(`${index} UID ${volume.uid} Level ${volume.level}`)
        const user = this.groupUserList.filter(item => item.agoraCameraInfo.uid === volume.uid)[0]
        if (user.userId === this.userId) {
          if (!this.isPubAudio) {
            this.$nextTick(() => {
              user.volume = 0;
              this.$utils.setSessionStorage(
                `${user.userId}:volume:`,
                user.volume
              );
            });
            return;
          }
        } else if (!user.pubAudio) {
          this.$nextTick(() => {
            user.volume = 0;
            this.$utils.setSessionStorage(
              `${user.userId}:volume:`,
              user.volume
            );
          });
          return;
        }
        if (+volume.level && +volume.level >= 0) {
          if (user.userId === this.userId) {
            this.$nextTick(() => {
              user.volume = +volume.level;
              this.$utils.setSessionStorage(
                `${user.userId}:volume:`,
                user.volume
              );
            });
            this.$set(this.$data, 'currentAudioVolume', this.$utils.getSessionStorage(`${user.userId}:volume:`))
          } else {
            this.$nextTick(() => {
              user.volume = +volume.level;
              this.$utils.setSessionStorage(
                `${user.userId}:volume:`,
                user.volume
              );
            });
          }
        } else {
          this.$nextTick(() => {
            user.volume = 0;
            this.$utils.setSessionStorage(
              `${user.userId}:volume:`,
              user.volume
            )
          })
        }
      })
    },
    agoraHandleNetworkQuality(stats) {
      console.log('agoraHandleNetworkQuality', stats)
    },
    // 当用户加入房间时，全量获取房间里的人员，只有首次加入房间才会触发，并且里面没有自己(即userId没有自己)
    onGroupUserList() {
      console.log('line.3168:onGroupUserList')
      this.hstRtcEngine.subEvent("onGroupUserList", (users) => {
        users.forEach((userId) => {
          const targetItem = {
            userId: userId,
            showName: "",
            frameRate: 0,
            pubShare: false,
            pubAudio: false,
            pubVideo: false,
            activePubAudio: true,
            activePubVideo: true,
            shareMediaId: "",
            audioMediaId: "",
            videoMediaId: "",
            volume: 0,
            myAudio: true,
            myVideo: true,
            currentAudioStatus: true,
            currentVideoStatus: true
          };
          this.getUserInfo(userId, (userInfo) => {
            targetItem.showName = userInfo.nickname;
            targetItem.userAccountId = userInfo.uid;
          });
          this.groupUserList.push(targetItem);
        });
        console.log('line.3193:this.groupUserList', this.groupUserList)
      });
    },
    // 有人加入房间，当我在会议中时才会触发，首次加入房间不触发
    onUserJoinGroup() {
      console.log('line.3200:onUserJoinGroup')
      this.hstRtcEngine.subEvent("onUserJoinGroup", (userId) => {
        const targetItem = {
          userId: userId,
          showName: "",
          frameRate: 0,
          pubShare: false,
          pubAudio: false,
          pubVideo: false,
          activePubAudio: true,
          activePubVideo: true,
          shareMediaId: "",
          audioMediaId: "",
          videoMediaId: "",
          volume: 0,
          myAudio: true,
          myVideo: true,
          currentAudioStatus: true,
          currentVideoStatus: true
        };
        this.getUserInfo(userId, (userInfo) => {
          targetItem.showName = userInfo.nickname;
          targetItem.userAccountId = userInfo.uid;
          // 显示有人加入房间消息
          this.showChatMsg({
            userId: userId,
            name: userInfo.nickname,
            headImg: userInfo.headImg,
            msg: `${userInfo.nickname}(${userId}) ${this.$t("joinRoom")}`,
          });
          // debugger
        });
        this.groupUserList.push(targetItem);
      });
      console.log('line.3234:this.groupUserList', this.groupUserList)
    },
    // 有人离开房间
    onUserLeaveGroup() {
      console.log('line.3237:onUserLeaveGroup')
      this.hstRtcEngine.subEvent("onUserLeaveGroup", (userId) => {
        const index = this.groupUserList.findIndex(
          (item) => item.userId === userId
        );
        const currentUser = this.groupUserList[index];
        try {
          // pubShare: false,
          // pubAudio: false,
          // pubVideo: false,
          // shareMediaId: "",
          // audioMediaId: "",
          // videoMediaId: "",
          // 停止接收远端媒体，包括音频、视频、屏幕共享和电子白板和删除媒体渲染对象。
          const {
            pubShare,
            shareMediaId,
            pubAudio,
            audioMediaId,
            pubVideo,
            videoMediaId,
          } = currentUser;
          const videoEleId =
            this.mainVideoUserId === userId ? "mainVideo" : userId;
          if (pubShare) {
            this.hstRtcEngine.stopReceiveMedia(
              userId,
              this.mediaType.SCREEN_SHARE,
              shareMediaId
            );
            this.hstRtcEngine.unsetMediaRender(
              userId,
              this.mediaType.SCREEN_SHARE,
              this.getEleById(videoEleId)
            );
          }
          if (pubAudio) {
            this.hstRtcEngine.stopReceiveMedia(
              userId,
              this.mediaType.AUDIO,
              audioMediaId
            );
            this.hstRtcEngine.unsetMediaRender(
              userId,
              this.mediaType.AUDIO,
              this.getEleById(videoEleId)
            );
          }
          if (pubVideo) {
            this.hstRtcEngine.stopReceiveMedia(
              userId,
              this.mediaType.VIDEO,
              videoMediaId
            );
            this.hstRtcEngine.unsetMediaRender(
              userId,
              this.mediaType.VIDEO,
              this.getEleById(videoEleId)
            );
          }
        } catch (e) {}
        // 删除离开人员
        if (index >= 0) {
          this.groupUserList.splice(index, 1);
        }
        // 如果离开的人员是主视频框人员
        if (userId === this.mainVideoUserId) {
          this.mainVideoIsAR = false;
          this.mainVideoUserId = this.userId;
          this.hstRtcEngine.setMediaRender(
            this.userId,
            this.mediaType.VIDEO,
            this.currentCamId,
            this.getEleById("mainVideo")
          );
        }
        // 如果离开的人员是主讲人
        if (userId === this.speakerUserId) {
          this.speakerUserId = "";
          // 如果主讲人有屏幕共享
          if (currentUser.pubShare) {
            this.hstRtcEngine.unsetMediaRender(
              userId,
              this.mediaType.SCREEN_SHARE,
              this.getEleById("shareVideo")
            );
            this.hstRtcEngine.startReceiveMedia(
              userId,
              this.mediaType.SCREEN_SHARE,
              currentUser.mediaId
            );
            this.showShareVideo = false;
          }
          // 如果主讲人正在进行动态标记
          if (this.activePointVideoUserId) {
            if (this.activePointVideoUserId === this.mainVideoUserId) {
              this.getEleById("mainVideo").parentElement.className = "";
            } else {
              this.getEleById(
                this.activePointVideoUserId
              ).parentElement.className = "";
            }
            this.activePointVideoUserId = "";
          }
        }
        // 显示有人离开房间消息
        this.getUserInfo(userId, (userInfo) => {
          // 显示有人加入房间消息
          this.showChatMsg({
            userId: userId,
            name: userInfo.nickname,
            headImg: userInfo.headImg,
            msg: `${userInfo.nickname}(${userId}) ${this.$t("leaveRoom")}`,
          });
        });
      });
    },
    // -------------------------------------分组内有人发布媒体流和取消发布媒体流-----------------------------
    // 分组内有人发布媒体流
    onPublishMedia() {
      this.hstRtcEngine.subEvent("onPublishMedia", (data) => {
        console.log('收到媒体流', data)
        const { mediaType, userId, mediaId } = data;
        const currentUser = this.groupUserList.find(
          (list) => list.userId === userId
        );
        switch (mediaType) {
          case this.mediaType.SCREEN_SHARE: // 屏幕共享
            if (currentUser.pubShare) return;
            currentUser.pubShare = true;
            currentUser.shareMediaId = mediaId;
            this.hstRtcEngine.startReceiveMedia(
              userId,
              this.mediaType.SCREEN_SHARE,
              mediaId
            );
            break;
          case this.mediaType.AUDIO: // 音频
            if (mediaId === "reserved_audioid_systemplay") {
              this.hstRtcEngine.startReceiveMedia(
                userId,
                this.mediaType.AUDIO,
                mediaId
              );
            } else {
              if (currentUser.pubAudio) return;
              currentUser.currentAudioStatus = true
              currentUser.pubAudio = true;
              currentUser.audioMediaId = mediaId;
              if (currentUser.myAudio) {
                this.hstRtcEngine.startReceiveMedia(
                  userId,
                  this.mediaType.AUDIO,
                  mediaId
                );
              }
            }
            break;
          case this.mediaType.VIDEO: // 视频
            if (currentUser.pubVideo) return;
            currentUser.pubVideo = true;
            currentUser.currentVideoStatus = true
            currentUser.videoMediaId = mediaId;
            if (currentUser.myVideo) {
              this.hstRtcEngine.startReceiveMedia(
                userId,
                this.mediaType.VIDEO,
                mediaId
              );
            }
            // this.hstRtcEngine.startReceiveMedia(
            //   userId,
            //   this.mediaType.VIDEO,
            //   mediaId
            // );
            break;
          default: // do something
        }
      });
    },
    // 分组内有人取消发布媒体流
    onUnPublishMedia() {
      this.hstRtcEngine.subEvent("onUnPublishMedia", (data) => {
        const { mediaType, userId, mediaId } = data;
        const currentUser = this.groupUserList.find(
          (list) => list.userId === userId
        );
        const videoEleId = userId === this.mainVideoUserId ? "mainVideo" : userId
        switch (mediaType) {
          case this.mediaType.SCREEN_SHARE: // 屏幕共享
            currentUser.pubShare = false;
            currentUser.shareMediaId = "";
            this.hstRtcEngine.unsetMediaRender(
              userId,
              this.mediaType.SCREEN_SHARE,
              this.getEleById("shareVideo")
            );
            this.hstRtcEngine.stopReceiveMedia(
              userId,
              this.mediaType.SCREEN_SHARE,
              mediaId
            );
            this.showShareVideo = false;
            break;
          case this.mediaType.AUDIO: // 音频
            if (mediaId === "reserved_audioid_systemplay") {
              this.hstRtcEngine.unsetMediaRender(
                userId,
                this.mediaType.AUDIO,
                this.getEleById("systemAudio")
              );
              this.hstRtcEngine.stopReceiveMedia(
                userId,
                this.mediaType.AUDIO,
                mediaId
              );
            } else {
              currentUser.currentAudioStatus = false
              currentUser.pubAudio = false;
              currentUser.audioMediaId = "";
              if (currentUser.myAudio) {
                this.hstRtcEngine.stopReceiveMedia(
                  userId,
                  this.mediaType.AUDIO,
                  mediaId
                );
                this.$nextTick(() => {
                  currentUser.volume = 0;
                  this.$utils.setSessionStorage(
                    `${userId}:volume:`,
                    currentUser.volume
                  );
                });
              }
            }
            break;
          case this.mediaType.VIDEO: // 视频
            currentUser.pubVideo = false;
            currentUser.videoMediaId = "";
            currentUser.currentVideoStatus = false
            if (currentUser.myVideo) {
              this.hstRtcEngine.stopReceiveMedia(
                userId,
                this.mediaType.VIDEO,
                mediaId
              );
            }
            this.hstRtcEngine.unsetMediaRender(
              userId,
              this.mediaType.VIDEO,
              this.getEleById(videoEleId)
            );

            if (currentUser.pubAudio && currentUser.activePubAudio) {
              this.hstRtcEngine.setMediaRender(
                userId,
                this.mediaType.AUDIO,
                currentUser.audioMediaId,
                this.getEleById(videoEleId)
              );
            }
            if (currentUser.pubAudio) {
              this.$nextTick(() => {
                currentUser.volume = 0;
                this.$set(this.$data, 'currentAudioVolume', 0)
              });
            }
            console.log(currentUser)
            // debugger
            break;
          default: // do something
        }
      });
    },
    // -------------------------------------------------接收到远端媒体流------------------------------------
    // 接收到远端媒体流
    onRemoteMediaAdd() {
      this.hstRtcEngine.subEvent("onRemoteMediaAdd", (data) => {
        console.log(data)
        const { mediaType, userId, mediaId } = data;
        const currentUser = this.groupUserList.find(
          (list) => list.userId === userId
        );
        const videoEleId =
          userId === this.mainVideoUserId ? "mainVideo" : userId;
        switch (mediaType) {
          case 0: // 屏幕共享
            this.showShareVideo = true;
            currentUser.shareMediaId = mediaId;
            this.$nextTick(() => {
              this.hstRtcEngine.setMediaRender(
                userId,
                this.mediaType.SCREEN_SHARE,
                mediaId,
                this.getEleById("shareVideo")
              );
            });
            break;
          case 1: // 音频
            if (mediaId === "reserved_audioid_systemplay") {
              this.hstRtcEngine.setMediaRender(
                userId,
                this.mediaType.AUDIO,
                mediaId,
                this.getEleById("systemAudio")
              );
            } else {
              currentUser.currentAudioStatus = true
              currentUser.audioMediaId = mediaId;
              this.hstRtcEngine.setMediaRender(
                userId,
                this.mediaType.AUDIO,
                mediaId,
                this.getEleById(videoEleId)
              );
              this.getStreamStats(mediaId, currentUser);
            }
            break;
          case 2: // 视频
            currentUser.videoMediaId = mediaId;
            currentUser.currentVideoStatus = true
            this.hstRtcEngine.setMediaRender(
              userId,
              this.mediaType.VIDEO,
              mediaId,
              this.getEleById(videoEleId)
            );
            console.log('渲染时:userId, this.mediaType.VIDEO, mediaId', userId, this.mediaType.VIDEO, mediaId)
            // this.hstGetUserResolution(mediaId, currentUser)
            if (currentUser.pubAudio) {
              this.$nextTick(() => {
                currentUser.volume = 0;
                this.$set(this.$data, 'currentAudioVolume', 0)
              });
            }
            break;
          default: // do something
        }
      });
    },
    // ----------------------------------------------打开与关闭本地相机和音频--------------------------------
    // 关闭与打开本地相机
    changeIsPubVideo() {
      if (this.rtcEngine === 'HST') {
        const currentUser = this.groupUserList.find(
          (list) => list.userId === this.userId
        );
        const videoEleId = this.mainVideoUserId === this.userId ? "mainVideo" : this.userId;
        if (this.isPubVideo) {
          this.isPubVideo = false;
          this.pubAudio = false;
          currentUser.activePubVideo = false;
          this.hstRtcEngine.stopPublishMedia(
            this.mediaType.VIDEO,
            this.currentCamId
          );
          this.hstRtcEngine.unsetMediaRender(
            this.userId,
            this.mediaType.VIDEO,
            this.getEleById(videoEleId)
          );
        } else {
          this.isPubVideo = true;
          this.pubAudio = true;
          currentUser.activePubVideo = true;
          this.hstRtcEngine.startPublishMedia(
            this.mediaType.VIDEO,
            this.currentCamId,
            {
              width: 1280,
              height: 720,
              frameRate: 25,
              bitRate: 0,
            }
          );
          this.hstRtcEngine.setMediaRender(
            this.userId,
            this.mediaType.VIDEO,
            this.currentCamId,
            this.getEleById(videoEleId)
          );
        }
      } else if (this.rtcEngine === 'AGORA') {
        this.agoraChangeVideo()
      }
    },
    async agoraChangeVideo() {
      const currentUser = this.groupUserList.find(
        (list) => list.userId === this.userId
      );
      const videoEleId = this.mainVideoUserId === this.userId ? 'mainVideo' : 'video-' + this.userId;
      if (this.isPubVideo) {
        this.isPubVideo = false;
        this.pubAudio = false;
        currentUser.activePubVideo = false;
        currentUser.agoraCameraInfo.videoTrack.stop()
        await agoraCameraRtcEngine.unpublish(currentUser.agoraCameraInfo.videoTrack)
      } else {
        this.isPubVideo = true;
        this.pubAudio = true;
        currentUser.activePubVideo = true;
        currentUser.agoraCameraInfo.videoTrack.play(this.getEleById(videoEleId), { mirror: agora.mirror, fit: agora.fit })
        await agoraCameraRtcEngine.publish(currentUser.agoraCameraInfo.videoTrack)
      }
    },
    // 打开与关闭麦克风
    changeIsPubAudio() {
      if (this.rtcEngine === 'HST') {
        const currentUser = this.groupUserList.find(
          (list) => list.userId === this.userId
        );
        console.log(currentUser)
        if (this.isPubAudio) {
          this.isPubAudio = false;
          currentUser.pubAudio = false;
          currentUser.activePubAudio = false;
          this.hstRtcEngine.stopPublishMedia(this.mediaType.AUDIO);
          this.$nextTick(() => {
            currentUser.volume = 0;
            this.$utils.setSessionStorage(
              `${currentUser.userId}:volume:`,
              currentUser.volume
            );
          });
        } else {
          this.isPubAudio = true;
          currentUser.pubAudio = true;
          currentUser.activePubAudio = true;
          this.hstRtcEngine.startPublishMedia(
            this.mediaType.AUDIO,
            this.currentMicId,
            null
          );
        }
      } else {
        this.agoraChangeAudio()
      }
    },
    agoraChangeAudio() {
      const currentUser = this.groupUserList.find(
        (list) => list.userId === this.userId
      );
      if (this.isPubAudio) {
        this.isPubAudio = false;
        currentUser.pubAudio = false;
        currentUser.activePubAudio = false;
        // currentUser.agoraCameraInfo.audioTrack.stop()
        agoraCameraRtcEngine.unpublish(currentUser.agoraCameraInfo.audioTrack)
        this.$nextTick(() => {
          currentUser.volume = 0;
          this.$utils.setSessionStorage(
            `${currentUser.userId}:volume:`,
            currentUser.volume
          );
        });
      } else {
        this.isPubAudio = true;
        currentUser.pubAudio = true;
        currentUser.activePubAudio = true;
        // currentUser.agoraCameraInfo.audioTrack.play()
        agoraCameraRtcEngine.publish(currentUser.agoraCameraInfo.audioTrack)
      }
    },
    // 停止与开始渲染他人视频媒体流
    changeIsPubVideoOther(item) {
      if (this.rtcEngine === 'HST') {
        if (item.currentVideoStatus) {
          if (item.activePubVideo) {
            item.activePubVideo = false;
            item.myVideo = false;
            this.hstRtcEngine.stopReceiveMedia(
              item.userId,
              this.mediaType.VIDEO,
              item.videoMediaId
            );
            const videoEleId = item.userId === this.mainVideoUserId ? "mainVideo" : item.userId;
            this.hstRtcEngine.unsetMediaRender(
              item.userId,
              this.mediaType.VIDEO,
              this.getEleById(videoEleId)
            );
            if (item.activePubAudio) {
              setTimeout(() => {
                this.hstRtcEngine.setMediaRender(
                  item.userId,
                  this.mediaType.AUDIO,
                  item.audioMediaId,
                  this.getEleById(videoEleId)
                );
              }, 200)
            }
          } else {
            item.activePubVideo = true;
            item.myVideo = true;
            item.pubVideo = true;
            this.hstRtcEngine.startReceiveMedia(
              item.userId,
              this.mediaType.VIDEO,
              item.videoMediaId
            );
          }
        }
      } else if (this.rtcEngine === 'AGORA') {
        this.agoraChangeOtherVideo(item)
      }
    },
    agoraChangeOtherVideo(item) {
      const videoEleId = item.userId === this.mainVideoUserId ? 'mainVideo' : 'video-' + item.userId;
      if (item.currentVideoStatus) {
        if (item.activePubVideo) {
          item.activePubVideo = false;
          item.myVideo = false;
          item.agoraCameraInfo.videoTrack.stop()
        } else {
          item.activePubVideo = true;
          item.myVideo = true;
          item.pubVideo = true;
          item.agoraCameraInfo.videoTrack.play(this.getEleById(videoEleId))
        }
      }
    },
    // 停止与开始渲染他人音频媒体流
    changeIsPubAudioOther(item) {
      if (this.rtcEngine === 'HST') {
        if (item.currentAudioStatus) {
          if (item.activePubAudio) {
            item.activePubAudio = false;
            item.myAudio = false;
            // item.pubAudio = false
            this.hstRtcEngine.stopReceiveMedia(
              item.userId,
              this.mediaType.AUDIO,
              item.audioMediaId
            )
            setTimeout(() => {
              item.volume = 0;
              this.$set(this.$data, 'currentAudioVolume', 0)
            }, 500)
          } else {
            item.activePubAudio = true;
            item.myAudio = true;
            // item.pubAudio = true
            this.hstRtcEngine.startReceiveMedia(
              item.userId,
              this.mediaType.AUDIO,
              item.audioMediaId
            )
          }
        }
      } else if (this.rtcEngine === 'AGORA') {
        this.agoraChangeOtherAudio(item)
      }
    },
    agoraChangeOtherAudio(item) {
      if (item.currentAudioStatus) {
        if (item.activePubAudio) {
          item.activePubAudio = false;
          item.myAudio = false;
          item.agoraCameraInfo.audioTrack.stop()
          setTimeout(() => {
            item.volume = 0;
            this.$set(this.$data, 'currentAudioVolume', 0)
          }, 500)
        } else {
          item.activePubAudio = true;
          item.myAudio = true;
          item.agoraCameraInfo.audioTrack.play()
        }
      }
    },
    // 鼠标移入
    mouseOverEvent(id) {
      if (this.userId === this.speakerUserId) {
        if (this.speakerUserId === id) {
          this.isSetNickNameShow = true
        }
      } else {
        if (this.userId === id) {
          this.isSetNickNameShow = true
        }
      }
    },
    // 鼠标移出
    mouseLeaveEvent(id) {
      if (this.userId === this.speakerUserId) {
        this.isSetNickNameShow = false
      } else {
        if (this.userId === id) {
          this.isSetNickNameShow = false
        }
      }
    },
    getnickMore(e, res) {
      this.pageHeight = e.pageY + 15
      const nick = 'nick' + res
      const nickID = document.getElementById(nick)
      console.log(2131331)
      if (nickID) {
        this.isnickinfoshow = false
      } else {
        this.isnickinfoshow = true
      }
      this.getnickinId = res
    },
    closeNickNore(e) {
      const self = this;
      if (self.isnickinfoshow === true) {
        const tree = self.$refs.showNickMore
        if (tree) {
          self.isnickinfoshow = false;
        }
      }
    },
    // ----------------------------------------------文件上传--------------------------------
    // 监听会议文件上传
    mettingUploadOnMessage(data) {
      // 会议文件传输
      if (data.type === "PUSH_ROOM_FILE") {
        this.mettingSendMessage(data.body)
      }
      // 会议文件推送状态 文件是否查看
      if (data.type === "PUSH_ROOM_FILE_STATE") {
        console.log(data)
        const index = this.messageList.findIndex(
          (item) => item.pushFileRecordId === data.body.pushFileRecordId
        )
        this.$set(this.messageList, index, {
          ...this.messageList[index],
          isRead: "YES"
        });
      }
    },
    // 会议聊天框 文件推送到聊天监听
    mettingSendMessage(data) {
      // 根据id值查找 原来数组对象有没有该对象
      const result = this.messageList.some(item => item.pushFileRecordId === data.pushFileRecordId)
      if (result) { // 存在 根据id修改对应的值
        const index = this.messageList.findIndex(
          (item) => item.pushFileRecordId === data.pushFileRecordId
        )
        this.$set(this.messageList, index, {
          ...this.messageList[index],
          isBackOut: data.isBackOut
        });
      } else { // 不存在 向后push增加
        this.messageList.push(data)
      }
      // 滚动到底部
      this.$nextTick(() => {
        const messageContent = this.getEleById("messageContent");
        messageContent.scrollTop = messageContent.scrollHeight;
      });
    },

    // 会议文件上传
    openMeetingUploadFile() {
      this.showMettingUploadFile = true
      this.isMettingUploadFile = true
      this.fileSize = ''
      this.progressFlag = false
      this.progressPercent = 0
    },
    // 预览文件
    getMettingPushFile(pushFileRecordId) {
      this.getOnresize()
      this.$ajax({
        url: this.$api.getKkFileViewForPushFile,
        data: {
          pushFileId: pushFileRecordId
        }
      }).then((data) => {
        this.showReadFile = true
        this.showFileName = data.fileName
        this.uploadFileShow = data.kkFileViewUrl
        this.uploadFileData = data
        this.$refs.topHeader.getForceUpdate()
      })
    },
    uploadLink(item) {
      console.log('uploadLink', item)
      // https://tmvafiles.tmva-cn.com 沙盒
      // https://tmvafiles.team-view.cn 测试
      // https://tmvafiles.ar-office.com 正式
      const imgOs = item.fileUrl.replace(process.env.VUE_APP_FILEDOWN_HOST, 'fliedown')
      var x = new XMLHttpRequest();
      x.open("GET", imgOs, true);
      x.responseType = 'blob';
      x.onload = function(e) {
        var url = window.URL.createObjectURL(x.response)
        var a = document.createElement('a');
        a.href = url
        a.download = item.fileName;
        a.click()
      }
      x.send();
    },
    // 删除文件推送
    delMettingFileRecord(pushFileRecordId) {
      this.$ajax({
        url: this.$api.delPushFileRecord,
        data: {
          pushFileId: pushFileRecordId
        }
      }).then((data) => {
        var index = this.messageList.findIndex(
          (item) => item.pushFileRecordId === pushFileRecordId
        )
        this.messageList.splice(index, 1);
      })
    },
    // 取消文件推送信息 撤销
    cancelMettingPushFile(pushFileRecordId) {
      this.$ajax({
        url: this.$api.cancelPushFile,
        data: {
          pushFileId: pushFileRecordId
        }
      }).then((data) => {
        console.log(this.messageList)
      })
    },
    // 移除选择文件
    mettingUploadFileRemove(file, fileList) {
      this.isMettingUploadFile = true
      this.fileSize = ''
    },
    // 选择文件
    mettingHandleChange(file, fileList) {
      if (file.status === "ready") {
        if (fileList.length === 2) {
          fileList.shift()
        }
        this.fileSize = this.$utils.changeFileSize(file.size)
        if (file.size / (1024 * 1024) > 50) {
          // 限制文件大小
          // this.isUploadFile = false
          this.$nextTick(() => {
            const elicon = document.getElementsByClassName('el-icon-document')[0]
            if (this.$utils.fileSuffixType(file.name) === "doc") {
              elicon.classList.replace('el-icon-document', 'uploadIconDocx')
            }
            if (this.$utils.fileSuffixType(file.name) === "xls") {
              elicon.classList.replace('el-icon-document', 'uploadIconXls')
            }
            if (this.$utils.fileSuffixType(file.name) === "pdf") {
              elicon.classList.replace('el-icon-document', 'uploadIconPdf')
            }
            if (this.$utils.fileSuffixType(file.name) === "ppt") {
              elicon.classList.replace('el-icon-document', 'uploadIconPpt')
            }
            const fileOnSend = document.getElementById('fileOnSend')
            fileOnSend.setAttribute("disabled", true)
            fileOnSend.style.backgroundColor = '#ccc'
            this.$utils.showMsg('当前限制文件大小不能大于50M', "warning");
          })
        } else {
          this.$nextTick(() => {
            const elicon = document.getElementsByClassName('el-icon-document')[0]
            if (this.$utils.fileSuffixType(file.name) === "doc") {
              elicon.classList.replace('el-icon-document', 'uploadIconDocx')
            }
            if (this.$utils.fileSuffixType(file.name) === "xls") {
              elicon.classList.replace('el-icon-document', 'uploadIconXls')
            }
            if (this.$utils.fileSuffixType(file.name) === "pdf") {
              elicon.classList.replace('el-icon-document', 'uploadIconPdf')
            }
            if (this.$utils.fileSuffixType(file.name) === "ppt") {
              elicon.classList.replace('el-icon-document', 'uploadIconPpt')
            }
            const fileOnSend = document.getElementById('fileOnSend')
            fileOnSend.removeAttribute("disabled")
            fileOnSend.style.backgroundColor = '#0466B2'
          })
        }
        this.isMettingUploadFile = false
      }
    },
    // 取消
    mettingUploadCancal() {
      this.isMettingUploadFile = true
      this.showMettingUploadFile = false
    },
    // 发送文件上传文件
    mettingUploadFileSubmit() {
      if (this.isMettingUploadFile) {
        this.$utils.showMsg(this.$t("uploadFileTitle"), "warning");
        return
      }
      this.$refs.mettingUploadFile.submit()
      this.$nextTick(() => {
        const fileOnSend = document.getElementById('fileOnSend')
        fileOnSend.setAttribute("disabled", true)
        fileOnSend.style.backgroundColor = '#ccc'
      })
    },
    // 调用上传文件接口
    mettingUploadFileLoad(info) {
      this.progressFlag = true
      this.progressPercent = 0
      const uploadFile = new FormData()
      // 添加参数
      uploadFile.append('file', info.file)
      uploadFile.append('roomUniqueNo', this.groupId)
      this.$noLoading({
        url: this.$api.uploadPushFile,
        data: uploadFile,
        timeout: 1000000,
        headers: { "Content-Type": "multipart/form-data" },
        onUploadProgress: progressEvent => {
          if (progressEvent.lengthComputable) {
            this.progressPercent = parseInt((progressEvent.loaded / progressEvent.total) * 100);
          }
        },
      }).then((data) => {
        this.showMettingUploadFile = false
        const fileOnSend = document.getElementById('fileOnSend')
        fileOnSend.removeAttribute("disabled")
        fileOnSend.style.backgroundColor = '#0466B2'
        this.isMettingRead = false
        this.$utils.showMsg(this.$t("sendUploadFileTitle"), "warning");
      })
    },
    // 获取文件推送记录
    getMettingPushFileRecord() {
      this.$ajax({
        url: this.$api.getPushFileRecord,
      }).then((data) => {
        console.log(data)
      })
    },
    // ----------------------------------------------退出操作----------------------------------------------
    // 停止广播本地媒体流
    stopPublishAllMedia() {
      if (!this.groupId) return;
      const videoEleId =
        this.mainVideoUserId === this.userId ? "mainVideo" : this.userId;
      if (this.isPubShare) {
        this.isPubShare = false;
        this.hstRtcEngine.stopPublishMedia(this.mediaType.SCREEN_SHARE);
        this.hstRtcEngine.unsetMediaRender(
          this.userId,
          this.mediaType.SCREEN_SHARE,
          this.getEleById(videoEleId)
        );
      }
      if (this.isPubAudio) {
        this.isPubAudio = false;
        this.hstRtcEngine.stopPublishMedia(this.mediaType.AUDIO);
      }
      if (this.isPubVideo) {
        this.isPubVideo = false;
        this.hstRtcEngine.stopPublishMedia(
          this.mediaType.VIDEO,
          this.currentCamId
        );
        this.hstRtcEngine.unsetMediaRender(
          this.userId,
          this.mediaType.VIDEO,
          this.getEleById(videoEleId)
        );
      }
    },
    // 停止接收远端媒体流
    stopRecvAllMedia() {
      if (!this.groupId) return;
      this.groupUserList.forEach((item) => {
        if (item.userId !== this.userId) {
          // 停止接收屏幕共享
          if (item.pubShare) {
            this.hstRtcEngine.stopReceiveMedia(
              item.userId,
              this.mediaType.SCREEN_SHARE,
              item.shareMediaId
            );
          }
          // 停止接收音频
          if (item.pubAudio) {
            this.hstRtcEngine.stopReceiveMedia(
              item.userId,
              this.mediaType.AUDIO,
              item.audioMediaId
            );
          }
          // 停止接收视频
          if (item.pubVideo) {
            this.hstRtcEngine.stopReceiveMedia(
              item.userId,
              this.mediaType.VIDEO,
              item.videoMediaId
            );
          }
        }
      });
    },
    // 强制移除
    forceRemoveCommand(command) {
      const listGroup = this.groupUserList.find(
        (item) => item.userId === command
      )
      console.log(listGroup)
      // return
      this.$ajax({
        url: this.$api.removeMeetingUser,
        data: {
          uniqueNo: this.groupId,
          tagUserAccountUid: listGroup.userAccountId,
        },
      }).then((data) => {
        console.log(data)
        // const index = this.groupUserList.findIndex(
        //   (item) => item.userAccountId === listGroup.userAccountId
        // );
        // if (index >= 0) {
        //   this.groupUserList.splice(index, 1);
        // }
      })
    },
    // 房间号状态锁定
    onUnlocked() {
      this.$ajax({
        url: this.$api.roomLock,
        data: {
          uniqueNo: this.groupId,
          isLock: this.isLockText
        },
      }).then((data) => {
        console.log(data)
        this.currentRoomLock = data.islock
      }).catch(() => { })
    },
    // 会议预约弹窗
    openInviteComfirm() {
      this.setDialogVisible = true;
      this.$ajax({
        url: this.$api.userAccountFindInfo,
        data: {
          accountId: this.user.accountId,
        },
      }).then((data) => {
        this.userInfoName = data
      })
      this.$ajax({
        url: this.$api.createMeetingInvitation,
        data: {
          roomNumber: this.groupId
        }
      }).then((data) => {
        this.invitationLink = data.invitationLink
        setTimeout(() => {
          const shareUrl = data.invitationLink
          const qrcode = new QRCode(this.$refs.qrCodeUrl, {
            text: shareUrl,
            width: 150, // 图像宽度
            height: 150, // 图像高度
            colorDark: '#000000',
            colorLight: '#ffffff',
            correctLevel: QRCode.CorrectLevel.H
          });
          qrcode.clear(); // 清除二维码
          qrcode.makeCode(shareUrl); // 生成另一个新的二维码
        }, 100);
        setTimeout(() => {
          const shareUrl = this.invitationLink
          const qrcode = new QRCode(this.$refs.inLoadHtml, {
            width: 360, // 图像宽度
            height: 360, // 图像高度
            colorDark: '#000000',
            colorLight: '#ffffff',
            correctLevel: QRCode.CorrectLevel.H
          });
          qrcode.clear(); // 清除二维码
          qrcode.makeCode(shareUrl); // 生成另一个新的二维码
        }, 100);
        this.meetingID = data.roomId
        this.guidePicData = this.userInfoName.name + ' ' + this.$t('sendRemoteGuide') + '\n' + this.$t('remoteGuideDodeCopy') + data.roomId + ' \n' + this.$t('remoteGuideLinkCopy') + data.invitationLink
      })
    },
    // 导入图片
    loadPic() {
      // this.loadVisiblePic = true
      const canvas = document.createElement("canvas")
      canvas.width = 750
      canvas.height = 1334
      canvas.style.width = 750 + 'px'
      canvas.style.height = 1334 + 'px'
      html2canvas(this.$refs.imageWrapper, { canvas: canvas, scale: 1 }).then(canvas => {
        const link = document.createElement("a")
        link.href = canvas.toDataURL("image/png"); // 下载链接
        link.setAttribute("download", this.$t('appointmentRemoteMeetingPic') + ".png");
        link.style.display = "none"; // a标签隐藏
        document.body.appendChild(link);
        link.click();
      })
    },
    // 复制
    copyContent() {
      var clipboard = new Clipboard('.copyBtn')
      clipboard.on('success', e => {
        //  释放内存
        clipboard.destroy()
        // 清除文本选中状态
        e.clearSelection()
      })
      clipboard.on('error', e => {
        // 不支持复制
        console.log('该浏览器不支持复制')
        // 释放内存
        clipboard.destroy()
      })
    },
    // 关闭预约弹窗
    closeSetDialog() {
      this.setDialogVisible = false
    },
    // 确认预约弹窗
    confirmSetDialog() {
      // this.currentCamId = this.currentCamIdCopy
      // this.currentMicId = this.currentMicIdCopy
      // this.setDialogVisible = false
      // this.$emit('confirmSet', this.currentCamId, this.currentMicId)
    },
    // 退出会议
    async leaveGroup() {
      this.getOnresize()
      this.$utils
        .confirm({
          confirmText: this.$t("exit"),
          content: this.$t("confirmExit"),
        })
        .then(async () => {
          if (this.rtcEngine === 'HST') {
            // 停止广播本地媒体流
            this.stopPublishAllMedia();
            // 停止接收远端视频
            this.stopRecvAllMedia();
            // 退出房间
            this.hstRtcEngine
              .leaveGroup()
              .then(() => {
                // 断掉会议室心跳
                if (this.roomWss) {
                  this.roomHeartAutoClose = true;
                  this.roomWss.close();
                }
                if (this.roomWssRelinkTimer) {
                  clearTimeout(this.roomWssRelinkTimer);
                  this.roomWssRelinkTimer = null;
                }
                this.groupId = "";
                this.groupUserList = [];
                this.isPubShare = false;
                this.isPubVideo = false;
                this.isPubAudio = false;
                this.mainVideoUserId = "";
                this.speakerUserId = "";
                this.mainVideoIsAR = false;
                this.messageList = [];
                this.isShowRepairDetails = false
                this.currentRoomLock = "NO"

                this.getUserAccount() // 更新图表数据
              }).catch(() => {});
          } else if (this.rtcEngine === 'AGORA') {
            await this.agoraUserLeaveGroup(this.userId)
          }
        }).catch(() => {});
    },

    async agoraUserLeaveGroup(userId) {
      const agoraRoomUser = this.groupUserList.filter(item => item.userId === userId)
      console.log('agoraRoomUser', agoraRoomUser)
      if (agoraRoomUser.length > 0 && this.currentCamId) {
        agoraRoomUser[0].agoraCameraInfo.videoTrack.close()
        agoraRoomUser[0].agoraCameraInfo.audioTrack.close()
      }
      if (this.agoraLocalScreenAudioTracks !== null) {
        this.agoraLocalScreenAudioTracks.close()
      }
      if (this.agoraLocalScreenVideoTracks !== null) {
        this.agoraLocalScreenVideoTracks.close()
      }
      await agoraCameraRtcEngine.leave()
      await agoraScreenRtcEngine.leave()
      // 断掉会议室心跳
      if (this.roomWss) {
        this.roomHeartAutoClose = true;
        this.roomWss.close();
      }
      if (this.roomWssRelinkTimer) {
        clearTimeout(this.roomWssRelinkTimer);
        this.roomWssRelinkTimer = null;
      }
      this.groupId = "";
      this.groupUserList = [];
      this.isPubShare = false;
      this.isPubVideo = false;
      this.isPubAudio = false;
      this.mainVideoUserId = "";
      this.speakerUserId = "";
      this.mainVideoIsAR = false;
      this.messageList = [];
      this.isShowRepairDetails = false
      this.currentRoomLock = "NO"
      this.getUserAccount() // 更新图表数据
      // this.getUserInfo(userId, (userInfo) => {
      //   // 显示有人加入房间消息
      //   this.showChatMsg({
      //     userId: userId,
      //     name: userInfo.nickname,
      //     headImg: userInfo.headImg,
      //     msg: `${userInfo.nickname}(${userId}) ${this.$t("leaveRoom")}`,
      //   });
      // });
    },

    // 退出登录
    logout() {
      this.getOnresize()
      this.$utils
        .confirm({
          confirmText: this.$t("exit"),
          content: this.$t("confirmExit"),
        })
        .then(() => {
          this.$ajax({
            url: this.$api.userAccountLogout,
          }).then(() => {
            // 断掉会议室心跳
            if (this.roomWss) {
              this.roomWss.close();
            }
            if (this.roomWssRelinkTimer) {
              clearTimeout(this.roomWssRelinkTimer);
              this.roomWssRelinkTimer = null;
            }
            this.destroy();
          });
        })
        .catch((e) => {
          console.log(e)
        });
    },
    // 销毁事件以及方法
    destroy() {
      try {
        this.hstRtcEngine
          .logout()
          .then(() => {
            // 停止广播本地媒体流
            this.stopPublishAllMedia();
            // 停止接收远端视频
            this.stopRecvAllMedia();
            // 取消所有事件订阅和销毁引擎
            this.hstRtcEngine.unsubEvent();
            this.hstRtcEngine.destroy();
          })
          .catch(() => {
            // 停止广播本地媒体流
            this.stopPublishAllMedia();
            // 停止接收远端视频
            this.stopRecvAllMedia();
            // 取消所有事件订阅和销毁引擎
            this.hstRtcEngine.unsubEvent();
            this.hstRtcEngine.destroy();
          });
      } catch (e) { }
      this.$utils.deleteSessionStorage("user");
      this.$utils.deleteLocalStorage('referUrl')
      this.$utils.deleteLocalStorage('AutoLogin')
      location.href = "/login";
    },
  },
};
