// 中文
export default {
  // 基础
  cancel: "Cancel",
  confirm: "Yes",
  hangUp: "Hang up",
  know: "Yes",
  apply: "Apply",
  state: "Status code",
  loaing: "Loading...",
  more: "More",
  // 登录页
  welcomeUse: "Welcome",
  aRAfterSale: "Expert online guidance system",
  loginWelcome: "Welcome to the AR remote guidance system",
  account: "Please enter a user name",
  savePassWord: "Save the password",
  autoLogin: "Auto login",
  password: "Password",
  login: "Login",
  accountType: "The account type is incorrect",
  aboutUs: "About us",
  contactUs: "Contact us",
  technicalSupport: "Technical support",
  copyright: "© 2017-2022 TMVA (Shanghai) Network Technology Co., Ltd copyright",
  languageSwitch: "Switch languages",
  accountNoEmpty: "The account number cannot be empty",
  passwordNoEmpty: "The password cannot be empty",
  accountNoCorrect: "The account number is incorrect",
  passwordNoCorrect: "The password is incorrect",
  accountOrPasswordError: "Incorrect account number or password",
  networkError: "Network connectivity is interrupted",
  networkCatch: "Server exception",
  // 工具
  tool: "Tool",
  searchServiceOrder: "Service ticket query",
  orderId: "Ticket ID",
  orderIdPlaceholder: "Please enter the ticket ID",
  noStart: "Not started",
  noEnd: "Not over",
  startTime: "Service start time",
  endTime: "End time of service",
  useTime: "Service time",
  serviceStatus: "Service status",
  arId: "Service AR ID",
  arName: "Service AR nickname",
  interPhoneId: "Service intercom ID",
  noData: "No data yet",
  startAttention: "Start following",
  startAttentionTips: "Tickets focus on success",
  cancelAttention: "Unfollow",
  cancelAttentionTips: "The ticket has been unfollowed",
  // 设置
  set: "Set up",
  cameraOrAudioSet: "Camera and audio settings",
  camera: "Cam",
  cameraPlaceholder: "Please select a camera",
  revolveCamera: "The screen rotates",
  openMirror: "Turn on mirroring",
  microphone: "Mic",
  microphonePlaceholder: "Please select a microphone",
  closeMicrophone: "Turn off the microphone",
  testMicrophone: "Record",
  abnormalMicrophone: "Microphone exception",
  openMicrophone: "Unrecord",
  loudspeaker: "Speaker",
  loudspeakerPlaceholder: "Please select a speaker",
  updatedHeadImg: "Avatar update successful",
  downHeadImg: "Avatar",
  setHeadImg: "Avatar settings",
  selectHeadImg: "Select",
  selectHeadImgTxt: "Description:Support PNG,JPG,and JPGE",
  selectOnePIc: "Select an image",
  selectOnePicFalse: "Please select an image",
  selectOnePicSize: "File cannot exceed 1M",
  downNickName: "Nickname",
  setNickName: "Nickname settings",
  changNickName: "The nickname has not been modified",
  setNickNamePattern: "Please enter a nickname, it cannot be empty, not more than 10 characters.",
  setAudioDevices: "Select the device",
  setAudioCall: "Audio/video",
  setAudioLayout: "Set the layout",
  setVolume: "Volume",
  setMicroPhoneVolume: "Please click the record test and speak into the record",
  setLoudSpeakerVolume: "Please click play test sound to see if you can hear the sound",
  noSupport: "Not supported",
  testMicroPhone: "Record",
  testLoudSpeaker: "Play",
  // APP下载
  loginAppDownload: "App Download",
  appDownload: "App",
  SystemIntroduction: "Specification",
  Instructions: "Manual",
  // 帮助
  help: "Help",
  serviceSupport: "Help",
  companyName: "TMVA (Shanghai) Network Technology Co., Ltd",
  phone: "Tel: 021-57618272",
  email: "Email: Support@tmva-sh.com",
  website: "Web: www.tmva-sh.com",
  address: "Add: Room 606-607,Building 6,No.239 Xinzhuan Road,Songjiang District,Shanghai",
  versionCode: 'V3.1',
  // 退出登录
  logout: "Logout",
  // 联系人
  searchContact: "Search for contact",
  searchUserResult: "Find a contact",
  // 右侧工具
  attendee: "Attendees",
  editNickName: "Modify the nickname",
  unlocked: "Unlocked",
  locked: "Locked",
  inviteComfirm: "Invite",
  SetMasterControl: "Set as host",
  forceRemove: "Remove",
  forceRemoveWarn: "You have been asked out of the current remote guide",
  speaker: "Host",
  openCamera: "Open camera",
  closeCamera: "Close camera",
  openAudio: "Audio On",
  closeAudio: "Audio Off",
  applySpeaker: "Apply host",
  giveUpSpeaker: "Quit host",
  applySpeakerApply: "Request host authority to call out",
  applySpeakerAccept: "Request host authority to call in",
  addRoomApply: "Request to join the remote guidance call",
  addRoomAccept: "Request to join the remote guidance call-in",
  whiteBoard: "Whiteboard",
  staticMark: "Static tags",
  dynamicMark: "Dynamic guidance",
  speakerAuthor: "Delegated",
  openShareDesktop: "Desktop sharing",
  closeShareDesktop: "Stop sharing",
  meetingUploadFile: "File upload",
  meetingRepairDetails: "Details of the report",
  outMeeting: "Quit",
  chat: "Chat",
  chatPlaceholder: "Please enter a chat",
  openNoCamera: 'Camera not turned on',
  // AR操作
  rearCamera: "Rear Cam",
  ARCamera: "AR camera",
  thermalImg: "Thermal imaging",
  photograph: "Photo",
  openLight: "Flash On",
  closeLight: "Flash Off",
  ARRecord: "Start record",
  ARRClose: "Recording",
  openHotImg: "Open the heat map",
  closeHotImg: "Close the heat map",
  areaMode: "Regional mode",
  centerMode: "Central mode",
  openMultipoint: "Turn on multi-point temperature measurement",
  closeMultipoint: "Turn off multi-point temperature measurement",
  color1: "High sensitivity",
  color2: "High contrast",
  color3: "Highest temperature zone",
  zoomLargen: "Magnify",
  zoomMinish: "Shrink",
  // 弹框或者轻提示
  userNotOnline: "The user is not online, please try again later",
  msg: "Prompt",
  close: "Shut down",
  hasInMeeting: "The user is already in the current remote coach",
  noAcceptInvite: "They are busy and can't accept your invitation at the moment",
  networkDisconnection: "Your network has been disconnected, please log out and log back in",
  time: "Time",
  temperature: "Temperature",
  getSpeakerPermission: "Is the other party authorized to apply for the host?",
  agree: "Agree",
  disagree: "Refuse",
  toSpeaker: "Become the host",
  giveUpedSpeaker: "Abandon host",
  currentNoSpeaker: "Currently no host",
  speakerLogout: "The host has exited",
  speakerleaveRoom: "Left",
  disagreeSpeakerPermission: "The other party rejected your application for host",
  timeoutSpeakerPermission: "The other party did not respond",
  userinmeeting: "The other party is busy, decide to join?",
  applyToJoin: "Apply to join",
  userinmeetingTips: "Waiting for the other party's consent, please wait...",
  want_to_join_the_meeting: "Apply to join the current remote coaching, whether you agree or not",
  no_meeting: "The other party has refused",
  closeShareDesk: "Turn off shared desktops first",
  confirmExit: "Are you sure you want to exit",
  pleaseEnterContent: "Please enter the content",
  confirmCloseShareDesk: "Are you sure you want to turn off desktop sharing",
  shareDeskOpened: "Desktop sharing is turned on",
  giveUpSpeakerTips: "Are you sure you want to give up host?",
  applySpeakerSendTips: "The host application has been sent, please be patient",
  abnormalLogin: "Login is abnormal or the account is already logged in, please log out and log back in",
  hasInvite: "Does the other party agree to apply for guidance",
  accept: "Accept",
  reject: "Refuse",
  agreeInvite: "Agreed to your invitation",
  rejectInvite: "The other party has refused",
  pleaseEnterNickname: "Please enter a nickname",
  editNickname: "Modify the nickname",
  nicknameNoNull: "Nicknames cannot be empty and cannot be longer than 10",
  noNull: "Cannot be empty",
  editNicknameSuccess: "The nickname was modified successfully",
  joinRoom: "Joined",
  leaveRoom: "Left",
  exit: "Quit",
  exitFull: "Quit",
  waitingOtherJoin: "Waiting to answer, please wait",
  waitingOtherHandle: "Waiting for the other party to process, please wait",
  hasCanced: "The other party has canceled",
  delete: "Delete",
  deleteTips: "Are you sure you want to delete it",
  deleteSuccess: "Delete successful",
  recordFile: "Cloud recording",
  recordFileBox: "Cloud recording file",
  record: "Recording",
  enter: "Join",
  ignore: "Ignore",
  theme: 'Theme',
  timer: 'Time',
  enterRoom: 'Your meeting has reached its start time?',
  // 白板
  text: "Text",
  brush: "Brush",
  straight: "Line",
  rectangle: "Rectangular",
  round: "Circle",
  triangle: "Triangle",
  square: "Square",
  eraser: "Eraser",
  empty: "Empty",
  revocation: "Revocation",
  import: "Import",
  download: "Save",
  hasLogin: "The account is already logged in",
  // AR操作提示
  AR_F_WIN_OpenMic_Success: "Turn on the microphone successfully",
  AR_F_WIN_OpenMic_Error: "Failed to turn on the microphone",
  AR_F_WIN_CloseMic_Success: "Turn off the microphone successfully",
  AR_F_WIN_CloseMic_Error: "Failed to turn off the microphone",
  AR_F_WIN_Rear_Camera_Success: "Turn on the rear camera successfully",
  AR_F_WIN_Rear_Camera_Error: "Failed to turn on the rear camera",
  AR_F_WIN_AR_Camera_Success: "Open the AR camera successfully",
  AR_F_WIN_AR_Camera_Error: "Failed to open AR camera",
  AR_F_WIN_Thermography_Success: "Turn on thermal imaging successfully",
  AR_F_WIN_Thermography_Error: "Thermal imaging failed",
  AR_F_WIN_OpenFlash_lamp_Success: "Turn on the flash off light successfully",
  AR_F_WIN_OpenFlash_lamp_Error: "Failed to turn on flash off light",
  AR_F_WIN_CloseFlash_lamp_Success: "Turn off the flash off light successfully",
  AR_F_WIN_CloseFlash_lamp_Error: "Failed to turn off the flash off light",
  AR_F_WIN_Photograph_Success: "The photo was taken successfully",
  AR_F_WIN_Photograph_Error: "Failed to take a picture",
  AR_F_WIN_Recording_Success: "Open Recording succeeded",
  AR_F_WIN_Recording_Error: "Cloud recording fails to turn on, and the remaining time of cloud recording is insufficient",
  AR_F_WIN_OpenThermograph_Success: "Open heat map successfully",
  AR_F_WIN_OpenThermograph_Error: "Failed to open heat map",
  AR_F_WIN_CloseThermograph_Success: "Close the heat map successfully",
  AR_F_WIN_CloseThermograph_Error: "Closing the heat map failed",
  AR_F_WIN_Regional_model_Success: "Turn on zone mode successfully",
  AR_F_WIN_Regional_model_Error: "Failed to open zone mode",
  AR_F_WIN_Central_mode_Success: "Open Center Mode successfully",
  AR_F_WIN_Central_mode_Error: "Failed to open central mode",
  AR_F_WIN_OpenMultipoint_temperature_measurement_Success: "Turn on multi-point temperature measurement successfully",
  AR_F_WIN_OpenMultipoint_temperature_measurement_Error: "Opening multi-point temperature measurement failed",
  AR_F_WIN_CloseMultipoint_temperature_measurement_Success: "Turn off multi-point temperature measurement successfully",
  AR_F_WIN_CloseMultipoint_temperature_measurement_Error: "Turning off multipoint temperature measurement failed",
  AR_F_WIN_Palette_mode_1_Success: "Turn on grading one successfully",
  AR_F_WIN_Palette_mode_1_Error: "One failed to open color grading",
  AR_F_WIN_Palette_mode_2_Success: "Open Color Grading Two successfully",
  AR_F_WIN_Palette_mode_2_Error: "Failed to open Color Grading Two",
  AR_F_WIN_Palette_mode_3_Success: "Turn on Color Grading Three successfully",
  AR_F_WIN_Palette_mode_3_Error: "Turn on Color Grading Three failed",
  Camera_Zoom_Largen_Success: "Zoom in successfully",
  Camera_Zoom_Largen_Error: "Magnification failed",
  Camera_Zoom_Minish_Success: "Scale down successfully",
  Camera_Zoom_Minish_Error: "Scale-in failed",
  AR_Camera_Main_Success: "Open the main camera successfully",
  AR_Camera_Main_Error: "Failed to open the main camera",
  AR_Camera_Deputy_Success: "Open the secondary camera successfully",
  AR_Camera_Deputy_Error: "Failed to open the secondary camera",
  Insta_camera_left_Success: "The panoramic camera scrolls successfully to the left",
  Insta_camera_left_Error: "The panoramic camera failed to scroll to the left",
  Insta_Camera_Right_Success: "The panoramic camera scrolls successfully to the right",
  Insta_Camera_Right_Error: "The panoramic camera failed to scroll to the right",
  Insta_Camera_Up_Success: "The panoramic camera scrolls up successfully",
  Insta_Camera_Up_Error: "The panoramic camera failed to scroll up",
  Insta_Camera_Down_Success: "The panoramic camera scrolled down successfully",
  Insta_Camera_Down_Error: "The panoramic camera failed to scroll down",
  // 设备信息
  equipmentInfo: "Device info",
  partsInfo: "Part information",
  faultInfo: "Fault information",
  equipmentName: "Equipment",
  equipmentModel: "Device mode",
  saleCreateTime: "Start time",
  saleWarrantyTime: "End time",
  partsName: "Part name",
  partsModel: "Part mode",
  faultName: "Fault name",
  faultNumber: "Fault code",
  equipmentDetail: "Description",
  partsDetail: "Description",
  faultDetail: "Description",
  equipmentImage: "Picture",
  partsImage: "Picture",
  faultImage: "Picture",
  hasReceived: "The other party has received the message",
  // 全局错误码---------------------------
  // 请求响应状态-state
  SUCCESS: "Succeed",
  ERROR: "Mistake",
  SYSTEM_ERROR: "System error",
  NO_PERMISSION: "No permissions",
  NO_LOGIN: "No login",
  KICK_OUT: "Kicked out",
  // 请求响应错误码-msg-账户相关
  ACCOUNT_000001: "Wrong username or password",
  ACCOUNT_000002: "Account exception",
  ACCOUNT_000003: "Platform user accounts cannot be added and initiated to add contacts",
  ACCOUNT_000004: "Accounts cannot be renewed",
  ACCOUNT_000005: "The account number is logged in",
  ACCOUNT_000006: "The same enterprise user account cannot be added",
  ACCOUNT_000007: "Already friends can't be added",
  ACCOUNT_000008: "An application has been initiated",
  ACCOUNT_000009: "The application has been processed (agreed or rejected)",
  ACCOUNT_000010: "The device unique code is not legitimate",
  ACCOUNT_000011: "Not an external contact of the account",
  ACCOUNT_000012: "The password is not in the correct format",
  ACCOUNT_000013: "The account does not have login permissions on the device",
  ACCOUNT_000014: "The current account cannot add external contacts",
  ACCOUNT_000015: "The other person's account cannot add external contacts",
  ACCOUNT_000016: "The login already exists",
  ACCOUNT_000017: "There are already super administrators under the enterprise",
  // 请求响应错误码-msg-接口请求
  API_000001: "Not logged in",
  API_000002: "Unauthorized",
  API_000003: "Unknown interface",
  API_000004: "Illegal parameter",
  API_000005: "Unsupported request methods",
  API_000006: "Http body read and write failed",
  // 请求响应错误码-msg-数据访问
  DATA_000001: "Data access exception",
  DATA_000002: "The data does not exist",
  DATA_000003: "Data already exists",
  // 请求响应错误码-msg-文件操作
  FILE_000001: "File size exceeded",
  FILE_000002: "Failed to create folder",
  FILE_000003: "Upload failed",
  FILE_000004: "Failed to get file upload signature",
  FILE_000005: "The file upload callback failed to verify the signature",
  FILE_000006: "Failed to parse parameters for file upload callback",
  FILE_FAILURE: "The file is invalid or expired",
  // 请求响应错误码-msg-其它错误
  OTHER_000001: "Unknown exception",
  OTHER_000002: "The operation failed",
  // 请求响应错误码-msg-设备相关
  EQUIPMENT_000001: "Data not shared by the enterprise is not editable",
  EQUIPMENT_000002: "There are devices under the device grouping that are not allowed to be deleted",
  EQUIPMENT_000003: "Device/part already sold is not allowed to be deleted",
  // 请求响应错误码-msg-销售客户相关
  CUSTOMER_000001: "Remove a linked customer prompt",
  // 请求响应错误码-msg-地址池信息
  POOL_000001: "Assign address pool information The number is not enough prompt",
  POOL_000002: "The pool subtype does not exist",
  POOL_000003: "The number of device pools is not enough",
  POOL_000004: "The number of component pools is not enough",
  POOL_000005: "The enterprise does not have sales resources/qualifications",
  POOL_000006: "Enterprise AR digital display information resources are insufficient",
  POOL_000007: "The device/part/fault QR code does not exist",
  // 请求响应错误码-msg-EXCEL相关
  EXCEL_000001: "Failed to read excel file",
  EXCEL_000002: "Failed to read excel data",
  // 请求响应错误码-msg-部门相关
  USER_ACCOUNT_GROUP_000001: "Sub-departments under a department cannot be deleted",
  USER_ACCOUNT_GROUP_000002: "Accounts contained in a group cannot be deleted",
  // 请求响应错误码-msg-授权资源
  SERVICE_RESOURCE_000001: "The current account has insufficient call time margin and the service has been stopped",
  SERVICE_RESOURCE_000002: "Unsupported resource billing mode",
  SERVICE_RESOURCE_000003: "The code pool type corresponding to the authorization type was not found",
  SERVICE_RESOURCE_000004: "The other account has stopped service",
  // 请求响应错误码-msg-好视通接口错误
  HST_ERROR_000001: "Failed to call up the Good Sight interface",
  HST_ERROR_000002: "Remote guidance is being recorded",
  HST_ERROR_000003: "Room or remote guidance records do not exist",
  HST_ERROR_000004: "Cloud recording storage space is insufficient",
  // 请求响应错误码-msg-远程指导用户
  ROOM_USER_ACCOUNT_000001: "Non-remote guidance room users cannot request a speaker",
  ROOM_USER_ACCOUNT_000002: "Already the main speaker, can not apply",
  ROOM_USER_ACCOUNT_000003: "The current user is not the keynote speaker",
  ROOM_USER_ACCOUNT_000004: "The application number is incorrect",
  ROOM_USER_ACCOUNT_000005: "Please wait for the result of the last application",
  ROOM_USER_ACCOUNT_000006: "Please wait for the result of the last invitation",
  ROOM_USER_ACCOUNT_000007: "The invitation number is incorrect",
  ROOM_USER_ACCOUNT_000008: "The account number is not online",
  ROOM_USER_ACCOUNT_000009: "The current speaker is not a user within the remote guide",
  ROOM_USER_ACCOUNT_0000010: "The user is not a user within remote guidance",
  ROOM_USER_ACCOUNT_000011: "The invitee has been invited by someone else",
  // 请求响应错误码-msg-远程指导室
  ROOM_000001: "Remote guidance room does not exist",
  ROOM_000002: "The remote guidance room has been dissolved",
  ROOM_000003: "The remote guidance room already exists",
  // 联系人类型
  platformContacts: "Platform contacts",
  companyContacts: "Enterprise contacts",
  externalContacts: "External contacts",
  // 请求响应错误码-msg-数据权限
  DATA_PMS_000001: "Non-corporate QR code",
  // 相机描述
  mainCamera: 'Main cam',
  secondaryCamera: 'Deputy cam',
  AR_CameraText0: "No Cam",
  AR_CameraText1: "Rear Cam",
  AR_CameraText2: "Front Cam",
  AR_CameraText3: "MUVC Cam",
  AR_CameraText4: "DUVC Cam",
  AR_CameraText5: "Thermal imaging",
  AR_CameraText6: "360 Cam",
  // 消息相关
  msgAll: "All",
  msgCall: "Call",
  msgAppointment: "Appointment",
  msgApply: "Application",
  msgSystem: "System",
  msgUpload: "Transfer",
  msgCallIn: "Call in",
  msgCallOut: "Call out",
  msgAgree: "Agree",
  msgRefuse: "Refuse",
  APPLIED: "The application was sent successfully",
  REJECTED: "Rejected",
  PASS: "Passed",
  TIMEOUTA: "No response",
  AGREEA: "Answered",
  REFUSEA: "Refuse to answer",
  CANCELA: "No response",
  TIMEOUTB: "No response",
  AGREEB: "Answered",
  REFUSEB: "Hang up",
  CANCELB: "No response",
  MESSAGE_RECEIVED: "No response",
  LOCKED: "The current remote coach is locked and cannot be joined",
  showDays: "Only 7-day records",
  // 预约远程指导
  addRemoteGuides: "Appointment guidance",
  addAppointment: "Appointment",
  remoteGuide: "Guidance Theme",
  lookGuide: "Show details",
  cancelGuide: "Cancel",
  sendRemoteGuide: "Invite you to attend guidance",
  enterGuideAccount: 'Please enter a subject',
  GuideAccountStartDay: 'Please select a start date',
  GuideAccountStartHours: 'Please select a start time',
  GuideAccountendDay: 'Please select an end date',
  GuideAccountendHours: 'Please select an end time',
  addGuideSend: "Add appointment instructions have been sent",
  reserved: 'Appointment list',
  AppointmentMeeting: "Join guidance",
  enterAppointmentMeetingID: "Please enter the remote guidance number or link",
  enterCorrectAppointmentMeetingID: "Please enter the correct remote guidance number or link",
  remoteGuideCopy: "Guidance Theme：",
  remoteGuideDodeCopy: "Guidance ID：",
  remoteGuideTimeCopy: "Time：",
  remoteGuideGMTCopy: "GMT+8:00",
  remoteGuideLinkCopy: "Click the link to join：",
  remoteGuideAccept: "Remote guidance requests inbound",
  remoteGuideApply: "Remote guidance request callout",
  startTimeGuide: "Start time",
  endTimeGuide: "End time",
  enterTimeGuide: "Entry time",
  quitTimeGuide: "Exit time",
  gudideHour: "Hour",
  guideBegin: "Start",
  BeijingTime: "Beijing time",
  timeCannot: "Appointments cannot be made for more than 24 hours",
  timeCannotEnd: "The end time cannot be less than the start time",
  timeCannotBegin: "The end time cannot be equal to the start time",
  SupportAPP: 'Use the AR remote guidance APP scan to attend',
  AppDownloadedPc: 'Tip: APP can be downloaded from PC client',
  reserveGuides: "'s remote guidance",
  // 外部联系人
  addOutContacts: "Add an external contact",
  enterAccount: "Please enter a user account",
  sendApply: "Send the application",
  addGroup: "Establish",
  enterGroupName: "Please enter a group name",
  moveGroup: "Move",
  selectGroup: "Select",
  outContactManage: "Controller",
  contactNickName: "Nickname",
  contactNickAccount: "Account",
  contactNickNote: "Remark",
  contactNickGroup: "Grouping",
  deleteGroup: "Delete",
  reName: "Rename",
  deleteContact: "Delete",
  delContactConfirm: "Are you sure you want to delete the contact",
  groupMoveSuccess: "The group move was successful",
  delGroupConfirm: "Decide if you want to delete the grouping",
  groupAddSuccess: "The grouping was added successfully",
  nameEditSuccess: "Name modification successful",
  addContactSend: "The application was sent successfully",
  notFundContact: "The user was not found",
  addContacts: "Add contact",
  // 云录制状态
  recordStatus1: "Initialize",
  recordStatus2: "Recording",
  recordStatus3: "Queue up for encoding",
  recordStatus4: "Encoding",
  recordStatus5: "Uploading",
  recordStatus6: "Finish",
  recordStatus7: "No status was obtained",
  recordStatus8: "lapse",
  recordStatus9: "Recording failed",
  "recordStatus-1": "Task exception",
  noLogin: 'Login is invalid, please log in again',
  zh: 'Chinese',
  en: 'English',
  ja: 'Japanese',
  transparentBoard: 'Space tags',
  importWhiteBoard: 'Import',
  downloadImage: 'Download',
  // 远程指导
  appointmentRemoteMeeting: "Invitation of the remote guidance",
  appointmentRemoteMeetingPic: "Details",
  guideCodingTheme: 'Theme:',
  guideCodingTime: 'Time:',
  toStarted: 'Upcoming',
  guideCoding: 'ID',
  guideCodingUrl: 'Link',
  mettingLoad: 'Save',
  meetingCopy: 'Copy ID And Link',
  mettingLoadSave: 'Save the card',
  mettingLoadQuit: 'Cancel',
  createMetting: "OK",
  INVITATION_MEETING_NOT_START_TIME: "Remote guidance did not start",
  INVITATION_MEETING_HAVE_IN_TIME: "Other appointments already exist for the time period you set, please reset them",
  INVITATION_MEETING_IS_OVER: "The remote guidance is over",
  INVITATION_MEETING_INVALID: "The invitation expires",
  INVITATION_MEETING_OVERMAN: "The number of people is overshoot",
  INVITATION_MEETING_NO_COMPANY: "Not a business",
  INVITATION_MEETING_IS_LOCAK: "The room is locked",
  PUSH_FILE_IS_READ: "The file was read",
  PUSH_FILE_IS_BACK_OUT: "The file has been withdrawn",
  PARAMETERS_EXCEPTION: "Parameter exception",
  ANDROID_DEVICE_EXCEPTION: "Android device exception Unauthorized",
  SYSTEM_ENVIRONMENT_DIF: "The system environment is inconsistent",
  USER_MOT_IN_ROOM: "The user is not in the remote coaching",

  // 文件上传
  uploadFileTitle: "Please select the file to be transferred",
  sendUploadFileTitle: "The file was sent successfully",
  uoloadFileList: "A list of attachments",
  uploadFileSelect: "Select file",
  uploadcancel: "Cancel",
  uploadconfirm: "Send",
  uploadPreview: "Preview",
  uploadBackOut: "Withdraw",
  uploadClose: "Shut down",
  uploadTitle: "File push",
  uploadTitleText: "A new file is received",
  uploadIsRead: "Do you want to preview it now?",
  showReadFile: "File browsing",
  fileSend: "Emit",
  fileAccept: "Reception",
  fileRecall: "Withdrawn",
  otherSideFileRecall: "The other party has withdrawn",
  otherSideFileAccept: "The other party has received",
  fileReread: "The file was read",
  fileNodata: "No data yet",
  startInvite: "Launch",
  startInvitePer: "Sponsor",
  toBeInvited: "Invited",
  my: "I",
  fileName: "File",
  keepRepair: 'Use the AR Remote Support APP scan to attend',
  WechatScan: "Support wechat scan to attend",

  // 账号统计数据导出
  accountRemoteSupportTimes: "Remote support duration (Minutes)",
  accountRemoteSupportNum: "Number of remote supports (Mimes)",
  accountOnLineTimes: "Online time (Minutes)",
  accountRepairOrderNum: "Acceptance of repair tickets (number of units)",
  accountProcessRepairOrderNum: "Repair requisitions processed (number of)",
  accountSharedFiles: "Shared files (Pcs)",
  yearRemoteSupportTimes: "Annual remote support hours",
  yearRemoteSupportNums: "Number of remote supports per year",
  yearRepairOrderNum: "Annual processing of work orders",
  tableMinute: "(Minutes)",
  tableNumber: "(Times)",
  tableJanuary: 'Jan',
  tableFebruary: 'Feb',
  tableMarch: 'Mar',
  tableApril: 'Apr',
  tableMay: 'May',
  tableJune: 'Jun',
  tableJuly: 'Jul',
  tableAugust: 'Aug',
  tableSeptember: 'Sep',
  tableOctober: 'Oct',
  tableNovember: 'Nov',
  tableDecember: 'Dec',
  currentMonth: "This month",
  currentYear: 'This year',
  defaultDrouping: 'Default grouping',
  sendARRecord: 'Send AR record',
  closeARRecord: 'close AR record',
  Mon: "Mon",
  Tues: "Tue",
  Wed: "Wed",
  Thur: "Thu",
  Fri: "Fri",
  Sat: "Sat",
  Sun: "Sun",
  news: "News",
  Shrink: "Shrink",
  Unfold: "Unfold",
  openWatermark: "Open watermark",
  closeWatermark: "Close watermark",
  fileAppName: 'Flie：',
  hitdownload: 'Download'

};
