/**
 * 公用头路径
 * */
const protocol = {
  'http:': 'ws://',
  'https:': 'wss://'
}
export const baseURL = process.env.NODE_ENV === 'development' ? process.env.VUE_APP_BASEURL : document.location.origin + '/tmva';
export const oemBaseURL = "/oem/";
export const manageHost = process.env.NODE_ENV === 'development' ? process.env.VUE_APP_MANAGEHOST : document.location.origin;
export const socketHost = process.env.NODE_ENV === 'development' ? process.env.VUE_APP_SOCKETHOST : protocol[document.location.protocol] + document.location.host + '/tmva/'
export const appId = "faabee69e8abc53686474dbe99448dae";
