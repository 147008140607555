import axios from "axios";
import { baseURL } from "./../config/path";
import { Loading, MessageBox } from "element-ui";
import i18n from "@/lang";
import { getSessionStorage, deleteSessionStorage } from "@/helpers/utils.js";
import store from "@/store/index.js";
import md5 from 'js-md5'

/**
 * 合并请求头
 * @param {object} headers //请求头
 * */
export const getHeader = headers => {
  const defaultHeader = {};
  const token = getSessionStorage("token");
  const version = getSessionStorage("version");
  if (token) defaultHeader.subject_token = token;
  if (version) defaultHeader.version = version;
  defaultHeader.api_version = 'V3';

  const dateDiff = new Date().getTime()
  const signStr = `sign=&timestamp=${dateDiff}&tag=tmva&deviceCode=${dateDiff}&device-type=${getSessionStorage("DeviceType")}&software-version=${getSessionStorage("SoftwareVersion")}&oem-name=TMVA`
  const sign = md5(signStr)
  console.log('signStr', signStr, 'sign', sign)
  const deviceCode = dateDiff
  const timestamp = dateDiff
  defaultHeader.deviceCode = deviceCode
  defaultHeader.sign = sign
  defaultHeader.timestamp = timestamp
  defaultHeader['meeting-sdk'] = process.env.VUE_APP_MEETING_SDK

  return Object.assign(defaultHeader, headers);
};

/**
 * 处理请求错误信息
 * @param {string} msg 错误信息
 * @param {number} status 状态码
 * */
export const showErrorMsg = msg => {
  MessageBox.alert(msg, i18n.t("msg"), {
    dangerouslyUseHTMLString: true,
    showClose: true,
    confirmButtonText: i18n.t("know"),
    customClass: "errorMsgCss",
    closeOnClickModal: false,
    // center: true
  });
};

/**
 * 公共网络请求
 * @param {string} requestParams.url 请求路径
 * @param {string} requestParams.method 请求类型、默认post
 * @param {object} requestParams.header 请求头、默认{}
 * @param {object} requestParams.data 请求参数post、默认{}
 * @param {object} requestParams.params 请求参数get、默认{}
 * @param {number} requestParams.timeout 请求超时时间、默认30000毫秒
 * @param {string} requestParams.responseType 响应数据类型
 * @param {boolean} requestParams.defineError 错误是否自己处理
 * */
export const ajax = requestParams => {
  return new Promise((resolve, reject) => {
    const {
      baseUrl,
      url,
      data = {},
      params = {},
      method = "POST",
      header = {
        'software-version': getSessionStorage("SoftwareVersion"),
        'oem-name': getSessionStorage("OemName"),
        'device-type': getSessionStorage("DeviceType"),
      },
      timeout = 30000,
      responseType = "json",
      defineError = false,
      showLoading = true
    } = requestParams;
    let isLoading = null;
    if (showLoading) {
      isLoading = Loading.service({
        text: i18n.t("loaing"),
        spinner: "el-icon-loading",
        background: "rgba(255, 255, 255, 0.2)"
      });
    }
    axios({
      baseURL: baseUrl || baseURL,
      url,
      data,
      params,
      method,
      timeout,
      responseType,
      headers: getHeader(header)
    })
      .then(result => {
        isLoading && isLoading.close();
        const { data } = result;
        if (defineError) {
          resolve(data);
          return;
        }
        const { state, msg } = data;
        // 未登录和被踢出
        if (state === "NO_LOGIN" || state === "KICK_OUT") {
          MessageBox.alert(i18n.t("noLogin"), i18n.t("msg"), {
            dangerouslyUseHTMLString: true,
            showClose: false,
            confirmButtonText: i18n.t("confirm"),
            center: true
          }).then(() => {
            // 清除本地缓存数据
            deleteSessionStorage("token");
            store.commit("updateUser", null);
            location.href = "/login";
          });
          return;
        }
        if (state !== "SUCCESS") {
          showErrorMsg(i18n.t(msg));
          reject(data);
          return;
        }
        resolve(data.data);
      })
      .catch(error => {
        isLoading && isLoading.close();
        showErrorMsg(i18n.t("networkError"));
        reject(error);
      });
  });
};
export const noCall = requestParams => {
  return new Promise((resolve, reject) => {
    const {
      baseUrl,
      url,
      data = {},
      params = {},
      method = "POST",
      header = {
        'software-version': getSessionStorage("SoftwareVersion"),
        'oem-name': getSessionStorage("OemName"),
        'device-type': getSessionStorage("DeviceType"),
      },
      timeout = 30000,
      responseType = "json",
      defineError = false,
      showLoading = true
    } = requestParams;
    let isLoading = null;
    if (showLoading) {
      isLoading = Loading.service({
        text: i18n.t("loaing"),
        spinner: "el-icon-loading",
        background: "rgba(255, 255, 255, 0.2)"
      });
    }
    axios({
      baseURL: baseUrl || baseURL,
      url,
      data,
      params,
      method,
      timeout,
      responseType,
      headers: getHeader(header)
    })
      .then(result => {
        isLoading && isLoading.close();
        const { data } = result;
        if (defineError) {
          resolve(data);
          return;
        }
        const { state } = data;
        // 未登录和被踢出
        if (state === "NO_LOGIN" || state === "KICK_OUT") {
          MessageBox.alert(i18n.t("noLogin"), i18n.t("msg"), {
            dangerouslyUseHTMLString: true,
            showClose: false,
            confirmButtonText: i18n.t("confirm"),
            center: true
          }).then(() => {
            // 清除本地缓存数据
            deleteSessionStorage("token");
            store.commit("updateUser", null);
            location.href = "/login";
          });
          return;
        }
        if (state !== "SUCCESS") {
          // showErrorMsg(i18n.t(msg));
          reject(data);
          return;
        }
        resolve(data.data);
      })
      .catch(error => {
        isLoading && isLoading.close();
        showErrorMsg(i18n.t("networkError"));
        reject(error);
      });
  });
};
export const noLoading = requestParams => {
  return new Promise((resolve, reject) => {
    const {
      baseUrl,
      url,
      data = {},
      params = {},
      method = "POST",
      header = {
        'software-version': getSessionStorage("SoftwareVersion"),
        'oem-name': getSessionStorage("OemName"),
        'device-type': getSessionStorage("DeviceType"),
      },
      timeout = 30000,
      responseType = "json",
      defineError = false,
      showLoading = false,
      onUploadProgress = (e) => e,
      onDownloadProgress = (e) => e
    } = requestParams;
    let isLoading = null;
    if (showLoading) {
      isLoading = Loading.service({
        text: i18n.t("loaing"),
        spinner: "el-icon-loading",
        background: "rgba(255, 255, 255, 0.2)"
      });
    }
    axios({
      baseURL: baseUrl || baseURL,
      url,
      data,
      params,
      method,
      timeout,
      responseType,
      headers: getHeader(header),
      onUploadProgress,
      onDownloadProgress
    })
      .then(result => {
        isLoading && isLoading.close();
        const { data } = result;
        if (defineError) {
          resolve(data);
          return;
        }
        const { state } = data;
        // 未登录和被踢出
        if (state === "NO_LOGIN" || state === "KICK_OUT") {
          MessageBox.alert(i18n.t("noLogin"), i18n.t("msg"), {
            dangerouslyUseHTMLString: true,
            showClose: false,
            confirmButtonText: i18n.t("confirm"),
            center: true
          }).then(() => {
            // 清除本地缓存数据
            deleteSessionStorage("token");
            store.commit("updateUser", null);
            location.href = "/login";
          });
          return;
        }
        if (state !== "SUCCESS") {
          // showErrorMsg(i18n.t(msg));
          reject(data);
          return;
        }
        resolve(data.data);
      })
      .catch(error => {
        isLoading && isLoading.close();
        showErrorMsg(i18n.t("networkError"));
        reject(error);
      });
  });
};
