import { getLocalStorage } from '@/helpers/utils'
const oemConfig = getLocalStorage('companyOemConfig')
const product = getLocalStorage('product')
const lang = getLocalStorage('lang')
const getImgSrc = (res) => {
  return res.fileHost + '/' + res.objectName
}

// 公司名称
export const companyLogo = () => {
  let companyLogo = require('./assets/imgs/defaultCompanyLogo.png')
  if (oemConfig && oemConfig.companyLogoImg) {
    companyLogo = getImgSrc(oemConfig.companyLogoImg)
  }
  return companyLogo
}

// 产品logo
export const productLogo = () => {
  let productLogo = require('./assets/imgs/defaultProductLogo.png')
  if (oemConfig && oemConfig.productLogoZhImg && lang === 'zh') {
    productLogo = getImgSrc(oemConfig.productLogoZhImg)
  }
  if (oemConfig && oemConfig.productLogoEnImg && lang === 'en') {
    productLogo = getImgSrc(oemConfig.productLogoEnImg)
  }
  return productLogo
}

// 产品名称
export const siteName = () => {
  const siteName = {
    zh: 'AR远程指导系统',
    en: 'AR Remote Guidance'
  }
  if (oemConfig && oemConfig.productZhName) {
    siteName.zh = oemConfig.productZhName
  }
  if (oemConfig && oemConfig.productEnName) {
    siteName.en = oemConfig.productEnName
  }
  return siteName[lang] ? siteName[lang] : siteName.zh
}

// 标语
export const siteSlogan = () => {
  const siteSlogan = {
    zh: 'SOP执行与追溯平台',
    en: 'SOP Execution And Traceability Platform'
  }
  if (oemConfig && oemConfig.productSloganZh) {
    siteSlogan.zh = oemConfig.productSloganZh
  }
  if (oemConfig && oemConfig.productSloganEn) {
    siteSlogan.en = oemConfig.productSloganZh
  }
  return siteSlogan[lang] ? siteSlogan[lang] : siteSlogan.zh
}
// 欢迎语
export const siteWelcome = () => {
  const siteWelcome = {
    zh: '欢迎使用AR远程指导系统',
    en: 'Welcome to the AR remote guidance system'
  }
  if (oemConfig && oemConfig.welcomeZhText) {
    siteWelcome.zh = oemConfig.welcomeZhText
  }
  if (oemConfig && oemConfig.welcomeEnText) {
    siteWelcome.en = oemConfig.welcomeEnText
  }
  return siteWelcome[lang] ? siteWelcome[lang] : siteWelcome.zh
}

// 场景图
export const sceneImg = () => {
  let productLogo = require('./assets/imgs/defaultSceneImg.png')
  if (oemConfig && oemConfig.productSceneZhWebImg && lang === 'zh') {
    productLogo = getImgSrc(oemConfig.productSceneZhWebImg)
  }
  if (oemConfig && oemConfig.productSceneEnWebImg && lang === 'en') {
    productLogo = getImgSrc(oemConfig.productSceneEnWebImg)
  }
  return productLogo
}

// 版权所有
export const copyright = () => {
  const copyright = {
    zh: '© 2017-2024 蒂姆维澳（上海）网络技术有限公司 版权所有.',
    en: '© 2017-2024 Teamviewer (Shanghai)Network Technology co,.Ltd. Copyright'
  }
  if (oemConfig && oemConfig.copyrightZhText) {
    copyright.zh = oemConfig.copyrightZhText
  }
  if (oemConfig && oemConfig.copyrightEnText) {
    copyright.en = oemConfig.copyrightEnText
  }
  return copyright[lang] ? copyright[lang] : copyright.zh
}

// 登录背景图
export const loginBgImg = () => {
  let loginBgImg = require('./assets/imgs/defaultLoginBgImg.png')
  if (oemConfig && oemConfig.xrWorkVersionConfigs && product) {
    const versionConfig = oemConfig.xrWorkVersionConfigs.filter(item => item.xrWorkType === product.versionType)
    if (versionConfig && versionConfig.length > 0) {
      if (versionConfig[0].loginBgZhImg && lang === 'zh') {
        loginBgImg = getImgSrc(versionConfig[0].loginBgZhImg)
      }
      if (versionConfig[0].loginBgEnImg && lang === 'en') {
        loginBgImg = getImgSrc(versionConfig[0].loginBgEnImg)
      }
    }
  }
  return loginBgImg
}

// 版本名称
export const versionName = (versionType) => {
  const versionName = {
    SPOT_INSPECTION: {
      zh: '点检验收',
      en: 'Inspection operation'
    },
    PRACTICAL_TRAINING_EDUCATION: {
      zh: '实操培训',
      en: 'Practical operation training'
    },
    ASSEMBLY_INSTRUCTION: {
      zh: '装配质检',
      en: 'Assembly operation'
    },
    SAFETY_MANAGEMENT: {
      zh: '安全检查',
      en: 'Safety operation'
    }
  }

  if (oemConfig && oemConfig.xrWorkVersionConfigs) {
    const versionConfig = oemConfig.xrWorkVersionConfigs.filter(item => item.xrWorkType === versionType)
    if (versionConfig && versionConfig.length > 0) {
      if (versionConfig[0].versionZhName && lang === 'zh') {
        versionName[versionType].zh = versionConfig[0].versionZhName
      }
      if (versionConfig[0].versionEnName && lang === 'en') {
        versionName[versionType].en = versionConfig[0].versionEnName
      }
    }
  }

  return versionName[versionType][lang]
}

export const contactUs = () => {
  const contactUs = {
    type: 'URL',
    tel: '',
    mail: '',
    companyName: '',
    companyWebUrl: '',
    companyAddress: '',
    jumpUrl: 'http://www.tmva-sh.com/col.jsp?id=102'
  }
  if (oemConfig) {
    if (oemConfig.connectUsType) {
      contactUs.type = oemConfig.connectUsType
    }
    if (oemConfig.contactUsTel) {
      contactUs.tel = oemConfig.contactUsTel
    }
    if (oemConfig.contactUsMail) {
      contactUs.mail = oemConfig.contactUsMail
    }
    if (oemConfig.companyName) {
      contactUs.companyName = oemConfig.companyName
    }
    if (oemConfig.companyWebUrl) {
      contactUs.companyWebUrl = oemConfig.companyWebUrl
    }
    if (oemConfig.companyAddress) {
      contactUs.companyAddress = oemConfig.companyAddress
    }
    if (oemConfig.contactUsUrl) {
      contactUs.jumpUrl = oemConfig.contactUsUrl
    }
  }
  return contactUs
}
